import { getObjectExists } from "../../data/s3";
import { FilePath } from "../../data/types";
import { getTitleFromFilename } from "../../data/util";
import { PageMediaStore } from "../usePageMedia";
import { pagemediaItemType } from "./pagemediaItemType";

const getUniqueFilename = async (path: FilePath): Promise<FilePath> => {
    let currentPath = path;
    let index = 0;

    while (await getObjectExists(`public/${currentPath}`)) {
        index += 1;
        currentPath = path.replace(/(\.\w+)$/, `.${index}$1`) as FilePath;
    }

    return currentPath;
}

const cleanFilename = (filename: string): string => filename.replace(/\.\w+$/, '').replace(/[^-\w_]/g, '') + "." + filename.split(".").pop();

export const pagemediaUploadItem = async (sources: File | File[], store: PageMediaStore) => {
    if (store.status !== "loaded") throw Error("Files are not initialized")

    if (!Array.isArray(sources)) sources = [sources]

    let mediaItems = await Promise.all(sources.map(async (source) => pagemediaItemType({
        status: "queued",
        path: await getUniqueFilename(store.path + "__media/" + cleanFilename(source.name) as FilePath),
        size: source.size,
        acl: "default",
        metadata: {
            title: getTitleFromFilename(source.name)
            // the missing type value is used by pagemediaItemType to determine that it needs to calculate stuff
        },
        source,
        progress: 0
    })))
    store.setItems([...store.items, ...mediaItems], false)

    setTimeout(() => store.processNextQueuedItem(), 0)
}
