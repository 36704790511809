import { PageData } from "../usePageState"

export const numberRE = /^\d+$/

export const pageGoToHeading = (id: number | string, store: PageData) => {
    if (store.editor === null) return
    if (typeof(id) === "string" && id.search(numberRE) === 0) id = parseInt(id, 10)
    if (typeof(id) === "string") return document.getElementById("media")?.scrollIntoView()

    const block = store.editor.lineBlockAt(id)

    window.scrollTo({
        top: block.top,
        left: window.scrollX,
        behavior: 'auto' // or 'smooth' for a smooth scrolling effect
    });
}
