import React from 'react'
import { NodeWithAncestors } from '../ast'
import { MarkdownContextRenderer } from '../markdown'

export const EmphasisView = ({ children, indexes, meta: { bold, italic, length }, ancestors, className, style }: NodeWithAncestors<"inline">) => {
    if (bold === "true" && italic === "true") {
        return <strong className={className} style={style}><em>
            {children.map((node, i) => <MarkdownContextRenderer key={i} indexes={[...indexes, i]} node={node} ancestors={ancestors} />)}
        </em></strong>
    } else if (bold === "true") {
        return <strong className={className} style={style}>
            {children.map((node, i) => <MarkdownContextRenderer key={i} indexes={[...indexes, i]} node={node} ancestors={ancestors} />)}
        </strong>
    } else if (italic === "true") {
        return <em className={className} style={style}>
            {children.map((node, i) => <MarkdownContextRenderer key={i} indexes={[...indexes, i]} node={node} ancestors={ancestors} />)}
        </em>
    }

    return <span className={className} style={style}>
        {children.map((node, i) => <MarkdownContextRenderer key={i} indexes={[...indexes, i]} node={node} ancestors={ancestors} />)}
    </span>
}