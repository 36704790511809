import { PageMediaStore } from '../usePageMedia';
import { FilePath } from '../../data/types';
import { putObjectACL } from '../../data/s3';

export const pagemmediaRemoveItem = async (path: FilePath, store: PageMediaStore, save: boolean=true): Promise<void> => {
    if (store.status !== "loaded") throw Error("Files are not initialized")

    const mediaItem = store.items.find(item => item.path === path)
    if (mediaItem === undefined) throw Error("Specified item does not exist")
    if (mediaItem.status !== "loaded") throw Error("Can't change ACL of incomplete item")

    await putObjectACL(`public/${mediaItem.path}`, "private")
    if (mediaItem.thumbPath) await putObjectACL(`public/${mediaItem.thumbPath}`, "private")

    await store.updateItem(path, { status: "deleted" }, save)
}
