import React from 'react'
import { NeutralPower } from '../../neutralcreature'
import NeutralPowerLine from './NeutralPowerLine'
import DndSubheading from '../dnd/DndSubheading'

interface NeutralPowerBlockOptions {
    powers: NeutralPower[]
    type: NeutralPower["type"]
}

const NeutralPowerBlock = ({ powers, type }: NeutralPowerBlockOptions) => {
    const filteredPowers = powers.filter(v => v.type === type) as NeutralPower[]
    if (filteredPowers.length === 0) return null

    return <div className="block reaction-powers">
        <DndSubheading>{type.slice(0, 1).toUpperCase()}{type.slice(1)}</DndSubheading>
        {filteredPowers.map((power, i) => <NeutralPowerLine key={i} power={power} />)}
    </div>
}

export default NeutralPowerBlock