import { FilePath } from "../../data/types";
import { MediaItem } from "./types";
import { Storage } from 'aws-amplify';

const docSVG = "data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='utf-8'?%3e%3c!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3e%3csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath opacity='0.15' d='M5 21H19V9H15C13.8954 9 13 8.10457 13 7V3H5V21Z' fill='%23000000'/%3e%3cpath d='M8 13H14M8 17H16M13 3H5V21H19V9M13 3H14L19 8V9M13 3V7C13 8 14 9 15 9H19' stroke='%23000000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" as FilePath

export const pagemediaResolveImages = async (mediaItem: MediaItem): Promise<MediaItem> => {
    let path = mediaItem.path
    let thumbPath = docSVG

    if (mediaItem.status === "loaded" || mediaItem.status === "deleted") {
        if (mediaItem.metadata.type === "image") path = await Storage.get(mediaItem.path) as FilePath
        if (mediaItem.thumbPath) thumbPath = await Storage.get(mediaItem.thumbPath) as FilePath
    } else if (mediaItem.source && mediaItem.metadata.type === "image" && mediaItem.thumbPath) {
        // uploading items
        path = URL.createObjectURL(mediaItem.source) as FilePath
        thumbPath = path
    }
    
    return {
        ...mediaItem,
        signedPath: path,
        signedThumbPath: thumbPath
    }
}