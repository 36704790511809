import React from 'react';
import { TableReference } from '../../data/query/from';
import { getItemTagPath, getItemTagValuePath, getPageTagPath, getPageTagValuePath, useMetadataValue } from '../../state/useMetadataStore';
import { DirPath } from '../../data/types';
import { Link } from './Link';

interface TagNameLinkOptions {
    tag: string
    href: DirPath
    style?: React.CSSProperties
}
const TagNameLink = ({ tag, href, style }: TagNameLinkOptions) => {
    const isKeyLinkable = useMetadataValue(href, 'linkable', "true") === "true";
    const tagLabel = useMetadataValue(href, "title", tag);

    if (isKeyLinkable) return <Link className="me-1" style={style} href={`/${href}`}>{tagLabel}</Link>;

    return <span>{tagLabel}</span>;
}


interface TagValueLinkOptions {
    value: string
    tagHref: DirPath
    valueHref: DirPath
    style?: React.CSSProperties
}
const TagValueLink = ({ value, tagHref, valueHref, style }: TagValueLinkOptions) => {
    const isKeyLinkable = useMetadataValue(tagHref, 'linkable', "true") === "true";
    const valueLabel = useMetadataValue(valueHref, "title", value);
    const isValueLinkable = useMetadataValue(valueHref, 'linkable', "true") === "true";

    if (isKeyLinkable && isValueLinkable) return <Link className="me-1" style={style} href={`/${valueHref}`}>{valueLabel}</Link>;

    return <span>{valueLabel}</span>;
}


interface TagLinkOptions {
    tag: string;
    value?: string;
    style?: React.CSSProperties;
    tableReference: TableReference;
}
const TagLink = ({ style, tag, value, tableReference }: TagLinkOptions) => {
    const tagPath = tableReference.table === "page"
        ? getPageTagPath(tag)
        : getItemTagPath(tag, tableReference.type);

    if (value) {
        const valuePath = tableReference.table === "page"
            ? getPageTagValuePath(tag, value)
            : getItemTagValuePath(tag, value, tableReference.type)
        return <TagValueLink value={value} tagHref={tagPath} valueHref={valuePath} style={style} />
    }
    else {
        return <TagNameLink tag={tag} href={tagPath} style={style} />
    }
}

export default TagLink;