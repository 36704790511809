import { MarkdownError } from "../ast"

export const MarkdownErrorView = ({ context, line, lineIndex, message }: MarkdownError) => {
    var contextText = ""
    for (var contextLine of context) {
        contextText += " ".repeat(4 - contextLine.line.toString().length)
        contextText += contextLine.line + 1
        contextText += ": "
        contextText += contextLine.text
        contextText += "\n"

        if (contextLine.line === line) {
            contextText += " ".repeat(6)
            contextText += "-".repeat(lineIndex)
            contextText += "^\n"
        }
    }

    return <blockquote className="error-node">
        <strong>ERROR</strong> ({line + 1}:{lineIndex})<br />
        <pre>{contextText}</pre>
        <p>{message}</p>
    </blockquote>
}
