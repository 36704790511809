import React, { useCallback } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { usePageState } from '../../state/usePageState';
import { useShallow } from 'zustand/react/shallow';
import { SectionReference } from '../../state/page/types';

interface PrintSectionOptionOptions {
    section: SectionReference
    onChange: (id: string, selected: boolean) => void
}

const PrintSectionOption = ({ section, onChange }: PrintSectionOptionOptions) => {
    return <Form.Check id={`section-${section.id}`} type="checkbox" label={section.label} checked={section.print} onChange={event => onChange(section.id, event.target.checked)} />
}


interface PrintOptions {
    show: boolean,
    close: () => void
}

export const PrintModal = ({ show, close }: PrintOptions) => {
    const { sections, setPrintSections } = usePageState(useShallow(state => ({
        sections: state.sections,
        setPrintSections: state.setPrintSections
    })))
    const onChange = useCallback((id: string, selected: boolean) => {
        setPrintSections(sections.filter(section => section.id === id ? selected : section.print).map(section => section.id))
    }, [sections, setPrintSections])
    const print = useCallback(() => {
        close()
        setTimeout(() => window.print(), 50)
    }, [close])

    return <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
            <Modal.Title>Print</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form>
                {sections.map((section, i) => <PrintSectionOption key={i} section={section} onChange={onChange} />)}
            </Form>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={close}>Close</Button>
            <Button variant="primary" onClick={print}>Print</Button>
        </Modal.Footer>
    </Modal>
}



