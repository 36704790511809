import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import { ACLToggleItem } from './ACLToggle';
import MoveButtons from './MoveButtons';
import { MediaItem } from '../../state/pagemedia/types';
import { usePageMedia, usePageMediaOperators, useResolvedImages } from '../../state/usePageMedia';

interface MediaItemEditableOptions {
    allowRight: boolean;
    allowLeft: boolean;
    mediaItem: MediaItem;
}

const imgStyles: React.CSSProperties = {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    aspectRatio: "1/1"
};

const MediaItemEditable = ({ allowRight, allowLeft, mediaItem }: MediaItemEditableOptions) => {
    const [title, setTitle] = useState(mediaItem.metadata.title)
    const actions = usePageMediaOperators()
    useEffect(() => {
        if (title !== mediaItem.metadata.title) {
            const timeoutID = window.setTimeout(() => actions.updateItemMetadata(mediaItem.path, "title", title), 1000)

            return () => window.clearTimeout(timeoutID)
        }
    }, [mediaItem, title, actions])

    return <Card style={{opacity:mediaItem.status==="deleted" ? 0.5 : 1}}>
        <Card.Img variant="top" src={mediaItem.signedThumbPath} style={imgStyles} />
        <Card.Body>
            <Card.Title>
                <input style={{width:"100%"}} onChange={e => setTitle(e.target.value)} value={title} />
                {mediaItem.status==="queued" && <Badge bg="secondary">Queued</Badge>}
            </Card.Title>
            {mediaItem.status === "error" && mediaItem.error ? <p>{mediaItem.error.message}</p> : null}
        </Card.Body>
        <Card.Footer className="pe-0 pw-0" style={{textAlign:"right"}}>
            {mediaItem.status==="uploading" && <ProgressBar variant="success" now={mediaItem.progress} max={1} />}
            {mediaItem.status==="loaded" && <MoveButtons left={allowLeft} right={allowRight} onMove={direction => actions.moveItem(mediaItem.path, direction)} />}
            {mediaItem.status === "loaded" && <ACLToggleItem value={mediaItem.acl} onChange={acl => actions.setItemACL(mediaItem.path, acl)} />}
            {mediaItem.status==="loaded" && <Button variant="link" onClick={() => actions.removeItem(mediaItem.path)}><FontAwesomeIcon icon={faTrash} /></Button>}
            {mediaItem.status==="deleted" && <Button variant="link" onClick={() => actions.unremoveItem(mediaItem.path)} style={{opacity:1}}><FontAwesomeIcon icon={faTrashRestore} /></Button>}
        </Card.Footer>
    </Card>;
}


const MediaEditable = () => {
    const mediaItems = useResolvedImages()
    const uploadItems = usePageMedia(state => state.uploadItems)

    useEffect(() => {
        function onPaste(this: Document, evt: ClipboardEvent) {
            const clipboardItems = evt?.clipboardData?.items
            if (!clipboardItems || clipboardItems.length !== 1) return

            const items = Array.from(clipboardItems).filter(item => item.type.indexOf("image") !== -1)
            if (items.length === 0) return

            const file = items[0].getAsFile()
            if (!file) return
            uploadItems(file)
        }

        document.addEventListener("paste", onPaste);

        return () => document.removeEventListener("paste", onPaste);
    }, [uploadItems])

    return <Container>
        <Row xs={1} s={2} md={3} className="g-4">
            {mediaItems.map((mediaItem, index) => <Col key={mediaItem.path}><MediaItemEditable allowLeft={index > 0} allowRight={index < mediaItems.length - 1} mediaItem={mediaItem} /></Col>)}
        </Row>
        <Row>
            <Dropzone onDrop={uploadItems}>{({getRootProps, getInputProps}) => {
                const rootProps = getRootProps();
                rootProps.style = {
                    height: 100,
                    textAlign: "center",
                    padding: 20,
                    border: "3px #CCC dashed",
                    borderRadius: 5,
                    marginTop: 25,
                    marginBottom: 25
                };

                return <div {...rootProps}>
                        <input {...getInputProps()} />
                        <p id="media" style={{marginBottom:0, fontSize:"2em", fontWeight:"bold" }}>Drag & drop files or click to select</p>
                </div>
            }}</Dropzone>
        </Row>
    </Container>;
}

export default MediaEditable;