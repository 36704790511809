import React from 'react'
import { BasicPower } from '../../../dnd';

interface DndBasicPowerOptions {
    power: BasicPower;
}

const DndBasicPower = ({ power }: DndBasicPowerOptions) => {
    return <p className="power">
        <strong>{power.name}{power.legendaryActionPoints > 1 ? <span> Costs {power.legendaryActionPoints} Actions</span> : null}.</strong>&nbsp;
        {power.description}
    </p>
}

export default DndBasicPower