import { create } from "zustand"
import { BasicACL, DirPath } from "../data/types"
import { pagemarkdownLoad } from "./pagemarkdown/pagemarkdownLoad"
import { PageMarkdownGeneratedData } from "./pagemarkdown/types"
import { Metadata, PageItemMetadata } from "./metadatastore/types"
import { pagemarkdownGetGeneratedData } from "./pagemarkdown/pagemarkdownGetGeneratedData"
import { pagemarkdownSave } from "./pagemarkdown/pagemarkdownSave"
import { pagemarkdownDelete } from "./pagemarkdown/pagemarkdownDelete"
import { pagemarkdownInitializeFromTemplate } from "./pagemarkdown/pagemarkdownInitializeFromTemplate"
import { useUser } from "./useUser"

export interface PageMarkdownStore {
    status: "uninitialized" | "loading" | "loaded" | "error" | "accessdenied"
    statusDetail: string

    path: DirPath
    acl: BasicACL
    exists: boolean

    markdown: string

    metadata: Metadata
    itemMetadata: PageItemMetadata

    setStatusUninitialized: (path: DirPath) => void
    setStatusLoading: () => void
    setStatusLoaded: (path: DirPath, acl: BasicACL, markdown: string, exists: boolean) => void
    setStatusError: (error: any) => void
    setStatusAccessDenied: (path: DirPath) => void

    setExists: (exists: boolean) => void

    save: () => Promise<void>
    load: (initializeOnly: boolean, path: DirPath) => Promise<void>
    delete: () => Promise<void>
    initializeFromTemplate: (template: DirPath) => void
    
    setMarkdown: (path: DirPath, acl: BasicACL, markdown: string, exists: boolean, save?: boolean) => Promise<void>
    setACL: (acl: BasicACL) => Promise<void>
}

const EmptyGeneratedData = {
    metadata: {}
} as PageMarkdownGeneratedData

export const usePageMarkdown = create<PageMarkdownStore>()((set, get) => ({
    status: "uninitialized",
    statusDetail: "",
    exists: false,

    path: "" as DirPath,
    acl: "private",
    markdown: "",

    metadata: {},
    itemMetadata: {},

    setStatusUninitialized: (path) => set(() => ({ status: "uninitialized", statusDetail: "", path, acl: "private", markdown: "", ...EmptyGeneratedData } as Partial<PageMarkdownStore>)),
    setStatusLoading: () => set(() => ({ status: "loading", statusDetail: "", markdown: "", ...EmptyGeneratedData } as Partial<PageMarkdownStore>)),
    setStatusLoaded: (path, acl, markdown, exists) => set(() => ({ status: "loaded", statusDetail: "", exists, path, acl, markdown, ...pagemarkdownGetGeneratedData(path, markdown) } as Partial<PageMarkdownStore>)),
    setStatusError: (error) => set(() => ({ status: "error", statusDetail: error.toString() } as Partial<PageMarkdownStore>)),
    setStatusAccessDenied: (path) => set(() => ({ status: "accessdenied", statusDetail: "", path } as Partial<PageMarkdownStore>)),

    setExists: (exists) => set(() => ({ exists })),

    save: () => pagemarkdownSave(get()),
    load: (initializeOnly, path) => pagemarkdownLoad(initializeOnly, path, get()),
    delete: () => pagemarkdownDelete(get()),
    initializeFromTemplate: (template) => pagemarkdownInitializeFromTemplate(template, get()),

    setMarkdown: (path, acl, markdown, exists, save=true) => {
        const newState = { ...get(), exists, path, acl, markdown, ...pagemarkdownGetGeneratedData(path, markdown) } as PageMarkdownStore
        set(() => newState)

        if (save) return pagemarkdownSave(newState)

        return Promise.resolve()
    },
    setACL: async (acl) => {
        const store = get()
        const newStore = { ...store, acl }

        set(() => newStore)

        return pagemarkdownSave(newStore)
    },
}))

export const setPageMarkdownPath = (path: DirPath) => {
    const store = usePageMarkdown.getState()
    store.setStatusUninitialized(path)
    pagemarkdownLoad(false, path, store)
}

var currentUserStatus = ""
useUser.subscribe(state => {
    if (currentUserStatus !== state.status) {
        currentUserStatus = state.status
        const pageMarkdownState = usePageMarkdown.getState()
        pagemarkdownLoad(false, pageMarkdownState.path, usePageMarkdown.getState())
    }
})