import { DirPath } from "../../data/types";
import { MetadataStoreData } from "../useMetadataStore";

export const metadataStoreGetMetadataValue = (path: DirPath, key: string, def: string, store: MetadataStoreData): string => {
    const metadata = store.pages[path];
    if (metadata === undefined) return def;

    const value = metadata[key];
    if (typeof(value) === "string") return value;
    if (typeof(value) === "boolean") return value ? "true" : "false";
    if (Array.isArray(value)) return value.join(", ");

    return def;
}
