import React from 'react'
import { ClipperResult } from '../../hooks/useClipper'
import { Button } from 'react-bootstrap'
import { faLink, faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BlockActions = ({element, information, copyLink, copyClip}: ClipperResult) => {
    if (!information) return null

    const blockActionsStyle: React.CSSProperties = {
        display: element ? "block" : "none",
        position: "absolute",
        left: information.right + 5,
        top: information.top,
        zIndex:1000
    }

    return <div id="block-actions" style={blockActionsStyle}>
        {copyLink ? <div><Button variant="link" title="Copy link" className="p-0" onClick={copyLink}><FontAwesomeIcon icon={faLink} /></Button></div> : null}
        {copyClip ? <div><Button variant="link" title="Copy snippet" className="p-0" onClick={copyClip}><FontAwesomeIcon icon={faCopy} /></Button></div> : null}
    </div>
}

export default BlockActions