import { InlineNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllInlineFunction, ParseAllInlineFunction, InlineParserFunctions } from "../parser"

const notSimpleLinkInjected = (peekAll: PeekAllInlineFunction) => (char: string, index: number, obj: MarkdownRange): boolean | number => char === ">" ? index + 1 : false

const processSimpleLinkRangesInjected = (parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): InlineNode => ({
    type: "inline",
    name: "link",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {
        href: ranges.flatten().slice(ranges.getStart() + 1, ranges.getEnd() - 1).markdown
    },
    value: "",
    children: [{
        type: "inline",
        name: "plaintext",
        start: ranges.getStart() + 1,
        end: ranges.getEnd() - 1,
        meta: {},
        value: ranges.flatten().slice(ranges.getStart() + 1, ranges.getEnd() - 1).markdown,
        children: []
    }]
})

export const SimpleLinkParserFunctions: InlineParserFunctions = {
    name: "simplelink",
    peek: (char, index, range: MarkdownRange) => range.matches(index, "<"),
    parse: (ranges, parseInline, peekAll) => ranges.eatCharacters(
        notSimpleLinkInjected(peekAll),
        processSimpleLinkRangesInjected(parseInline),
        "Expected simple link"
    )
}
