import { ItemMetadata, MetadataKeyMap, MetadataValueMap, PageMetadata, TypeMetadataKeyMap } from "./types";
import { DirPath } from "../../data/types";
import { MetadataStore } from "../useMetadataStore";

const databaseRemovePagesPath = (pages: PageMetadata, path: DirPath): PageMetadata => {
    return Object.keys(pages)
        .filter(key => key !== path && key.indexOf(`${path}#`) === -1)
        .reduce((cur, path) => Object.assign(cur, { [path]:pages[path] }), {});
}

const databaseRemoveItemsPath = (items: ItemMetadata, path: DirPath): ItemMetadata => {
    return Object.keys(items)
        .filter(key => key !== path && key.indexOf(`${path}#`) === -1)
        .reduce((cur, path) => Object.assign(cur, { [path]:items[path] }), {});
}

const databaseRemovePathsPath = (paths: DirPath[], path: DirPath): DirPath[] => paths.filter(p => {
    if (p === path) return false;
    if (p.slice(0, path.length + 1) === path + '#') return false;
    
    return true;
});

const databaseRemoveValuesPath = (values: MetadataValueMap, path: DirPath): MetadataValueMap => {
    const newValues = {...values};

    for (let value in newValues) {
        newValues[value] = databaseRemovePathsPath(newValues[value], path);
        if (newValues[value].length === 0) {
            delete newValues[value];
        }
    }

    return newValues;
}

const databaseRemoveMapPath = (keys: MetadataKeyMap, path: DirPath): MetadataKeyMap => {
    const newKeys = {...keys};

    for (let key in newKeys) {
        newKeys[key] = databaseRemoveValuesPath(newKeys[key], path);
        if (Object.keys(newKeys[key]).length === 0) {
            delete newKeys[key];
        }
    }

    return newKeys;
}

const databaseRemoveItemsMapPath = (types: TypeMetadataKeyMap, path: DirPath): TypeMetadataKeyMap => {
    const newItemTypes = {...types}

    for (let type in newItemTypes) {
        newItemTypes[type] = databaseRemoveMapPath(newItemTypes[type], path)
        if (Object.keys(newItemTypes[type]).length === 0) {
            delete newItemTypes[type];
        }
    }

    return newItemTypes;
}

export const metadataStoreRemovePage = async (path: DirPath, store: MetadataStore, save: boolean=true) => {
    const data = {
        pages: databaseRemovePagesPath(store.pages, path),
        items: databaseRemoveItemsPath(store.items, path),
        metadata: databaseRemoveMapPath(store.metadata, path),
        itemmetadata: databaseRemoveItemsMapPath(store.itemmetadata, path)
    }

    const publicData = store.publicData === null ? null : {
        pages: databaseRemovePagesPath(store.publicData.pages, path),
        items: databaseRemoveItemsPath(store.publicData.items, path),
        metadata: databaseRemoveMapPath(store.publicData.metadata, path),
        itemmetadata: databaseRemoveItemsMapPath(store.publicData.itemmetadata, path)
    }
    
    store.setData(data, publicData, save)
}