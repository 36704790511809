import { FilePath } from "../../data/types";
import { PageMediaStore } from "../usePageMedia";
import { MoveDirection } from "./types";

export const pagemediaMoveItem = async (path: FilePath, direction: MoveDirection, store: PageMediaStore, save: boolean=true) => {
    if (store.status !== "loaded") throw Error("Files are not initialized")

    const mediaIndex = store.items.findIndex(item => item.path === path)
    if (mediaIndex === -1) throw Error("Specified item does not exist")

    const newItems = direction === "left"
        ? [
            ...store.items.slice(0, mediaIndex - 1),
            store.items[mediaIndex],
            store.items[mediaIndex - 1],
            ...store.items.slice(mediaIndex + 1)
        ]
        : [
            ...store.items.slice(0, mediaIndex),
            store.items[mediaIndex + 1],
            store.items[mediaIndex],
            ...store.items.slice(mediaIndex + 2)
        ];
    
    await store.setItems(newItems, save)
}