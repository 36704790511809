import { Storage } from 'aws-amplify';
import { FilePath } from '../../data/types';
import { putObjectACL } from '../../data/s3';
import { PageMarkdownStore } from '../usePageMarkdown';

export const pagemarkdownSave = async (store: PageMarkdownStore): Promise<void> => {
    if (store.status !== "loaded") throw Error("Mrkdown is not initialized")

    const mediaPath = store.path + "index.md" as FilePath

    await Storage.put(mediaPath, store.markdown, { cacheControl: 'no-cache' })
    await putObjectACL(`public/${mediaPath}`, store.acl)

    if (store.exists === false) store.setExists(true)
}
