import React from 'react';
import { QueryResult, QueryResultCell, QueryResultRow, RowReference, useQuery, useRandomRow } from '../../query';
import { DirPath, HeadingLevels } from '../../types';
import H from '../../../components/view/H';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons';

interface QueryErrorOptions {
    error: any;
    query: string;
}

const QueryError = ({ error, query }: QueryErrorOptions) => {
    if (error.name === "SyntaxError") {
        let errorMessage = '';
        const queryLines = query.split("\n")

        for (let i=0; i<queryLines.length; i++) {
            errorMessage += queryLines[i] + '\n';
            if (error.location.start.line === i + 1) {
                if (error.location.start.column > 1) errorMessage += ' '.repeat(error.location.start.column - 1);
                errorMessage += '^'.repeat(error.location.end.column - error.location.start.column) + '\n'
            }
        }

        errorMessage += error.message + "\n";

        return <pre>{errorMessage}</pre>;
    }

    return <pre>{error.message}</pre>
}


interface QueryTableValueOptions {
    linkable: boolean;
    label: string;
    path: DirPath;
}

const QueryTableValue = ({ linkable, label, path }: QueryTableValueOptions) => linkable ? <a className="me-2" href={`/${path}`}>{label}</a> : <span className='me-2'>{label}</span>


interface QueryTableCellOptions {
    cell: QueryResultCell;
    rowReference: RowReference;
}

const QueryTableCell = ({ cell, rowReference }: QueryTableCellOptions) => {
    if (cell.key === "title") {
        switch (rowReference.type) {
            case "page": return <a href={`/${rowReference.path}`}>{cell.items[0] ? cell.items[0].label : ''}</a>
            case "item": return <a href={`/${rowReference.path}#${rowReference.id}`}>{cell.items[0] ? cell.items[0].label : ''}</a>
        }
    }

    return <React.Fragment>{cell.items.map((item, i) => <QueryTableValue key={i} linkable={cell.linkable} label={item.label} path={item.path} />)}</React.Fragment>;
}


interface QueryTableRowOptions {
    result: QueryResultRow;
    highlighted: boolean;
}

const QueryTableRow = ({ result, highlighted }: QueryTableRowOptions) => {
    return <tr style={{fontWeight:highlighted ? "bold" : "normal"}}>
        {result.cells.map((cell, i) => <td key={i}><QueryTableCell cell={cell} rowReference={result.reference} /></td>)}
    </tr>
}


interface QueryResultTableOptions {
    result: QueryResult;
}

const QueryResultTable = ({ result }: QueryResultTableOptions) => {
    const randomizable = result.rows.length && result.headings.filter(cell => cell.key === "random").length > 0
    const [randomRow, randomizeRow] = useRandomRow(result.rows.length)

    return <React.Fragment>
        {randomizable ? <dl className="row">{result.rows[randomRow].cells.map((cell, i) => <React.Fragment key={i}>
            <dt className="col-xs-12 col-sm-6 col-md-4">{result.headings[i].heading}</dt>
            <dd className="col-xs-12 col-sm-6 col-md-8"><QueryTableCell cell={cell} rowReference={result.rows[randomRow].reference} /></dd></React.Fragment>)}
        </dl> : null}
        <table className="table table-striped">
            <thead>
                <tr>{result.headings.map(({ heading, linkable, path }, i) => {
                    if (heading === "Row") return <th key={i}>d{result.rows.length} <FontAwesomeIcon icon={faDiceD20} style={{cursor:"pointer"}} onClick={randomizeRow} /></th>
                    if (linkable) return <th key={i}><a href={`/${path}`}>{heading}</a></th>

                    return <th key={i}>{heading}</th>
                })}</tr>
            </thead>
            <tbody>
                {result.rows.length === 0 ? <tr><td colSpan={result.headings.length}>No results</td></tr> : null}
                {result.rows.map((row, i) => <QueryTableRow key={i} result={row} highlighted={randomizable===true && randomRow===i} />)}
            </tbody>
        </table>
    </React.Fragment>
}


interface QueryTableOptions {
    level: HeadingLevels;
    title: string;
    query: string;
}

const QueryTable = ({ level, title, query }: QueryTableOptions) => {
    const [queryResult, error] = useQuery(query)

    return <React.Fragment>
        {title.length ? <H level={level}>{title}</H> : null}
        {error ? <QueryError error={error} query={query} /> : null}
        {queryResult ? <QueryResultTable result={queryResult} /> : null}
    </React.Fragment>
}

export default QueryTable;