import React from 'react'
import { BlockNode, InlineNode, MarkdownError, MarkdownNode, Node } from '../ast'
import './MarkdownASTView.css'
import { MarkdownErrorView } from './MarkdownErrorView'

type ASTNodeMetaOptions = { [key: string]: string }
const ASTNodeMeta = (meta: ASTNodeMetaOptions) => {
    if (Object.keys(meta).length === 0) return null

    return <table>
        <tbody>
            {Object.keys(meta).map((key, i) => <tr key={i}><td>{key}</td><td>=</td><td><code>{JSON.stringify(meta[key])}</code></td></tr>)}
        </tbody>
    </table>
}

interface ASTNodeValueOptions {
    children: string
}
const ASTNodeValue = ({ children }: ASTNodeValueOptions) => {
    return children.length ? <pre>{children}</pre> : null
}

interface ASTNodeChildrenOptions {
    children: Node[]
}
const ASTNodeChildren = ({ children }: ASTNodeChildrenOptions) => {
    return <>{children.map((node, i) => <ASTNode key={i} ast={node} />)}</>
}

const ASTInlineNode = ({ name, start, end, meta, value, children }: InlineNode) => {
    return <blockquote className={`node-inline-${name}`}>
        <strong>INLINE:{name}</strong> ({start}:{end})<br />
        <ASTNodeMeta {...meta} />
        <ASTNodeValue>{value}</ASTNodeValue>
        <ASTNodeChildren>{children}</ASTNodeChildren>
    </blockquote>
}

const ASTBlockNode = ({ name, start, end, meta, children }: BlockNode) => {
    return <blockquote className={`node-block-${name}`}>
        <strong>BLOCK:{name}</strong> ({start}:{end})<br />
        <ASTNodeMeta {...meta} />
        <ASTNodeChildren>{children}</ASTNodeChildren>
    </blockquote>
}

interface ASTNodeOptions {
    ast: Node
}
const ASTNode = ({ ast }: ASTNodeOptions) => {
    switch (ast.type) {
        case "inline": return <ASTInlineNode {...ast} />
        case "block": return <ASTBlockNode {...ast} />
    }
}

interface MarkdownNodeViewOptions {
    ast: MarkdownNode | MarkdownError
}
export const MarkdownNodeView = ({ ast }: MarkdownNodeViewOptions) => {
    switch (ast.type) {
        case "markdown":
            return <blockquote className="markdown-node">
                <strong>MARKDOWN</strong> ({ast.start}:{ast.end})<br />
                {ast.children.map((node, i) => <ASTNode key={i} ast={node} />)}
            </blockquote>
        case "error":
            return <MarkdownErrorView {...ast} />
    }
}