import { DirPath } from "../../data/types";
import { RandomTableStore } from "../useRandomTables";

export const randomtablesRemovePage = async (path: DirPath, store: RandomTableStore, save: boolean=true) => {
    // main store is always updatable
    const data = { ...store.data }
    delete data[path]

    // public store should only be updated if the page is public
    const publicData = store.publicData === null
        ? store.publicData
        : { ...store.publicData }
    if (publicData && publicData[path] !== undefined) {
        delete publicData[path]
    }

    store.setData(data, publicData, save)
}

