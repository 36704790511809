import React, { useState } from 'react'
import { FilePath } from '../../data/types';
import { useSignedURL } from '../../state/usePageMedia';

interface LinkOptions {
    href: string
    className?: string
    title?: string
    style?: React.CSSProperties
    ref?: React.RefObject<HTMLAnchorElement>
}

const DisabledLinkStyle = {
    color: "grey",
    cursor: "not-allowed"
} as React.CSSProperties

const MediaLink = ({ href, className, title, style, ref, children }: React.PropsWithChildren<LinkOptions>) => {
    const [hovering, setHovering] = useState(false as boolean)
    const [signedHREF] = useSignedURL(href as FilePath, hovering)

    return <a href={signedHREF || ""} className={className} title={title} style={signedHREF ? undefined : {...style, ...DisabledLinkStyle}} ref={ref} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
        {children}
    </a>
}


const InternalLink = ({ href, className, title, style, ref, children }: React.PropsWithChildren<LinkOptions>) => {
    return <a className="abcd" href={href} title={title} style={style} ref={ref} onClick={e => { window.history.pushState({}, "", href); e.preventDefault(); }}>
        {children}
    </a>
}


export const Link = ({ href, className="", title, style, ref, children }: React.PropsWithChildren<LinkOptions>) => {
    if (href.search(/(^|\/)__media__\//) !== -1) {
        return <MediaLink href={href as FilePath} className={className} title={title} style={style}>{children}</MediaLink>
    } else if (href.slice(0, 1) === "/") {
        return <InternalLink href={href as FilePath} className={className} title={title} style={style}>{children}</InternalLink>
    } else { // external link
        return <a href={href} className={className} title={title} style={style} ref={ref}>{children}</a>
    }
}
