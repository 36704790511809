import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Body from './components/Body';
import { Container, ThemeProvider } from 'react-bootstrap';
import SignInModal from './components/auth/SignInModal';
import Menu from './components/Menu';
import { useShallow } from 'zustand/react/shallow';
import { usePageState } from './state/usePageState';
import './App.css'
import { PrintModal } from './components/menu/PrintModal';

const App = () => {
    const { dialog, setDialog } = usePageState(useShallow(state => ({ dialog: state.dialog, setDialog: state.showDialog })))

    return <ThemeProvider>
        <Menu />

        <Container className="App-container" fluid="md">
            <Body />
        </Container>
        
        <SignInModal show={dialog==='signin'} close={() => setDialog('')} />
        <PrintModal show={dialog==='print'} close={() => setDialog('')} />
    </ThemeProvider>
}

export default App;
