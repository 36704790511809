import React from 'react'
import { AbilityScoreSet, Dnd5eSkills, calculateSkillModifier } from '../../../dnd';
import DndPropertyLine from './DndPropertyLine';

interface DndSkillsOptions {
    proficiency?: number
    abilities?: AbilityScoreSet
    skills?: string[]
}

const DndSkills = ({ proficiency, abilities, skills }: DndSkillsOptions) => {
    if (!skills) return null
    if (Dnd5eSkills.filter(v => skills.indexOf(v) !== -1).length === 0) return null;

    let second = false
    return <DndPropertyLine label="Skills">
        {Dnd5eSkills.map((v, i) => {
            if (skills.indexOf(v) === -1) return null
            const result = <React.Fragment key={i}>
                {second ? ', ' : ''}
                <span style={{textTransform: "capitalize"}}>{v.toLowerCase()}</span>
                {proficiency && abilities ? ` +${calculateSkillModifier(abilities, v, true, proficiency)}` : ''}
            </React.Fragment>
            second = true
            return result
        })}
    </DndPropertyLine>
}

export default DndSkills