import { BasicACL, DirPath } from "../../data/types";
import { MetadataStore, MetadataStoreData } from "../useMetadataStore";
import { ItemMetadata, Metadata, MetadataKeyMap, PageItemMetadata, PageMetadata } from "./types";

const metadataAddReference = (metadata: MetadataKeyMap, path: DirPath, key: string, value: string): MetadataKeyMap => {
    let currentMetadata = metadata;

    if (currentMetadata[key] === undefined) currentMetadata = {...currentMetadata, [key]: {}};
    if (currentMetadata[key][value] === undefined) currentMetadata = {...currentMetadata, [key]: {...currentMetadata[key], [value]: [path]} };
    if (currentMetadata[key][value].indexOf(path) !== -1) return currentMetadata;

    return {
        ...currentMetadata,
        [key]: {
            ...currentMetadata[key],
            [value]: [...currentMetadata[key][value], path]
        }
    };
}

const updateStore = (path: DirPath, metadata: Metadata, itemMetadata: PageItemMetadata, data: MetadataStoreData): MetadataStoreData => {
    const newPages = { ...data.pages, [path]: metadata } as PageMetadata;
    const newItems = { ...data.items, [path]: itemMetadata } as ItemMetadata;

    let newMetadata = { ...data.metadata } as MetadataKeyMap;
    for (let key in metadata) {
        let val = metadata[key];
        if (Array.isArray(val)) {
            for (let value of val) {
                newMetadata = metadataAddReference(newMetadata, path, key, value);
            }
        } else if (typeof(val) === "string" || typeof(val) === "number") {
            newMetadata = metadataAddReference(newMetadata, path, key, val);
        }
    }

    let newItemMetadata = { ...data.itemmetadata };
    for (let id in itemMetadata) {
        const type = itemMetadata[id].type;
        if (typeof(type) !== "string") continue;

        for (let key in itemMetadata[id]) {
            if (key === "type") continue;

            let val = itemMetadata[id][key];
            if (Array.isArray(val)) {
                for (let value of val) {
                    newItemMetadata[type] = metadataAddReference(newItemMetadata[type] || {}, `${path}#${id}` as DirPath, key, value);
                }
            } else if (typeof(val) === "string" || typeof(val) === "number") {
                newItemMetadata[type] = metadataAddReference(newItemMetadata[type] || {}, `${path}#${id}` as DirPath, key, val);
            }
        }
    }

    return {
        pages: newPages,
        metadata: newMetadata,
        items: newItems,
        itemmetadata: newItemMetadata
    };
}

export const metadataStoreAddPage = async (path: DirPath, acl: BasicACL, metadata: Metadata, itemMetadata: PageItemMetadata, store: MetadataStore, save: boolean=true) => {
    // main store is always updatable
    const data = updateStore(path, metadata, itemMetadata, store)

    // public store should only be updated if the page is public
    const publicData = store.publicData === null || (store.mode === "public" && acl === "private")
        ? store.publicData
        : updateStore(path, metadata, itemMetadata, store.publicData)

    store.setData(data, publicData, save)
}
