import { ExpandedRandomTableReference, RandomTableDatabase, RandomTableReference } from "./types";

export const randomtableExpandReferences = (data: RandomTableDatabase, references: RandomTableReference[]): ExpandedRandomTableReference[] => {
    let newReferences: ExpandedRandomTableReference[] = [];

    for (let i=0; i<references.length; i++) {
        if (references[i].path === "*") {
            newReferences = [
                ...newReferences,
                ...Object
                    .keys(data)
                    .map(path => data[path].map((table): ExpandedRandomTableReference => ({
                        path,
                        title: table.title,
                        randomTable: table.title,
                        setTitles: table.sets.map(s => s.title)
                    } as ExpandedRandomTableReference)))
                    .flat()
            ];
        }
        else if (references[i].randomTable === "*") {
            if (data[references[i].path]) {
                newReferences = [
                    ...newReferences,
                    ...data[references[i].path].map((table): ExpandedRandomTableReference => ({
                        path: references[i].path,
                        title: table.title,
                        randomTable: table.title,
                        setTitles: table.sets.map(s => s.title)
                    } as ExpandedRandomTableReference))
                ];
            }
        }
        else {
            newReferences.push(references[i] as ExpandedRandomTableReference);
        }
    }

    return newReferences;
}
