import React from 'react'
import { AbilityScoreSet, Dnd5eAbilityScoreItem, formatDnd5eAbilityScoreModifier, getDnd5eAbilityScoreModifier } from '../../../dnd';

interface DndAbilityScoreBlockOptions {
    ability: Dnd5eAbilityScoreItem;
}
const AbilityScoreBlock = ({ ability }: DndAbilityScoreBlockOptions) => {
    return <div className="ability-strength">
        <strong>{ability.ability}</strong>&nbsp;
        <p>{ability.score} ({formatDnd5eAbilityScoreModifier(getDnd5eAbilityScoreModifier(ability.score))})</p>
    </div>
}

interface DndAbilityScoresOptions {
    abilities?: AbilityScoreSet
}
const DndAbilityScores = ({ abilities }: DndAbilityScoresOptions) => {
    if (!abilities) return null

    return <div className="abilities block">
        <AbilityScoreBlock ability={abilities.STR} />
        <AbilityScoreBlock ability={abilities.DEX} />
        <AbilityScoreBlock ability={abilities.CON} />
        <AbilityScoreBlock ability={abilities.INT} />
        <AbilityScoreBlock ability={abilities.WIS} />
        <AbilityScoreBlock ability={abilities.CHA} />
    </div>
}

export default DndAbilityScores