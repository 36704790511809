import React from 'react'
import { NodeWithAncestors } from '../../../markdown/ast'
import { MarkdownContextRenderer } from '../../../markdown/markdown'
import { Link } from '../Link';

export const LinkOverride = ({ indexes, meta: { href, title }, children, ancestors, className, style }: NodeWithAncestors<"inline">) => {
    return <Link href={href} title={title} className={className} style={style}>
        {children.map((node, i) => <MarkdownContextRenderer key={i} node={node} indexes={[...indexes, i]} ancestors={ancestors} />)}
    </Link>
}
