import { Storage } from 'aws-amplify';
import { PageMarkdownStore } from '../usePageMarkdown';

export const pagemarkdownDelete = async (store: PageMarkdownStore): Promise<void> => {
    if (store.status !== "loaded") throw Error("Markdown is not initialized")

    await Storage.remove(store.path + "index.md")

    await store.load(false, store.path)
}
