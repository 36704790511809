import React from 'react';
import './MarkdownSandbox.css'
import { useMarkdownParser } from './markdown/parser';
import { MarkdownNodeView } from './markdown/tree/MarkdownASTView';
import { Markdown, MarkdownContextRenderer } from './markdown/markdown';
import { NodeWithAncestors } from './markdown/ast';

const testData = `# hello world
## sub heading <http://google.com>

something __*here*__ about
a thing [link text](http://daemon.com.au) and another thing

![Daemon logo](https://www.daemon.com.au/css/images/daemon-logo.png)

something ![Daemon logo](https://www.daemon.com.au/css/images/daemon-logo.png) something

another \`with\` a title ![Daemon logo](https://www.daemon.com.au/css/images/daemon-logo.png "Hello")

* some
  1. sub
  2. items
    > what to do
    > about *these* things
* thing
1. world [link text](http://daemon.com.au "daemon") asdf
2. Hello

> but ~~what~~ is
> this about
> > hello world
> > 
> > * hello
> > * world

\`\`\`text
whoop!
\`\`\`

| a | title<br>abcd |
| - | ----- |
| 1 | asdf |
| 2 | qwer |

[[query]]
| SELECT title
| FROM pages
| WHERE tags = 'homegame'

[[5ecreature]]
| name: New Creature
| size: medium
| type: humanoid
| ac: 10
| hitpoints: 1d10
| walk: 30
| abilities: 10 10 10 10 10 10
| proficiency: 2
| cr: 1
| passive: Name:Description
| reaction: Name:Description
| action: Name:Description
| attack: Name:Melee Attack:STR+PROF:range 5 feet, one target:1d8+PROF:Description
| attack: 2:Name:Melee Attack:STR+PROF:range 5 feet, one target:1d8+PROF:Description

[[randomtable|boundrows=true]]
| Table name
| Colours | Foods |
| -------- | -------- |
| Fuscia | Bacon |
| Teal | Pineapple |
| Vermillion | Marshmallow |

[[snippet]]
| abcd
| def
| <p>Snippet text</p>
`

const StrikethroughView = ({ indexes, children, ancestors }: NodeWithAncestors<"inline">) => {
  return <span style={{textDecoration:"line-through", fontWeight: "bold", color:"red" }}>
      {children.map((node, i) => <MarkdownContextRenderer key={i} node={node} indexes={[...indexes, i]} ancestors={ancestors} />)}
  </span>
}
const overrideRenderers = {
  inline: {
    strikethrough: StrikethroughView
  },
  block: {

  }
}

function MarkdownSandbox() {
  const [ast, updateAST] = useMarkdownParser(testData)

  return <div style={{display: "flex", height: "100%", margin: 0}}>
    <textarea style={{flex: 1}} onChange={(ev) => updateAST(ev.target.value)} defaultValue={testData} />
    <div style={{flex: 1, paddingLeft: 10, overflowY: "scroll"}}><MarkdownNodeView ast={ast} /></div>
    <div className="rendered" style={{flex: 1, paddingLeft: 10, overflowY: "scroll"}}><Markdown ast={ast} overrideRenderers={overrideRenderers} /></div>
  </div>;
}

export default MarkdownSandbox;
