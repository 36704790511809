import React from 'react'
import { NodeWithAncestors } from '../ast'

export const NoRendererBlockView = ({ type, name, children, meta, ancestors }: NodeWithAncestors<"block">) => {
    return <p style={{ fontWeight: "bold", color: "red" }}>
        No renderer defined for {type}:{name}<br />
        Metadata: <code>{JSON.stringify(meta)}</code><br />
        Children: <code>{JSON.stringify(children)}</code>
    </p>
}

export const NoRendererInlineView = ({ type, name, children, meta, ancestors }: NodeWithAncestors<"inline">) => {
    return <span style={{ fontWeight: "bold", color: "red" }}>
        No renderer defined for {type}:{name}.
        Metadata: <code>{JSON.stringify(meta)}</code>.
        Children: <code>{JSON.stringify(children)}</code>.
    </span>
}
