import { HeadingOverride } from "./heading";
import { LinkOverride } from "./link";
import { TableOverride } from "./table";

export const RendererOverrides = {
    inline: {
        link: LinkOverride
    },
    block: {
        heading: HeadingOverride,
        table: TableOverride
    }
}