import { InlineNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { InlineParserFunction, ParseAllInlineFunction } from "../parser"

const nonLinkText = (char: string, index: number, obj: MarkdownRange): boolean | number => {
    if (index === 0) return false
    if (obj.matches(index, "]")) return index + 1

    return false
}

const processLinktextRanges = (parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): InlineNode => ({
    type: "inline",
    name: "link",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    value: "",
    children: parseInline(ranges.flatten().slice(ranges.getStart() + 1, ranges.getEnd() - 1).toArray())
})

export const parseLinkText: InlineParserFunction = (ranges, parseInline, peekAll) => ranges.eatCharacters(
    nonLinkText,
    processLinktextRanges(parseInline),
    "Expected closing `]`"
)
