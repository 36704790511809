import React, { memo, useCallback, useMemo } from 'react'
import { Markdown } from '../../markdown/markdown'
import { RendererOverrides } from './markdownoverride/_all'
import { AST, GetClassNameFn } from '../../markdown/ast'
import { usePageState } from '../../state/usePageState'
import './MarkdownView.css'

interface MarkdownViewOptions {
    onMouseOver: React.MouseEventHandler<HTMLElement>;
    onMouseUp: React.MouseEventHandler<HTMLElement>;
    children: string;
    ast?: AST
}
const MarkdownView = memo(({ onMouseOver, onMouseUp, children, ast }: MarkdownViewOptions) => {
    const sections = usePageState(state => state.sections)
    const visibleIndexes = useMemo(() => ({
        web: sections.filter(section => section.web).map(section => section.indexes).reduce((a, b) => [...a, ...b], []),
        print: sections.filter(section => section.print).map(section => section.indexes).reduce((a, b) => [...a, ...b], []),
    }), [sections])
    const getClassName: GetClassNameFn = useCallback((node, indexes, ancestors) => {
        if (indexes.length > 1) return undefined

        return [
            visibleIndexes.web.indexOf(indexes[0]) !== -1 ? "web-show" : "web-hide",
            visibleIndexes.print.indexOf(indexes[0]) !== -1 ? "print-show" : "print-hide",
        ].join(" ")
    }, [visibleIndexes])

    return <div onMouseOver={onMouseOver} onMouseUp={onMouseUp}>
        <Markdown markdown={children} overrideRenderers={RendererOverrides} getClassName={getClassName} ast={ast} />
    </div>
})

export default MarkdownView;