import { BlockNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { BlockParserFunctions, ParseAllBlockFunction, ParseAllInlineFunction, PeekAllBlockFunction } from "../parser"

const isNotUnorderedListInjected = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number): boolean => {
    return ["  ", "* ", "- "].indexOf(range.markdown.slice(0, 2)) === -1
}

const processUnorderedListRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => ({
    type: "block",
    name: "unorderedlist",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    children: parseBlock(ranges)
})

export const UnorderedListParserFunctions: BlockParserFunctions = {
    name: "unorderedlist",
    peek: (range, index, obj, ancestors) => {
        if (ancestors[ancestors.length - 1] === "unorderedlist") return false
        
        return ["* ", "- "].indexOf(range.markdown.slice(0, 2)) !== -1
    },
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotUnorderedListInjected(peekAll),
        processUnorderedListRangesInjected(parseBlocks, parseInline),
        "Expected start of unordered list"
    )
}
