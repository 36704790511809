import { BlockNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllBlockFunction, ParseAllBlockFunction, ParseAllInlineFunction, BlockParserFunctions } from "../parser"

const isNotCodeInjected = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number, obj: MarkdownRange[]): number | boolean => {
    if (index === 0) return false
    if (obj[index].markdown.slice(0, 3) !== "```") return false
    
    return index + 1
}

const processCodeRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => ({
    type: "block",
    name: "code",
    start: ranges.getStart(),
    end: ranges.getStart(),
    meta: {
        language: ranges[0].markdown.slice(3).replace(/[ \s\r]+$/, '')
    },
    children: [{
        type: "inline",
        name: "plaintext",
        start: ranges.getStart() + 1,
        end: ranges.getEnd() - 1,
        meta: {},
        value: ranges.slice(1, -1).map(range => range.markdown).join("\n"),
        children: []
    }]
})

export const CodeParserFunctions: BlockParserFunctions = {
    name: "code",
    peek: (range, index, obj, ancestors) => range.markdown.slice(0, 3) === "```",
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotCodeInjected(peekAll),
        processCodeRangesInjected(parseBlocks, parseInline),
        "Expected start of a code block"
    )
}
