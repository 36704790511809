import { CompletionContext, CompletionResult } from "@codemirror/autocomplete";
import { PageData } from "../../state/usePageState";
import { trim } from "../util";
import { Element } from "react-markdown/lib/ast-to-react";
import { CompletionDatabase } from "../../state/page/types";

type BlockParams = { [k: string]: string }

export interface MarkdownBlock {
    index: number;
    line: number;
    originalText: string;
    originalLength: number;
    blockHead: string;
    blockParams: BlockParams
    content: string[];
}

const ParamRegexp = /^([^=]+)=([^=]+)$/
const getBlockParams = (reResult: IterableIterator<RegExpMatchArray>): BlockParams => Array.from(reResult)[0][1]
    .split('|')
    .filter(v => v !== "")
    .map(v => ({ [v.replace(ParamRegexp, "$1")]: v.replace(ParamRegexp, "$2") } as BlockParams))
    .reduce((a: BlockParams, b: BlockParams) => ({ ...a, ...b }), {} as BlockParams)

export const getBlockMatcher = (name: string) => {
    const blockStartPattern = new RegExp(`\\[\\[${name}((\\|[^|\\]]+)*)\\]\\]`, 'g')
    
    return (markdown: string) => {
        const lines = markdown.split("\n").map((line,index) => ({ line, index }));
        const blockStarts = lines.filter(({line}) => line.search(blockStartPattern) === 0);
        const blocks = blockStarts.map(({index}, blockIndex) => {
            const endLine = lines.slice(index+1).find(({line}) => line.search(/^\| /) === -1);
            const content = endLine === undefined ? lines.slice(index) : lines.slice(index, endLine.index);

            const block: MarkdownBlock = {
                index: blockIndex,
                line: content[0].index+1,
                originalText: content.map(({line}) => line).join("\n") + "\n",
                originalLength: content.length + 1,
                blockHead: content[0].line.replace(blockStartPattern, "$1"),
                blockParams: getBlockParams(content[0].line.matchAll(blockStartPattern)),
                content: content.slice(1).map(({line}) => line.slice(2))
            };

            return block;
        });

        return blocks;
    }
}

export const createProcessBlockFunction = <T>(name: string, parse: (content: MarkdownBlock) => T, render: (pageData: PageData, data: T) => string) => {
    const matcher = getBlockMatcher(name);
    
    return {
        parse: (markdown: string): T[] => {
            const blocks = matcher(markdown);

            return Array.from(blocks).map(block => parse(block));
        },
        render: (pageData: PageData, markdown: string): string => {
            const blocks = matcher(markdown);

            let newMarkdown = markdown;

            for (let block of blocks) {
                try {
                    const data = parse(block);
                    const replaceWith = render(pageData, data);

                    newMarkdown = newMarkdown.replace(block.originalText, replaceWith);
                } catch (error: any) {
                    console.error(error, block);
                    newMarkdown = newMarkdown.replace(block.originalText, `**${name} block error: ${error.message}**\n`);
                }
            }

            return newMarkdown;
        }
    }
}

export interface BlockStateError {
    status: "error";
    error: string;
}
interface BlockStateRenderable<BlockDataType> {
    status: "renderable"
    name: string
    data: BlockDataType
}
type BlockState<BlockDataType> = BlockStateError | BlockStateRenderable<BlockDataType>
type BlockParser<BlockDataType> = (content: MarkdownBlock) => BlockDataType
type BlockRenderer<BlockDataType> = (data: BlockDataType) => string
type BlockRenderMatcher<BlockDataType> = (node: Element) => BlockDataType | null
type BlockRenderToReact<BlockDataType> = (data: BlockDataType) => JSX.Element
export type BlockCompletionGenerator = (context: CompletionContext, text: string[], startIndex: number, currentIndex: number, completionDatabase: CompletionDatabase) => CompletionResult | null

interface CustomMarkdownBlockOptions<BlockDataType> {
    name: string;
    parser: BlockParser<BlockDataType>;
    renderer?: BlockRenderer<BlockDataType>;
    renderMatcher?: BlockRenderMatcher<BlockDataType>
    renderToReact?: BlockRenderToReact<BlockDataType>
    getCompletions?: BlockCompletionGenerator
    template: string;
}
export class CustomMarkdownBlock<BlockDataType> {
    name: string
    matcher: (markdown: string) => MarkdownBlock[]
    parser: BlockParser<BlockDataType>
    renderer: BlockRenderer<BlockDataType>
    renderMatcher: BlockRenderMatcher<BlockDataType>
    renderToReact?: BlockRenderToReact<BlockDataType>
    getCompletions: BlockCompletionGenerator
    template: string

    constructor({ name, parser, renderer, renderMatcher, renderToReact, getCompletions, template }: CustomMarkdownBlockOptions<BlockDataType>) {
        if ((renderer === undefined) !== (renderMatcher === undefined)) throw new Error("CustomMarkdownBlock must either be passed BOTH renderer and renderMatcher, or NEITHER")

        this.name = name
        this.matcher = getBlockMatcher(name)
        this.parser = parser
        this.renderer = renderer || this.defaultRenderer
        this.renderMatcher = renderMatcher || this.defaultRenderMatcher
        this.renderToReact = renderToReact
        this.getCompletions = getCompletions || this.defaultGetCompletions 
        this.template = template
    }

    parse(markdown: string): BlockState<BlockDataType>[] {
        return this.matcher(markdown).map((block): BlockState<BlockDataType> => {
            try {
                return { status:"renderable", name:this.name, data:this.parser(block) }
            } catch (err: any) {
                console.error(err)
                return { status:"error", error:err.message }
            }
        })
    }

    renderFromAST(data: any): JSX.Element | null {
        if (this.renderToReact === undefined) return null

        return this.renderToReact(data as BlockDataType)
    }

    render(markdown: string): string {
        const blocks = this.matcher(markdown);

        let newMarkdown = markdown;

        for (let block of blocks) {
            try {
                newMarkdown = newMarkdown.replace(block.originalText, this.renderer(this.parser(block)));
            } catch (error: any) {
                console.error(error, block);
                newMarkdown = newMarkdown.replace(block.originalText, `**${this.name} block error: ${error.message}**\n`);
            }
        }

        return newMarkdown;
    }

    getReact(node: Element): JSX.Element | null {
        const data = this.renderMatcher(node)
        if (data === null) return null

        if (this.renderToReact === undefined) return null

        return this.renderToReact(data)
    }

    defaultRenderer(data: BlockDataType): string {
        const dataJSON = JSON.stringify(data)
        const dataJSONEscaped = unescape(encodeURIComponent(dataJSON))
        const dataBase64 = window.btoa(dataJSONEscaped)
        return `\`\`\`markdown-block-${this.name}\n[[${dataBase64}]]\n\`\`\`\n`;
    }

    defaultRenderMatcher(node: Element): BlockDataType | null {
        if (node.type !== "element" || node.tagName !== "pre") return null

        const codeCandidate = node.children[0]
        if (codeCandidate.type !== "element" || codeCandidate.tagName !== "code") return null
        if (codeCandidate.properties === undefined || !Array.isArray(codeCandidate.properties.className)) return null
        if (codeCandidate.properties.className.indexOf(`language-markdown-block-${this.name}`) === -1) return null

        const contentElement = codeCandidate.children[0]
        if (contentElement.type !== "text") return null

        const input = trim(contentElement.value).slice(2,-2)
        const macroData = window.atob(input)
        const macroDataDecoded = decodeURIComponent(escape(macroData))

        return JSON.parse(macroDataDecoded) as BlockDataType;
    }

    defaultGetCompletions(context: CompletionContext, text: string[], startIndex: number, currentIndex: number, completionDatabase: CompletionDatabase): CompletionResult | null {
        return null
    }
}

type ParseTagLineResult = { mode:"none" } | { mode:"name", name:string } | { mode:"value", name:string, value:string }
export const parseTagLine = (line: string): ParseTagLineResult => {
    const lineParts = line.replace(/^\| /, '').split(":").map(trim)

    if (lineParts.length === 2) {
        return {
            mode:"value",
            name: lineParts[0],
            value: lineParts[1]
        }
    } else if (lineParts.length === 1 && lineParts[0].length) {
        return {
            mode:"name",
            name: lineParts[0]
        }
    } else {
        return {
            mode:"none"
        }
    }
}

type ParseCurrentTagLineResult =
    { mode:"name", name:string, match:{ from:number, to:number, text:string} } |
    { mode:"value", name: string, value: string, match:{ from:number, to:number, text:string} } |
    { mode:"none" }
export const parseCurrentTagLine = (context: CompletionContext, line: string): ParseCurrentTagLineResult => {
    const parseResult = parseTagLine(line)

    switch (parseResult.mode) {
        case "none":
            return parseResult
        case "name":
            const tagMatch = context.matchBefore(/^\| [-\w_]*/);
            if (!tagMatch) return { mode:"none" }
            return { ...parseResult, match:tagMatch }
        case "value":
            const valueMatch = context.matchBefore(/^\| [-\w_]+: .*/);
            if (!valueMatch) return { mode:"none" }
            return { ...parseResult, match:valueMatch }
    }
}

