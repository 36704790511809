import { Storage } from 'aws-amplify';
import { PageMediaStore } from '../usePageMedia';

export const pagemmediaDelete = async (store: PageMediaStore): Promise<void> => {
    if (store.status !== "loaded") throw Error("Files are not initialized")

    await Storage.remove(store.path + "media.json")

    const files = await Storage.list(store.path + "__media/", { pageSize: "ALL" });
    for (let file of files.results) {
        if (file.key) await Storage.remove(file.key);
    }

    await store.load(false, store.path)
}
