import { FilePath } from "../../data/types";
import { getImageDimensionsFromFile, getImageDimensionsFromPath } from "./pagemediaImageDimensions";
import { MediaItem, MediaType } from "./types";

const imageRE = /\.(png|jpg|jpeg|gif|webp)$/
export const getMediaType = (file: FilePath): MediaType => file.search(imageRE) === -1 ? "other" : "image";

export const pagemediaHasItemType = (mediaItem: MediaItem): boolean => mediaItem.metadata.type !== undefined

export const pagemediaItemType = async (mediaItem: MediaItem): Promise<MediaItem> => {
    if (pagemediaHasItemType(mediaItem)) return mediaItem

    switch (getMediaType(mediaItem.path)) {
        case "image":
            const dimensions = mediaItem.source === undefined
                ? await getImageDimensionsFromPath(mediaItem.path)
                : await getImageDimensionsFromFile(mediaItem.source)

            if (dimensions === null) return {
                ...mediaItem,
                metadata: {
                    ...mediaItem.metadata,
                    type: "other"
                }
            }

            return {
                ...mediaItem,
                thumbPath: mediaItem.path.replace(/\.\w+$/, '_thumb.png') as FilePath,
                metadata: {
                    ...mediaItem.metadata,
                    type: "image",
                    ...dimensions
                }
            };
        case "other":
            return {
                ...mediaItem,
                metadata: {
                    ...mediaItem.metadata,
                    type: "other"
                }
            };
    }
}
