import { BlockNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllBlockFunction, ParseAllBlockFunction, ParseAllInlineFunction, BlockParserFunctions } from "../parser"

export const TestOrderedListItemRE = /^(\d+\.)/

const isNotOrderedListInjected = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number): boolean => {
    if (range.markdown.slice(0, 2) === "  ") return false
    if (range.markdown.search(TestOrderedListItemRE) !== -1) return false

    return true
}

const processOrderedListRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => ({
    type: "block",
    name: "orderedlist",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    children: parseBlock(ranges)
})

export const OrderedListParserFunctions: BlockParserFunctions = {
    name: "orderedlist",
    peek: (range, index, obj, ancestors) => {
        if (ancestors[ancestors.length - 1] === "orderedlist") return false
        
        return range.markdown.search(TestOrderedListItemRE) !== -1
    },
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotOrderedListInjected(peekAll),
        processOrderedListRangesInjected(parseBlocks, parseInline),
        "Expected start of ordered list"
    )
}
