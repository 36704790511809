import { FilePath } from "../../data/types";
import { Storage } from 'aws-amplify';
import { RandomTableDatabase } from "./types";
import { RandomTableStore } from "../useRandomTables";

export const publicRandomTablePath = "randomtables_public.json" as FilePath;
export const privateRandomTablePath = "randomtables_private.json" as FilePath;

const loadStore = async (path: FilePath): Promise<RandomTableDatabase> => {
    let result;

    try {
        result = await Storage.get(path, { download: true, cacheControl: 'no-cache' });
    } catch (error: any) {
        if (error.message === 'NoSuchKey') return {}

        throw error
    }

    if (result.Body === undefined) return {};

    const text = await (result.Body as Blob).text();
    const data = JSON.parse(text);

    return data
}

export const randomtablesLoad = async (initializeOnly: boolean, mode: RandomTableStore["mode"], store: RandomTableStore) => {
    if (initializeOnly && store.status !== "uninitialized") return

    const path = mode === "public" ? publicRandomTablePath as FilePath : privateRandomTablePath as FilePath

    try {
        const [data, publicData] = await Promise.all([
            loadStore(path),
            mode === "private" ? loadStore(publicRandomTablePath) : null
        ])

        store.setStatusLoaded(mode, data, publicData)
    } catch (error: any) {
        if (error.message === 'Access Denied') return store.setStatusAccessDenied()

        store.setStatusError(error)
    }
}