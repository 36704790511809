import { create } from 'zustand'
import { BasicACL, DirPath } from '../data/types';
import { shared } from 'use-broadcast-ts';
import { ItemMetadata, Metadata, MetadataKeyMap, PageItemMetadata, PageMetadata, StoreGeneratedData, TypeMetadataKeyMap } from './metadatastore/types';
import { metadataStoreLoad } from './metadatastore/metadataStoreLoad';
import { metadataStoreSetMode } from './metadatastore/metadataStoreSetMode';
import { EmptyStoreData } from './metadatastore/metadataStoreLoad';
import { metadataStoreSave } from './metadatastore/metadataStoreSave';
import { metadataStoreRemovePage } from './metadatastore/metadataStoreRemovePage';
import { metadataStoreAddPage } from './metadatastore/metadataStoreAddPage';
import { metadataStoreGetMetadataValue } from './metadatastore/metadataStoreGetMetadataValue';
import { metadataStoreGetTemplates } from './metadatastore/metadataStoreGetTemplates'
import { metadataStoreGetPageTagCompletions } from './metadatastore/metadataStoreGetPageTagCompletions';
import { metadataStoreGetItemTagCompletions } from './metadatastore/metadataStoreGetItemTagCompletions';
import { metadataStoreGetPageTagValueCompletions } from './metadatastore/metadataStoreGetPageTagValueCompletions';
import { metadataStoreGetItemTagValueCompletions } from './metadatastore/metadataStoreGetItemTagValueCompletions';
import { metadataStoreGetGeneratedData } from './metadatastore/metadataStoreGetGeneratedData';
import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { usePersistantArrayReference } from '../hooks/usePersistantArrayReference';
import { metadataStoreGetLinkCompletions } from './metadatastore/metadataStoreGetLinkCompletions';
import { getHeadingID } from '../data/util';

export const getPageTagPath = (tag: string) => `meta/${getHeadingID(tag)}/` as DirPath;
export const getPageTagValuePath = (tag: string, value: string) => `meta/${getHeadingID(tag)}/${getHeadingID(value)}/` as DirPath;
export const getItemTagPath = (tag: string, type: string='') => tag === 'type' ? `items/` as DirPath : `items/${getHeadingID(type)}/${getHeadingID(tag)}/` as DirPath;
export const getItemTagValuePath = (tag: string, value: string, type: string='') => tag === 'type' ? `items/${getHeadingID(value)}/` as DirPath : `items/${getHeadingID(type)}/${getHeadingID(tag)}/${getHeadingID(value)}/` as DirPath;

export interface MetadataStoreData {
    pages: PageMetadata
    items: ItemMetadata
    metadata: MetadataKeyMap
    itemmetadata: TypeMetadataKeyMap
}
export interface MetadataStore extends MetadataStoreData {
    status: "uninitialized" | "loading" | "loaded" | "error" | "accessdenied"
    mode: "public" | "private"
    statusDetail: string

    publicData: MetadataStoreData | null

    templates: ReturnType<typeof metadataStoreGetTemplates>
    pageLinkCompletions: ReturnType<typeof metadataStoreGetLinkCompletions>
    pageTagCompletions: ReturnType<typeof metadataStoreGetPageTagCompletions>
    itemTagCompletions: ReturnType<typeof metadataStoreGetItemTagCompletions>
    pageTagValueCompletions: ReturnType<typeof metadataStoreGetPageTagValueCompletions>
    itemTagValueCompletions: ReturnType<typeof metadataStoreGetItemTagValueCompletions>

    setStatusUninitialized: (mode: MetadataStore["mode"]) => void
    setStatusLoading: () => void
    setStatusLoaded: (mode: MetadataStore["mode"], data: MetadataStoreData, publicData: MetadataStore["publicData"]) => void
    setStatusError: (error: any) => void
    setStatusAccessDenied: () => void

    save: () => Promise<void>
    setData: (data: MetadataStoreData, publicData: MetadataStore["publicData"], save?: boolean) => Promise<void>

    load: (initializeOnly: boolean, mode: MetadataStore["mode"]) => Promise<void>
    setMode: (mode: MetadataStore["mode"]) => Promise<void>
    removePage: (path: DirPath) => Promise<void>
    addPage: (path: DirPath, acl: BasicACL, metadata: Metadata, itemMetadata: PageItemMetadata) => Promise<void>
    updatePage: (path: DirPath, acl: BasicACL, metadata: Metadata, itemMetadata: PageItemMetadata) => Promise<void>

    getMetadataValue: (path: DirPath, key: string, def: string) => string
    getPathTitle: (path: string) => string
    getTagLabel: (tag: string) => string
    getTagValueLabel: (tag: string, value: string) => string
}

const EmptyGeneratedData = {
    templates: [],
    pageLinkCompletions: [],
    pageTagCompletions: [],
    itemTagCompletions: {},
    pageTagValueCompletions: {},
    itemTagValueCompletions: {},
} as StoreGeneratedData

export const useMetadataStore = create<MetadataStore>()(
    shared(
        (set, get) => ({
            status: "uninitialized",
            mode: "public",
            statusDetail: "",
            pages: {},
            items: {},
            metadata: {},
            itemmetadata: {},

            publicData: null,
            
            templates: [],
            pageLinkCompletions: [],
            pageTagCompletions: [],
            itemTagCompletions: {},
            pageTagValueCompletions: {},
            itemTagValueCompletions: {},

            setStatusUninitialized: (mode) => set(() => ({ status: "uninitialized", statusDetail: "", mode, ...EmptyStoreData, publicData: null, ...EmptyGeneratedData } as Partial<MetadataStore>)),
            setStatusLoading: () => set(() => ({ status: "loading", statusDetail: "", ...EmptyStoreData, publicData: null, ...EmptyGeneratedData } as Partial<MetadataStore>)),
            setStatusLoaded: (mode, data, publicData) => set(state => ({
                status: "loaded",
                statusDetail: "",
                ...data,
                publicData,
                ...metadataStoreGetGeneratedData(data)
            } as Partial<MetadataStore>)),
            setStatusError: (error) => set(() => ({ status: "error", statusDetail: error.toString() } as Partial<MetadataStore>)),
            setStatusAccessDenied: () => set(() => ({ status: "accessdenied", statusDetail: "" } as Partial<MetadataStore>)),

            save: () => metadataStoreSave(get()),
            setData: (data, publicData, save=true) => {
                const newState = { ...data, publicData, ...metadataStoreGetGeneratedData(data) } as MetadataStore
                set(() => newState)

                return save ? metadataStoreSave(newState) : Promise.resolve()
            },

            load: async (initializeOnly, mode) => metadataStoreLoad(initializeOnly, mode, get()),
            setMode: async (mode) => metadataStoreSetMode(mode, get()),
            removePage: async (path) => metadataStoreRemovePage(path, get()),
            addPage: async (path, acl, metadata, itemMetadata) => metadataStoreAddPage(path, acl, metadata, itemMetadata, get()),
            updatePage: async (path, acl, metadata, itemMetadata) => {
                await metadataStoreRemovePage(path, get(), false)
                return await metadataStoreAddPage(path, acl, metadata, itemMetadata, get(), false)
            },

            getMetadataValue: (path, key, def) => metadataStoreGetMetadataValue(path, key, def, get()),
            getPathTitle: (path) => metadataStoreGetMetadataValue(path as DirPath, "title", path.replace(/^(.*\/)(^\/+)\/$/, "$1"), get()),
            getTagLabel: (tag) => metadataStoreGetMetadataValue(getPageTagPath(tag), "title", tag, get()),
            getTagValueLabel: (tag, value) => metadataStoreGetMetadataValue(getPageTagValuePath(tag, value), "title", value, get()),
        }),
        { name: 'metadata-store' }
    ),
)

export const useMetadataStoreData = () => useMetadataStore(useShallow(state => ({
    pages: state.pages,
    items: state.items,
    metadata: state.metadata,
    itemmetadata: state.itemmetadata
} as MetadataStoreData)))

export const useTagValues = (tag: string) => {
    const metadata = useMetadataStore(state => state.metadata)
    return Object.keys(metadata[tag]).sort()
}

interface PathTitlesMap {
    [path: string]: string
}
const getPathTitles = (paths: string[], data: MetadataStoreData) => paths.map(path => ({
    path,
    title: metadataStoreGetMetadataValue(
        path as DirPath,
        "title",
        path.replace(/^(.*\/)(^\/+)\/$/, "$1"),
        data
    )
})).reduce((m, d) => ({
    ...m,
    [d.path]: d.title
}), {} as PathTitlesMap);
export const usePathTitles = (paths: string[]) => {
    const pathsReference = usePersistantArrayReference(paths)
    const data = useMetadataStoreData()
    const [titles, setTitles] = useState(getPathTitles(pathsReference, data))
    useEffect(() => setTitles(getPathTitles(pathsReference, data)), [pathsReference, data])

    return titles
}

export const useMetadataValue = (path: DirPath, key: string, def: string) => {
    const data = useMetadataStoreData()
    const [result, setResult] = useState(metadataStoreGetMetadataValue(path, key, def, data))

    useEffect(() => setResult(metadataStoreGetMetadataValue(path, key, def, data)), [path, key, def, data])

    return result
}

export const useMetadataCompletions = () => useMetadataStore(useShallow((state) => ({
    pageLinkCompletions: state.pageLinkCompletions,
    pageTagCompletions: state.pageTagCompletions,
    pageTagValueCompletions: state.pageTagValueCompletions,
    itemTagCompletions: state.itemTagCompletions,
    itemTagValueCompletions: state.itemTagValueCompletions
})))

// Load from S3 by default
metadataStoreLoad(true, "public", useMetadataStore.getState())