import React from 'react';
import BodyView from './view/BodyView';
import BodyEdit from './edit/BodyEdit';
import { usePageState } from '../state/usePageState';
import { usePageMarkdown } from '../state/usePageMarkdown';
import { usePageMedia } from '../state/usePageMedia';

export type PageMode = "view" | "edit";

const Body = () => {
    const status = usePageState(state => state.status)
    const markdownStatusDetail = usePageMarkdown(state => state.statusDetail)
    const mediaStatusDetail = usePageMedia(state => state.statusDetail)

    switch (status) {
        case 'uninitialized': return <div>Initializing...</div>;
        case 'loading': return <div>Loading...</div>;
        case 'accessdenied': return <div>You do not have permission to view this page.</div>;
        case 'error': return <div>Errors:
            <ul>
                {markdownStatusDetail.length ? <li>{markdownStatusDetail}</li> : null}
                {mediaStatusDetail.length ? <li>{mediaStatusDetail}</li> : null}
            </ul>
        </div>;
        case 'editable': return <BodyEdit />;
        case 'loaded': return <BodyView />;
        case 'saving': return <div>Saving...</div>;
    }
}

export default Body;