import { Storage } from "aws-amplify";
import { DirPath } from "../../data/types";
import { getTitleFromFilename } from "../../data/util";
import { PageMarkdownStore } from "../usePageMarkdown";

export const pagemarkdownInitializeFromTemplate = async (template: DirPath, store: PageMarkdownStore) => {
    const result = await Storage.get(template + "index.md", { download: true, cacheControl: 'no-cache' })
    if (result.Body === undefined) throw new Error(`Could not access template ${template}`)
    const templateMarkdown = await (result.Body as Blob).text()

    const pageName = store.path.split("/").pop() || ""
    const title = getTitleFromFilename(pageName)

    const markdown = templateMarkdown.replace(/^---\n(.*?)\n---\n/s, '')
        .replace(/\[\[pagename\]\]/g, pageName)
        .replace(/\[\[pagetitle\]\]/g, title)

    store.setMarkdown(store.path, "private", markdown, false)
}
