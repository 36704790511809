import React, { useCallback, useState } from "react"
import { DirPath } from "../data/types";
import { trim } from "../data/util";

interface ElementInformation {
    link?: string;

    top: number;
    left: number;
    right: number;
    bottom: number;
    width: number;
    height: number;
}

const getElementInformation = (path: DirPath, element: HTMLElement): ElementInformation => {
    const rect = element.getBoundingClientRect()
    const title = element.dataset.title ? element.dataset.title : trim(element.innerHTML)
    const id = getElementID(element)

    return {
        link: element.matches("H1,H2,H3,H4,H5,H6,.dnd5e-stat-block") ? `[${title}](/${path}${id})` : undefined,

        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
        right: rect.left + window.scrollX + rect.width,
        bottom: rect.left + window.scrollX + rect.height,
        width: rect.width,
        height: rect.height
    };
}

type PotentialElement = HTMLElement | null

type ClipperFn = () => void

const listItemIndex = (listItem: Element): number => {
    var i = 0;

    while(listItem.previousElementSibling) {
        listItem = listItem.previousElementSibling;
        i++;
    }

    return i + 1;
}

const getElementHTML = (element: HTMLElement) => element.outerHTML

const getListItemHTML = (element: HTMLElement) => {
    const parent = element.parentElement
    if (parent === null) return ''

    if (parent.tagName === "UL") {
        return `<ul class="${parent.className}">${element.outerHTML}</ul>`
    }

    const index = listItemIndex(element)
    return `<ol class="${parent.className}" start="${index}">${element.outerHTML}</ol>`
}

const getTableRowHTML = (element: HTMLElement) => {
    let head = ''
    let className = ''
    let parent = element
    while (parent.tagName !== "TABLE" && parent.tagName !== "THEAD") {
        if (parent.parentElement === null) break
        parent = parent.parentElement
    }
    if (parent.tagName === "TABLE") {
        head = parent.getElementsByTagName("thead").item(0)?.outerHTML || ''
        className = parent.className
    }

    return `<table class="${className}">${head}<tbody>${element.outerHTML}</tbody></table>`
}

const getSelectionHTML = (range: Range) => Array.from(range.cloneContents().children).map(el => el.outerHTML).join("")

function getElementID(element: HTMLElement) {
    if (element && element.id) {
        return `#${element.id}`
    }

    return '';
}

function getNearestElementID(element: HTMLElement) {
    let target: Element | null = element
    while (target) {
        if (target && target.id) {
            return `#${target.id}`
        }

        target = target.previousElementSibling || target.parentElement
    }

    return '';
}

const copyHTMLToClipboard = (path: DirPath, element: HTMLElement) => {
    let html = ''

    const selection = window.getSelection()
    if (!selection || selection.isCollapsed) {
        switch (element.tagName) {
            case "LI": html = getListItemHTML(element); break
            case "TR": html = getTableRowHTML(element); break
            default: html = getElementHTML(element); break
        }
    } else {
        html = getSelectionHTML(selection.getRangeAt(0))
    }

    const snippet =`[[snippet]]\n| ${path}\n| /${path}${getNearestElementID(element)}\n| ${html}\n`

    navigator.clipboard.writeText(snippet)
}

const clipableElements = ["H1","H2","H3","H4","H5","H6","P","TR","LI","DL"]

const getClippableParent = (element: HTMLElement): HTMLElement | null => {
    if (element.closest(".dnd5e-stat-block")) return element.closest(".dnd5e-stat-block")
    
    const candidate = element.closest(clipableElements.join(","))
    if (candidate === null) return null
    if (candidate.matches(".ignore-snippet")) return null

    return candidate as HTMLElement
}

const getLinkCopier = (information: ElementInformation): ClipperFn | undefined => {
    const link = information.link

    if (link) {
        return () => navigator.clipboard.writeText(link)
    }

    return undefined
}

export interface ClipperResult {
    element: PotentialElement;
    information?: ElementInformation;
    onMouseOver: React.MouseEventHandler<HTMLElement>;
    onMouseUp: React.MouseEventHandler<HTMLElement>;
    copyLink?: ClipperFn;
    copyClip?: ClipperFn;
}

const useClipper = (path: DirPath): ClipperResult => {
    const [element, setElement] = useState(null as PotentialElement)

    const onMouseOver = useCallback<React.MouseEventHandler<HTMLElement>>((event) => {
        if (event.target === undefined) return
        if (event.buttons === 1) return

        const selection = window.getSelection()
        if (selection && !selection.isCollapsed) return

        let target = getClippableParent(event.target as HTMLElement)

        setElement(target)
    }, [])

    const onMouseUp = useCallback<React.MouseEventHandler<HTMLElement>>((event) => {
        if (event.target === undefined) return onMouseOver(event)

        const selection = window.getSelection()
        if (!selection || selection.isCollapsed) return onMouseOver(event)
        const newRange = selection.getRangeAt(0)

        let target = newRange.startContainer.parentElement
        if (target === null) return onMouseOver(event)
        while (clipableElements.indexOf(target.tagName) === -1) {
            if (target.parentElement === null) return
            target = target.parentElement
        }

        setElement(target)
    }, [onMouseOver])

    const copyToClipboard = useCallback(() => {
        if (element === null) return
        
        if (element.dataset.clip !== undefined) {
            navigator.clipboard.writeText(element.dataset.clip)
        } else {
            copyHTMLToClipboard(path, element)
        }
    }, [element, path])

    if (element) {
        const information = getElementInformation(path, element)

        return {
            element,
            information,
            onMouseOver,
            onMouseUp,
            copyClip: copyToClipboard,
            copyLink: getLinkCopier(information),
        }
    } else {
        return {
            element: null,
            onMouseOver,
            onMouseUp
        }
    }
}

export default useClipper