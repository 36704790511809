import { Completion } from '@codemirror/autocomplete';
import { MetadataStoreData } from '../useMetadataStore';

export const metadataStoreGetPageTagCompletions = (store: MetadataStoreData): Completion[] => {
    const simpleCompletions = Object.keys(store.metadata).map((key): Completion => ({
        label: key,
        type: "macro",
        apply: `${key}: `,
        detail: "Frontmatter"
    }));

    const fullCompletions = simpleCompletions.map(completion => Object.keys(store.metadata[completion.label]).map((value): Completion => ({
        label: `${completion.label}: ${value}`,
        type: "macro",
        apply: `${completion.label}: ${value}`,
        detail: "Frontmatter"
    }))).filter(v => v.length < 10).flat().concat(simpleCompletions).sort((a, b) => a.label.localeCompare(b.label))

    return fullCompletions
}
