import { FilePath } from "../../data/types";
import { Dimensions } from "./types";
import { Storage } from 'aws-amplify';

export const getImageDimensionsFromFile = async (file: File): Promise<Dimensions | null> => new Promise(async (resolve) => {
    var img = document.createElement('img');
    var blob = URL.createObjectURL(file);
    img.src = blob;
    img.onload = function() {
        resolve({
            width: `{img.width}px`,
            height: `{img.height}px`
        } as Dimensions);
    }
    img.onerror = function() {
        resolve(null);
    }
});

export const getImageDimensionsFromPath = async (path: FilePath): Promise<Dimensions | null> => new Promise(async (resolve) => {
    const imgSrc = await Storage.get(path);
    const img = document.createElement('img');
    img.src = imgSrc;
    img.onload = function() {
        resolve({
            width: `${img.width.toString()}px`,
            height: `${img.height.toString()}px`
        } as Dimensions);
    }
    img.onerror = function() {
        resolve(null);
    }
});
