import React from 'react'
import { NeutralCreatureArmor, NeutralCreatureHitDice, NeutralCreatureMove, hitDiceToSkillBonus } from '../../neutralcreature'
import { NeutralAC } from './NeutralAC'
import NeutralMoveLine from './NeutralMove'
import DndPropertyLine from '../dnd/DndPropertyLine'

interface NeutralBasicPropertiesOptions {
    armor: NeutralCreatureArmor
    hitdice: NeutralCreatureHitDice
    move: NeutralCreatureMove
}

const NeutralBasicProperties = ({ armor, hitdice, move }: NeutralBasicPropertiesOptions) => {
    const skillBonus = hitDiceToSkillBonus(hitdice, "full")

    return <div className="block basic-properties">
        <NeutralAC armor={armor} hitdice={hitdice} />
        <DndPropertyLine label="Hit Points">
            {Math.ceil(hitdice * (4.5 + hitDiceToSkillBonus(hitdice, "none")))} ({hitdice}d8 + {hitDiceToSkillBonus(hitdice, "none") * hitdice})
        </DndPropertyLine>
        <NeutralMoveLine move={move} />
        <DndPropertyLine label="To hit/Strong saves">+{skillBonus}</DndPropertyLine>
        <DndPropertyLine label="Spell Save DC">{8 + skillBonus}</DndPropertyLine>
    </div>
}

export default NeutralBasicProperties