import { DirPath } from "./types";
import { Parser } from 'node-sql-parser';
import interpretWhere from "./query/interpretWhere";
import functionalWhere from "./query/functionalWhere";
import { selectHeadings, QueryResultHeading, resultToRow, reduceToUnique } from "./query/select";
import { getTableReference } from "./query/from";
import { useCallback, useEffect, useState } from "react";
import { MetadataStore, useMetadataStore } from "../state/useMetadataStore";
import { Metadata } from "../state/metadatastore/types";

export interface PageReference {
    type: "page";
    path: DirPath;
}

export interface ItemReference {
    type: "item";
    path: DirPath;
    id: string;
}

export type RowReference = PageReference | ItemReference

type RowCompareFunction = (a: Metadata, b: Metadata) => number;
const orderByToFunction = (order: any): RowCompareFunction =>{
    if (order === null) return () => -1;
    if (order.constructor !== Array) return () => -1;

    return order.map((node): RowCompareFunction => (a: Metadata, b: Metadata) => {
        const aVal = a[node.expr.column] || '';
        const bVal = b[node.expr.column] || '';

        const aComparable = Array.isArray(aVal) ? aVal.sort().join(", ") : aVal;
        const bComparable = Array.isArray(bVal) ? bVal.sort().join(", ") : bVal;

        return node.type === "ASC"
            ? aComparable.localeCompare(bComparable, undefined, {numeric: true, sensitivity: 'base'})
            : bComparable.localeCompare(aComparable, undefined, {numeric: true, sensitivity: 'base'});
    }).reduce((aFn, bFn): RowCompareFunction => (aPath, bPath) => {
        const comparison = aFn(aPath, bPath);

        return comparison === 0 ? bFn(aPath, bPath) : comparison;
    });
}

interface QueryResultCellItem {
    label: string;
    path: DirPath;
}
export interface QueryResultCell {
    key: string;
    linkable: boolean;
    items: QueryResultCellItem[];
}
export interface QueryResultRow {
    reference: RowReference;
    metadata: Metadata;
    cells: QueryResultCell[]
}
export interface QueryResult {
    headings: QueryResultHeading[];
    rows: QueryResultRow[]
}

const executeMetadataQuery = (metadataDatabase: MetadataStore, query: string): QueryResult => {
    const parser = new Parser();
    const {distinct, columns, from, where, orderby} = parser.astify(query) as any;

    let rows: QueryResultRow[]

    const interpretedWhere = interpretWhere(where)
    const table = getTableReference(from[0].table, interpretedWhere)
    const headings = columns.map(selectHeadings(metadataDatabase, table))
    const filter = functionalWhere(interpretedWhere);
    const order = orderByToFunction(orderby);

    switch (table.table) {
        case "page":
            rows = Object
                .keys(metadataDatabase.pages)
                .filter(path => filter(metadataDatabase.pages[path]))
                .map((path): PageReference => ({ type:"page", path: path as DirPath}))
                .sort((a, b) => order(metadataDatabase.pages[a.path], metadataDatabase.pages[b.path]))
                .map(resultToRow(metadataDatabase, headings, table));

            if (distinct === "DISTINCT") {
                rows = reduceToUnique(rows)
            }
            
            break;

        case "item":
            rows = Object
                .keys(metadataDatabase.items)
                .map((path): ItemReference[] => Object.keys(metadataDatabase.items[path]).map((id): ItemReference => ({ type:"item", path: path as DirPath, id })))
                .flat()
                .filter(ref => filter(metadataDatabase.items[ref.path][ref.id]))
                .sort((a, b) => order(metadataDatabase.items[a.path][a.id], metadataDatabase.items[b.path][b.id]))
                .map(resultToRow(metadataDatabase, headings, table));

            if (distinct === "DISTINCT") {
                rows = reduceToUnique(rows)
            }

            break;

        default:
            throw new Error(`Unknown table: ${table}`)
    }

    return {
        headings,
        rows
    };
}

type UseQueryResult = [QueryResult | null, any]
export const useQuery = (query: string): UseQueryResult => {
    const database = useMetadataStore()
    const [result, setResult] = useState([null, null] as UseQueryResult)

    useEffect(() => {
        try {
            setResult([executeMetadataQuery(database, query), null])
        } catch(error: any) {
            console.error(error)
    
            if (error.name === "SyntaxError") {
                setResult([null, error])
            }
    
            setResult([null, error])
        }
    }, [database, query])

    return result
}

const getRandomValue = (rows: number): number => Math.floor(Math.random() * rows);

export const useRandomRow = (rows: number): [number, () => void] => {
    const [row, updateRandomValue] = useState(getRandomValue(rows))

    const randomize = useCallback(() => {
        updateRandomValue(getRandomValue(rows));
    }, [rows]);

    return [row, randomize];
}
