import { HeadingLevel } from "@aws-amplify/ui-react";
import { CustomMarkdownBlock, parseCurrentTagLine, parseTagLine } from "./_utils";
import { Completion } from "@codemirror/autocomplete";
import TaggedTitle from "./components/TaggedTitle";
import { Metadata } from "../../state/metadatastore/types";
import { getHeadingID } from "../util";

type CompletionFilter = (completion: Completion) => boolean

const linesToTagsFilter = (lines: string[]): CompletionFilter => {
    const potentialTags = lines.map(line => line.replace(/^\| ([-\w_]+):.*$/, '$1'))
    const onlyTags: string[] = []

    for (let tag of potentialTags) {
        if (tag.search(/^[-\w_]+$/) === 0) {
            onlyTags.push(tag)
        }
    }

    const re = new RegExp(`^(${onlyTags.join("|")})(:|$)`)

    return (completion: Completion) => completion.label.search(re) === -1
}

interface TaggedTitleData {
    id: string;
    title: string;
    level: HeadingLevel;
    metadata: Metadata;
}
export const TaggedTitleBlock = new CustomMarkdownBlock<TaggedTitleData>({
    name: "taggedtitle",
    parser: (block) => {
        if (block.content.length === 0) throw new Error("expecting at least one row");

        let title: string | undefined = block.content.shift();
        if (title === undefined) throw new Error("expecting a title");

        let invalidIndex = block.content.findIndex(line => line.search(/^[-\w_]+: .*$/) === -1);
        if (invalidIndex > -1) throw new Error(`line ${invalidIndex + 2} is not in the format tag_name: value`);

        const metadata = block.content.map((line) => {
            const parts = line.split(": ");

            return {
                key: parts[0] || '',
                value: parts[1] || ''
            }
        }).reduce((cur, n) => Object.assign(cur, { [n.key]:n.value }), {} as Metadata);
        metadata.title = title.replace(/^(#+ )/, '');

        if (metadata.type === undefined) throw new Error(`missing a type tag`);

        return {
            title: title.replace(/^(#+ )/, ''),
            id: getHeadingID(title),
            level: title.search(/^#+ /) === -1 ? 6 : title.replace(/^(#+) .*$/, "$1").length as HeadingLevel,
            metadata
        }
    },
    renderToReact: (data) => {
        return <TaggedTitle
            title={data.title}
            level={data.level}
            tags={data.metadata}
        />
    },
    getCompletions(context, text, startIndex, currentIndex, completionDatabase) {
        if (currentIndex === startIndex + 1) return null

        const currentTagLine = parseCurrentTagLine(context, text[currentIndex])

        if (currentIndex === startIndex + 2) {
            // this should be the type tag
            switch (currentTagLine.mode) {
                case "name":
                    return { from: currentTagLine.match.from + 2, options: [{
                        label: "New type",
                        type: "macro",
                        apply: `type: `,
                        detail: "Tagged title type"
                    }, ...completionDatabase.item.tagValue.general.type.map((v: Completion) => ({...v, label:`type: ${v.label}`, apply: `type: ${v.label}`, detail: "Tagged title type"}))] };;
                case "value":
                    return {
                        from: currentTagLine.match.from + "type".length + 4,
                        options: completionDatabase.item.tagValue.general.type
                    }
                case "none":
                    return null
            }
        }

        // these is a type specific tag
        const typeTagLine = parseTagLine(text[startIndex+2] || "")
        if (typeTagLine.mode !== "value") return null

        switch (currentTagLine.mode) {
            case "name":
                if (completionDatabase.item.tag[typeTagLine.value] === undefined) return null

                if (startIndex + 3 !== currentIndex) {
                    return {
                        from: currentTagLine.match.from + 2,
                        options: completionDatabase.item.tag[typeTagLine.value].filter(linesToTagsFilter(text.slice(startIndex + 3, currentIndex)))
                    };
                } else {
                    return {
                        from: currentTagLine.match.from + 2,
                        options: completionDatabase.item.tag[typeTagLine.value]
                    };
                }
            case "value":
                return {
                    from: currentTagLine.match.from + currentTagLine.name.length + 4,
                    options: completionDatabase.item.tagValue[typeTagLine.value][currentTagLine.name]
                }
            case "none":
                return null
        }
    },
    template: `[[taggedtitle]]
| ##### `
})