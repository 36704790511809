import { BlockNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { BlockParserFunctions, PeekAllBlockFunction } from "../parser"

const isNotBreak = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number): boolean => {
    if (index === 0) return false

    const match = peekAll(range.toArray())
    if (match === null || match.name === "break") return false

    return true
}

const processBreakRangesInjected = (ranges: NonEmptyMarkdownRangeArray): BlockNode => ({
    type: "block",
    name: "break",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    children: []
})

export const BreakParserFunctions: BlockParserFunctions = {
    name: "break",
    peek: (range: MarkdownRange, index: number) => range.markdown[0] === "\n",
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotBreak(peekAll),
        processBreakRangesInjected,
        "Expected break"
    )
}
