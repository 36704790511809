import { Storage } from 'aws-amplify';
import { PageMediaStore } from "../usePageMedia";
import { FilePath } from '../../data/types';
import { putObjectACL } from '../../data/s3';

export const pagemediaSave = async (store: PageMediaStore): Promise<void> => {
    if (store.status !== "loaded") throw Error("Files are not initialized")

    const mediaPath = store.path + "media.json" as FilePath
    const savableMedia = store.items.filter(v => v.status === "loaded" || v.status === "deleted");

    await Storage.put(mediaPath, JSON.stringify(savableMedia), { cacheControl: 'no-cache' })
    await putObjectACL(`public/${mediaPath}`, store.acl)

    if (store.exists === false) store.setExists(true)
}
