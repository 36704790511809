import { parseMacro } from "../../data/markdownblocks/_all"
import { BlockNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllBlockFunction, ParseAllInlineFunction, ParseAllBlockFunction, BlockParserFunctions } from "../parser"

const testMacroLineRE = /^\|/
const isNotMacroInjected = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number): boolean => {
    if (range.markdown.slice(0, 2) === "[[") return false
    if (range.markdown.search(testMacroLineRE) !== -1) return false

    return true
}

const processMacroRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => {
    const macroData = parseMacro(ranges.flatten().markdown)

    if (macroData.status === "error") return {
        type: "block",
        name: "error",
        start: ranges.getStart(),
        end: ranges.getEnd(),
        meta: {
            errorMessage: macroData.error
        },
        children: []
    }

    return {
        type: "block",
        name: "macro",
        start: ranges.getStart(),
        end: ranges.getEnd(),
        meta: {
            ...macroData.data as any as { [key: string]: string },
            macro: macroData.name
        },
        children: []
    }
}

export const MacroParserFunctions: BlockParserFunctions = {
    name: "macro",
    peek: (range, index, obj, ancestors) => {
        if (ancestors[ancestors.length - 1] === "macro") return false
        
        return range.markdown.slice(0, 2) === "[["
    },
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotMacroInjected(peekAll),
        processMacroRangesInjected(parseBlocks, parseInline),
        "Expected start of macro"
    )
}
