import { Metadata } from "../../state/metadatastore/types"

export type StaticType = string | number | undefined

type WhereAny           = { type: "any" }
type WhereNodeColumn    = { type: "column", name: string }
type WhereNodeStatic    = { type: "static", value: StaticType }
type WhereNodeRegex     = { type: "regex", regex: RegExp }
type WhereNodeEquals    = { type: "=", column: WhereNodeColumn, value: WhereNodeStatic }
type WhereNodeNotEquals = { type: "!=", column: WhereNodeColumn, value: WhereNodeStatic }
type WhereNodeAnd       = { type: "AND", clauses: WhereNodeBoolean[] }
type WhereNodeOr        = { type: "OR", clauses: WhereNodeBoolean[] }
type WhereNodeLike      = { type: "LIKE", column: WhereNodeColumn, regex: WhereNodeRegex }
type WhereNodeNotLike   = { type: "NOT LIKE", column: WhereNodeColumn, regex: WhereNodeRegex }
type WhereNodeBoolean   = WhereNodeEquals | WhereNodeNotEquals | WhereNodeAnd | WhereNodeOr | WhereNodeLike | WhereNodeNotLike
export type WhereNode = WhereAny | WhereNodeColumn | WhereNodeStatic | WhereNodeRegex | WhereNodeEquals | WhereNodeNotEquals | WhereNodeAnd | WhereNodeOr | WhereNodeLike | WhereNodeNotLike

const equalNode = (left: WhereNode, right: WhereNode): WhereNodeEquals => {
    if (left.type === "column" && right.type === "static") {
        return { type: "=", column: left, value: right }
    }
    if (right.type === "column" && left.type === "static") {
        return { type: "=", column: right, value: left }
    }

    throw new Error("= clauses must include a column and a static value")
}

const unequalNode = (left: WhereNode, right: WhereNode): WhereNodeNotEquals => {
    if (left.type === "column" && right.type === "static") {
        return { type: "!=", column: left, value: right }
    }
    if (right.type === "column" && left.type === "static") {
        return { type: "!=", column: right, value: left }
    }

    throw new Error("= clauses must include a column and a static value")
}

const andNode = (left: WhereNode, right: WhereNode): WhereNode => {
    if (left.type === "any") return right
    if (right.type === "any") return left
    if (left.type === "AND" && right.type === "AND") {
        return { type:"AND", clauses:[...left.clauses, ...right.clauses]}
    }
    if (left.type === "AND" && right.type !== "column" && right.type !== "static" && right.type !== "regex") {
        return { type:"AND", clauses:[...left.clauses, right]}
    }
    if (right.type === "AND" && left.type !== "column" && left.type !== "static" && left.type !== "regex") {
        return { type:"AND", clauses:[left, ...right.clauses]}
    }
    if (left.type !== "column" && left.type !== "static" && left.type !== "regex" && right.type !== "column" && right.type !== "static" && right.type !== "regex") {
        return { type:"AND", clauses:[left, right]}
    }

    throw new Error("AND clauses must be composed of boolean clauses")
}

const orNode = (left: WhereNode, right: WhereNode): WhereNode => {
    if (left.type === "any" || right.type === "any") return left
    if (left.type === "OR" && right.type === "OR") {
        return { type:"OR", clauses:[...left.clauses, ...right.clauses]}
    }
    if (left.type === "OR" && right.type !== "column" && right.type !== "static" && right.type !== "regex") {
        return { type:"OR", clauses:[...left.clauses, right]}
    }
    if (right.type === "OR" && left.type !== "column" && left.type !== "static" && left.type !== "regex") {
        return { type:"OR", clauses:[left, ...right.clauses]}
    }
    if (left.type !== "column" && left.type !== "static" && left.type !== "regex" && right.type !== "column" && right.type !== "static" && right.type !== "regex") {
        return { type:"OR", clauses:[left, right]}
    }

    throw new Error("OR clauses must be composed of boolean clauses")
}

const likeNode = (left: WhereNode, right: WhereNode): WhereNodeLike => {
    if (left.type === "column" && right.type === "static" && typeof(right.value) === "string") {
        return { type: "LIKE", column: left, regex: { type: "regex", regex: new RegExp(right.value) } }
    }
    else if (right.type === "column" && left.type === "static" && typeof(left.value) === "string") {
        return { type: "LIKE", column: right, regex: { type: "regex", regex: new RegExp(left.value) } }
    }
    
    throw new Error("LIKE clauses are expected to compare a column with a regex pattern")
}

const notLikeNode = (left: WhereNode, right: WhereNode): WhereNodeNotLike => {
    if (left.type === "column" && right.type === "static" && typeof(right.value) === "string") {
        return { type: "NOT LIKE", column: left, regex: { type: "regex", regex: new RegExp(right.value) } }
    }
    else if (right.type === "column" && left.type === "static" && typeof(left.value) === "string") {
        return { type: "NOT LIKE", column: right, regex: { type: "regex", regex: new RegExp(left.value) } }
    }
    
    throw new Error("NOT LIKE clauses are expected to compare a column with a regex pattern")
}

const columnNode = (name: string): WhereNodeColumn => ({ type: "column", name })

const columnStatic = (value: string | number | undefined): WhereNodeStatic => ({ type: "static", value })


const interpretWhere = (where: any): WhereNode => {
    if (!where) return { type: "any" }

    switch (where.type) {
        case "binary_expr":
            switch (where.operator) {
                case "=":
                    return equalNode(interpretWhere(where.left), interpretWhere(where.right))
                case "!=":
                    return unequalNode(interpretWhere(where.left), interpretWhere(where.right))
                case "AND":
                    return andNode(interpretWhere(where.left), interpretWhere(where.right))
                case "OR":
                    return orNode(interpretWhere(where.left), interpretWhere(where.right))
                case "IS NOT":
                    return unequalNode(interpretWhere(where.left), interpretWhere(where.right))
                case "LIKE":
                    return likeNode(interpretWhere(where.left), interpretWhere(where.right))
                case "NOT LIKE":
                    return notLikeNode(interpretWhere(where.left), interpretWhere(where.right))
                default:
                    throw Error(`Unexpected operator: ${where.operator}`);
            }
        case "column_ref":
            return columnNode(where.column)
        case "single_quote_string":
            return columnStatic(where.value)
        case "string":
            return columnStatic(where.value)
        case "null":
            return columnStatic(undefined)
        default:
            throw Error(`Unexpected node type: ${where.type}`);
    }
}

export const getStableValues = (where: WhereNode): Metadata => {
    if (where.type === "=") {
        const value = where.value.value;

        if (value === undefined) return {};
        
        return { [where.column.name]: value.toString() };
    }
    if (where.type === "AND") {
        return where.clauses.map(getStableValues).reduce((a, b) => ({ ...a, ...b }), {})
    }

    return {}
}

export default interpretWhere
