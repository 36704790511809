import React from 'react'
import { Dnd5eArmorClass } from '../../../dnd'
import DndPropertyLine from './DndPropertyLine'

interface DndACOptions {
    ac: Dnd5eArmorClass | undefined
}

export const DndAC = ({ ac }: DndACOptions) => {
    if (!ac) return null

    return <DndPropertyLine label="Armor Class">
        {ac.ac} {ac.label.length ? <span>{ac.label}</span> : null}
    </DndPropertyLine>
}
