import React from 'react';
import { Form } from 'react-bootstrap';
import { DirPath } from '../../data/types';
import { useMetadataStore } from '../../state/useMetadataStore';

interface SelectTemplateOptions {
    className?: string
    onChange: (path: DirPath) => void
}

const SelectTemplate = ({ className, onChange }: SelectTemplateOptions) => {
    const templates = useMetadataStore(state => state.templates)

    if (templates.length === 0) return null;

    return <Form.Select className={className} aria-label="Select template" value={"-1"} onChange={({ target }) => target.value !== "-1" && window.confirm(templates[parseInt(target.value)].description) && onChange(templates[parseInt(target.value)].path)}>
        <option value="-1">-- Start from template --</option>
        {templates.map((option, i) => <option key={i} value={i}>{option.title}</option>)}
    </Form.Select>
}

export default SelectTemplate;


