import React, { useCallback, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import './MediaView.css';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { usePageMedia, useResolvedImages, useSignedURL } from '../../state/usePageMedia';
import { MediaItem } from '../../state/pagemedia/types';
//import "pdfjs-dist/build/pdf.worker.entry";

/*const parsePDFasync = async (url: string) => {
    const pdfDocument = await getDocument('helloworld.pdf').promise
    
    const pages = await Promise.all(new Array(pdfDocument.numPages).map((_, page) => pdfDocument.getPage(page).then(page => page.getTextContent()).then(text => ({ page, text }))))
    
    console.log(pages)
}*/


interface MediaItemViewOptions {
    mediaItem: MediaItem
}

const DisabledLinkStyle = {
    color: "grey",
    cursor: "not-allowed"
} as React.CSSProperties

const NonImageView = ({ mediaItem }: MediaItemViewOptions) => {
    const [hovering, setHovering] = useState(false as boolean)
    const [signedHREF, getSignedHREF] = useSignedURL(mediaItem.path, hovering)
    const loadPage = useCallback(async (event: any) => {
        if (signedHREF !== undefined) return true
        
        const href = await getSignedHREF()
        window.location.href = href
        event.preventDefault()
    }, [signedHREF, getSignedHREF])

    return <Card className="mb-1" onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
        <Card.Body>
            <Card.Title>
                <a href={signedHREF || ""} onClick={loadPage} target="_blank" rel="noreferrer" style={signedHREF ? undefined : DisabledLinkStyle}>
                    {mediaItem.metadata.title || mediaItem.metadata.path.split("/").pop()}
                </a>
            </Card.Title>
        </Card.Body>
    </Card>;
}


const MediaView = () => {
    const mediaStatus = usePageMedia(state => state.status)
    const mediaItems = useResolvedImages()

    const images = mediaItems.filter(item => item.status !== "deleted" && item.metadata.type === "image")
    const other = mediaItems.filter(item => item.status !== "deleted" &&item.metadata.type === "other")

    if (mediaStatus === "uninitialized" || mediaStatus === "loading") return <div style={{ textAlign:"center" }}>
        <h1 id="media">Media</h1>
        <Spinner animation="grow" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    </div>

    return images.length || other.length ? <div className="media">
        <h1 id="media">Media</h1>
        {images.length ? <ImageGallery items={images.map(image => ({ original: image.signedPath || "", thumbnail: image.signedThumbPath || "", originalTitle: image.metadata.title, thumbnailTitle: image.metadata.title }))} /> : null}
        {other.length ? <Row xs={1} className="mb-3">
            {other.map((mediaItem, index) => <Col key={index}><NonImageView mediaItem={mediaItem} /></Col>)}
        </Row> : null}
    </div> : null;
}

export default MediaView;