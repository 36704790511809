import { BlockNode } from "../ast"
import { NonEmptyMarkdownRangeArray } from "../markdownrange"
import { BlockParserFunctions, ParseAllBlockFunction, ParseAllInlineFunction, PeekAllBlockFunction } from "../parser"
import { MarkdownRange } from "../markdownrange"

const isNotBlockquoteInjected = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number): boolean => {
    return range.markdown.slice(0, 2) !== "> "
}

const processBlockquoteRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => ({
    type: "block",
    name: "blockquote",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    children: parseBlock(ranges.removePrefix(2))
})

export const BlockquoteParserFunctions: BlockParserFunctions = {
    name: "blockquote",
    peek: (range, index, obj, ancestors) => range.matches(index + 1, "> "),
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotBlockquoteInjected(peekAll),
        processBlockquoteRangesInjected(parseBlocks, parseInline),
        "Expected start of blockquote"
    )
}
