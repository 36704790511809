import React from 'react'
import { NodeWithAncestors } from '../ast'
import { MarkdownContextRenderer } from '../markdown'

export const TableCellView = ({ indexes, children, ancestors, className, style }: NodeWithAncestors<"block">) => {
    if (ancestors[ancestors.length - 3] === "tableheader") {
        return <th className={className} style={style}>
            {children.map((node, i) => <MarkdownContextRenderer key={i} node={node} indexes={[...indexes, i]} ancestors={ancestors} />)}
        </th>
    } else {
        return <td className={className} style={style}>
            {children.map((node, i) => <MarkdownContextRenderer key={i} node={node} indexes={[...indexes, i]} ancestors={ancestors} />)}
        </td>
    }
}