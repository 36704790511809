import React from 'react'
import { BasicPower, CreaturePower } from '../../../dnd'
import DndSubheading from './DndSubheading'
import DndBasicPower from './DndBasicPower'

interface DndReactionPowersOptions {
    powers: CreaturePower[]
    showBreak: boolean
}

const DndReactionPowers = ({ powers, showBreak }: DndReactionPowersOptions) => {
    const reactionPowers = powers.filter(v => v.type === "reaction") as BasicPower[]
    if (reactionPowers.length === 0) return null

    return <div className="block reaction-powers">
        <DndSubheading>Reactions</DndSubheading>
        {reactionPowers.map((power, i) => <DndBasicPower key={i} power={power} />)}
    </div>
}

export default DndReactionPowers