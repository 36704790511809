import { BlockNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllBlockFunction, ParseAllBlockFunction, ParseAllInlineFunction, BlockParserFunctions } from "../parser"

const isNotTableRowInjected = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number): boolean => index !== 0

const RowSuffixRE = /\|[ \r\n]+$/
const processTableRowRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => ({
    type: "block",
    name: "tablerow",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    children: parseBlock(ranges.removeSuffix(RowSuffixRE))
})

export const TableRowParserFunctions: BlockParserFunctions = {
    name: "tablerow",
    peek: (range, index, obj, ancestors) => {
        if (["tablehead", "tablebody"].indexOf(ancestors[ancestors.length - 1]) === -1) return false
        
        return range.markdown[0] === "|"
    },
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotTableRowInjected(peekAll),
        processTableRowRangesInjected(parseBlocks, parseInline),
        "Expected start of a table row"
    )
}
