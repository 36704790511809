import { HeadingLevel } from "@aws-amplify/ui-react";
import { CustomMarkdownBlock } from "./_utils";
import React from "react";
import FunctionalRandomLinksTable from "./components/FunctionalRandomTableLinks";
import { RandomTableReference } from "../../state/randomtables/types";

interface RandomTableLinksData {
    title: string;
    level: HeadingLevel;
    references: RandomTableReference[];
}
export const RandomTableLinksBlock = new CustomMarkdownBlock<RandomTableLinksData>({
    name: "randomtablelinks",
    parser: (block) => {
        if (block.content.length === 0) throw new Error("expecting at least one row")

        let title: string | undefined = block.content.shift();
        if (title === undefined) throw new Error("expecting a title")

        const references = block.content.map((line): RandomTableReference => {
            const parts = line.split("|");
            return {
                title: parts[0].replace(/(^\s*|\s*$)/g, ''),
                randomTable: parts[0].replace(/(^\s*|\s*$)/g, ''),
                path: parts[1].replace(/(^\s*|\s*$)/g, ''),
                setTitles: []
            }
        });

        return {
            title: title.replace(/^(#+ )/, ''),
            level: title.search(/^#+ /) === -1 ? 6 : title.replace(/^(#+) .*$/, "$1").length as HeadingLevel,
            references
        }
    },
    renderToReact: (data) => {
        return <FunctionalRandomLinksTable
            title={data.title}
            level={data.level}
            references={data.references}
        />
    },
    template: `[[randomtablelinks]]
| ##### Table name
| `
})
