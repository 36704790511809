import { HeadingLevels } from "../../data/types";
import { PageData } from "../usePageState";
import { resolveUnchangedElements } from "./pageGenerateTieredToC";
import { TableOfContentsReduction, TableOfContentsTiered } from "./types";

const tagToLevel: {[key:string]: HeadingLevels} = {
    H1: 1,
    H2: 2,
    H3: 3,
    H4: 4,
    H5: 5,
    H6: 6
}

const getHeadingReferences = (): [TableOfContentsTiered[], string] => {
    const flat = Array.from(document.querySelectorAll(".web-show h1, .web-show h2, .web-show h3, .web-show h4, .web-show h5, .web-show h6, h1.web-show, h2.web-show, h3.web-show, h4.web-show, h5.web-show, h6.web-show")).map(el => ({
        id: el.id,
        label: el.innerHTML.replace(/<[^\]]+>/g, ''),
        href: `#${el.id}`,
        level: tagToLevel[el.tagName] ? tagToLevel[el.tagName] : 6
    })).filter(ref => ref.href !== "#");

    const idCounts = flat.map(({ id }) => id).reduce((idCounts, id) => ({...idCounts, [id]: (idCounts[id] || 0) + 1}), {} as {[key: string]: number})
    const nonUniqueIDs = Object.keys(idCounts).filter(id => idCounts[id] > 1)

    const reduction = flat.reduce((a, b) => {
        // create tiered version
        const item: TableOfContentsTiered = { ...b, children:[] };
        if (item.id && nonUniqueIDs.indexOf(item.id) !== -1) {
            delete item.id
            delete item.href
        }

        // trim breadcrumb - remove items from end that couldn't be the new item's parent
        const breadcrumb: TableOfContentsTiered[] = [...a.breadcrumb.filter(i => i.level < b.level), item];

        // it's a new 1st level item
        if (breadcrumb.length === 1) return { toc:[...a.toc, item], breadcrumb };

        // it's a child of the 2nd to last item
        breadcrumb[breadcrumb.length - 2].children.push(item);
        return { toc:a.toc, breadcrumb };
    }, { toc:[], breadcrumb:[] } as TableOfContentsReduction)
    
    return [reduction.toc, flat.map(ref => ref.href).join()];
}

export const pageWatchHeadings = (store: PageData) => {
    const newData = getHeadingReferences()

    return store.tocTiered.length === newData[0].length
        ? resolveUnchangedElements(store.tocTiered, newData[0])
        : newData[0]
}