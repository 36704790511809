
// [[5ecreature]]
// | size: tiny/small/medium/large/huge
// | type: beast/etc
// | ac: 123
// | hitpoints: dice formula
// | speed: 123
// | climb/swim/fly: 213
// | abilities: 16 *17 13 *8 14 10
// | skills: perception stealth
// | proficiency: 3
// | senses: string
// | languages: string
// | cr: 1
// | passive:Name: description [calc]
// | reaction:Name: description [calc]
// | action:Name: description [calc]
// | attack:Name:calc:dice: description [calc]
// | 1:action
// | 2:attack

import React from 'react'
import { parseDieExpression } from "../dice";
import { Dnd5eChallengeRatings, Dnd5eCreature, Dnd5eCreatureSizes, Dnd5eCreatureTypes, parseAttackPower, parseBasicPower, parseDnd5eAbilityScoreSet, parseDnd5eArmorClass, parseDnd5eChallengeRating, parseDnd5eCreatureSize, parseDnd5eCreatureType, parseDnd5eName, parseDnd5eProficiencyModifier, parseDnd5eSkills, parseDnd5eSpeed } from "../dnd";
import { trim } from "../util";
import { CustomMarkdownBlock, parseCurrentTagLine } from "./_utils";
import Dnd5eStatBlock from './components/Dnd5eCreatureStatBlock';
import { Completion } from '@codemirror/autocomplete';

const dnd5eCreatureKeyCompletions = [
    "size","type","ac","hitpoints","walk","climb","swim","fly","abilities","skills","proficiency","senses",
    "damagevulnerabilities","damageimmunities","conditionimmunities","damageresistances","languages","cr",
    "passive","reaction","action","attack"
].map((key): Completion => ({ label: key, type: "macro", apply: `${key}: `, detail: "5e creature metadata" }))
const dnd5eCreatureSizeCompletions = Dnd5eCreatureSizes.map(size => ({ label: size, type: "macro", detail: "5e creature size" }))
const dnd5eCreatureTypeCompletions = Dnd5eCreatureTypes.map(type => ({ label: type, type: "macro", detail: "5e creature types" }))
const dnd5eCreatureSpeedCompletions = [{ label:"30 ft.", type:"macro", detail:"5e creature speed" }]
const dnd5eCreatureAbilitiesCompletions = [{ label:"*10 *10 10 10 10 10", type:"macro", detail:"5e creature abiilties" }]
const dnd5eCreatureProficienciesCompletions = [{ label:"perception,stealth", type:"macro", detail:"5e creature proficiencies" }]
const dnd5eCreatureSensesCompletions = [
    { label:"blindsense 30 ft.", type:"macro", detail:"5e creature senses" },
    { label:"darkvision 60 ft.", type:"macro", detail:"5e creature senses" },
    { label:"tremorsense 10 ft.", type:"macro", detail:"5e creature senses" },
]
const dnd5eCreatureChallengeRatingCompletions = Dnd5eChallengeRatings.map((cr): Completion => ({ label: cr, type: "macro", detail: "5e challenge rating" }))
export const Dnd5eCreatureBlock = new CustomMarkdownBlock<Dnd5eCreature>({
    name: "5ecreature",
    parser: (block) => {
        const keyedLines = block.content.map(line => ({ key:line.replace(/^([^:]+):.*$/, "$1"), value:line.replace(/^[^:]+:(.*)$/, "$1") }))
        const keyMap = keyedLines.reduce((a, b) => ({ ...a, [b.key]:b.value }), {} as {[key: string]:string})

        return {
            name: parseDnd5eName(keyMap.name),
            size: parseDnd5eCreatureSize(keyMap.size),
            type: parseDnd5eCreatureType(keyMap.type),
            ac: parseDnd5eArmorClass(keyMap.ac),
            hitpoints: parseDieExpression(keyMap.hitpoints),
            walk: parseDnd5eSpeed("walk", false, keyMap.walk),
            climb: parseDnd5eSpeed("climb", false, keyMap.climb),
            swim: parseDnd5eSpeed("swim", false, keyMap.swim),
            fly: parseDnd5eSpeed("fly", false, keyMap.fly),
            abilities: parseDnd5eAbilityScoreSet(keyMap.abilities),
            skills: parseDnd5eSkills(keyMap.skills),
            proficiency: parseDnd5eProficiencyModifier(keyMap.proficiency),
            senses: keyMap.senses || "",
            damagevulnerabilities: trim(keyMap.damagevulnerabilities || ""),
            damageimmunities: trim(keyMap.damageimmunities || ""),
            conditionimmunities: trim(keyMap.conditionimmunities || ""),
            damageresistances: trim(keyMap.damageresistances || ""),
            languages: keyMap.languages || "",
            cr: parseDnd5eChallengeRating(keyMap.cr),
            powers: keyedLines
                .filter(({ key }) => ["passive", "reaction", "action", "attack"].indexOf(key) !== -1)
                .map(keyedLine => keyedLine.key === "attack" ? parseAttackPower(keyedLine.value) : parseBasicPower(keyedLine.key, keyedLine.value)),
            raw: "[[5ecreature]]\n" + block.content.map(l => `| ${l}`).join("\n")
        }
    },
    renderToReact: (data) => {
        return <Dnd5eStatBlock creature={data} />
    },
    getCompletions(context, text, startIndex, currentIndex, completionDatabase) {
        if (currentIndex === startIndex + 1) return null

        const currentTagLine = parseCurrentTagLine(context, text[currentIndex])
        if (currentTagLine.mode === "none") return null

        if (currentTagLine.mode === "name") {
            return { from: currentTagLine.match.from + 2, options: dnd5eCreatureKeyCompletions };;
        }

        const frm = currentTagLine.match.from + currentTagLine.name.length + 4
        switch (currentTagLine.name) {
            case "size": return { from:frm, options: dnd5eCreatureSizeCompletions }
            case "type": return { from:frm, options: dnd5eCreatureTypeCompletions }
            case "walk": case "climb": case "swim": case "fly": return { from:frm, options: dnd5eCreatureSpeedCompletions }
            case "abilities": return { from:frm, options: dnd5eCreatureAbilitiesCompletions }
            case "proficiency": return { from:frm, options: dnd5eCreatureProficienciesCompletions }
            case "senses": return { from:frm, options: dnd5eCreatureSensesCompletions }
            case "cr": return { from:frm, options: dnd5eCreatureChallengeRatingCompletions }
            default: return null
        }
    },
    template: `[[5ecreature]]
| name: New Creature
| size: medium
| type: humanoid
| ac: 10
| hitpoints: 1d10
| walk: 30 ft.
| abilities: 10 10 10 10 10 10
| proficiency: 2
| cr: 1
| passive: Name:Description
| reaction: Name:Description
| action: Name:Description
| attack: Name:Melee Attack:STR+PROF:range 5 feet, one target:1d8+PROF:Description
| attack: 2:Name:Melee Attack:STR+PROF:range 5 feet, one target:1d8+PROF:Description
`
})