import React from 'react';
import { HeadingLevels } from '../../data/types';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getHeadingID } from '../../data/util';
import { Link } from './Link';

interface HOptions {
    level: HeadingLevels;
    href?: string;
    children: string;
}

const H = ({ level, children, href }: HOptions) => {
    switch (level) {
        case 1: return <h1 id={getHeadingID(children)}>{children} {href && <Link href={href}><FontAwesomeIcon icon={faLink} /></Link>}</h1>;
        case 2: return <h2 id={getHeadingID(children)}>{children} {href && <Link href={href}><FontAwesomeIcon icon={faLink} /></Link>}</h2>;
        case 3: return <h3 id={getHeadingID(children)}>{children} {href && <Link href={href}><FontAwesomeIcon icon={faLink} /></Link>}</h3>;
        case 4: return <h4 id={getHeadingID(children)}>{children} {href && <Link href={href}><FontAwesomeIcon icon={faLink} /></Link>}</h4>;
        case 5: return <h5 id={getHeadingID(children)}>{children} {href && <Link href={href}><FontAwesomeIcon icon={faLink} /></Link>}</h5>;
        case 6: return <h6 id={getHeadingID(children)}>{children} {href && <Link href={href}><FontAwesomeIcon icon={faLink} /></Link>}</h6>;
    }
}

export default H;