import React, { useMemo } from 'react'
import { Row, Col } from 'react-bootstrap';
import MarkdownView from './MarkdownView';
import MediaView from './MediaView';
import MetadataBadges from './MetadataBadges';
import ViewTableOfContents from './ViewTableOfContents';
import './BodyView.css'
import useClipper from '../../hooks/useClipper';
import BlockActions from './BlockActions';
import { usePageMarkdown } from '../../state/usePageMarkdown';
import { useShallow } from 'zustand/react/shallow';
import { SectionLinks } from './SectionLinks';
import { parseMarkdown } from '../../markdown/parser';

const BodyView = () => {
    const { path, markdown, metadata } = usePageMarkdown(useShallow(state => ({ path: state.path, markdown: state.markdown, metadata: state.metadata })))
    const ast = useMemo(() => parseMarkdown(markdown), [markdown])
    const clipper = useClipper(path)

    const title = typeof(metadata.title) === "string" ? metadata.title : ''
    document.title = title

    return <Row>
        <Col id="body" xs={{ span: 12, order: 2}} sm={{ span: 8, order: 1}} md={{ span: 8, order: 1}}>
            <h1 id="title">
                {title}
                <SectionLinks />
            </h1>
            <MetadataBadges table="page" metadata={metadata}  />
            <MarkdownView ast={ast} onMouseOver={clipper.onMouseOver}onMouseUp={clipper.onMouseUp}>{markdown}</MarkdownView>
            <MediaView />
            <BlockActions {...clipper} />
        </Col>
        <Col id="toc" xs={{span:12, order:1}} sm={{span:4, order:2}} md={{span:4, order:2}}>
            <ViewTableOfContents />
        </Col>
    </Row>;
}

export default BodyView