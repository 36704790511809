
// [[neutralcreature]]
// | armor: hide/scale/chain/plate
// | hitdice: 9
// | hitpoints: 45
// | move: standard|double
// | quantity: Solo, Cabal 1 & 1d10 Aristocrats
// | passive:Name: description
// | attack:Name: description
// | action:Name: description

import React from 'react'
import { trim } from "../util";
import { CustomMarkdownBlock, parseCurrentTagLine } from "./_utils";
import { Completion } from '@codemirror/autocomplete';
import { Nominal } from '../types';
import NeutralCreatureStatBlock from './components/NeutralCreatureStatBlock';

const dnd5eCreatureKeyCompletions = [
    "armor","hitdice","hitpoints","move","quantity","morale",
    "passive","action","attack"
].map((key): Completion => ({ label: key, type: "macro", apply: `${key}: `, detail: "neutral creature metadata" }))

const neutralCreatureArmor = ["unarmored","leather","hide","scale","chain","plate"] as const
export type NeutralCreatureArmor = typeof neutralCreatureArmor[number]
const neutralCreatureArmorCompletions = neutralCreatureArmor.map(armor => ({ label: armor, type: "macro", detail: "neutral creature armor" }))
const parseNeutralCreatureArmor = (input: string | undefined): NeutralCreatureArmor => {
    if (input === undefined) return "unarmored"

    const armor =  trim(input.toLowerCase()) as NeutralCreatureArmor
    if (neutralCreatureArmor.indexOf(armor) === -1) throw Error(`Unknown neutral creature armor: ${armor}`)

    return armor
}

export type NeutralCreatureHitDice = Nominal<number, "neutral hit dice">
export const parseNeutralCreatureHitDice = (input: string | undefined): NeutralCreatureHitDice => {
    if (input === undefined) return 1 as NeutralCreatureHitDice

    const hitdice =  trim(input.toLowerCase())
    if (hitdice.search(/^\d+$/) === -1) throw Error(`Expected hit dice as a number: '${hitdice}'`)

    return parseInt(hitdice) as NeutralCreatureHitDice
}

const neutralCreatureMove = ["standard","double","half"] as const
const neutralCreatureMoveCompletions = neutralCreatureMove.map(move => ({ label: move, type: "macro", detail: "neutral creature move" }))
export type NeutralCreatureMove = typeof neutralCreatureMove[number]
const parseNeutralCreatureMove = (input: string | undefined): NeutralCreatureMove => {
    if (input === undefined) return "standard"

    const move =  trim(input.toLowerCase()) as NeutralCreatureMove
    if (neutralCreatureMove.indexOf(move) === -1) throw Error(`Unknown neutral creature move: ${move}`)

    return move
}

type NeutralCreatureQuantity = Nominal<string, "neutral creature qantity">
const parseNeutralCreatureQuantity = (input: string | undefined): NeutralCreatureQuantity => {
    if (input === undefined) return "Solo" as NeutralCreatureQuantity

    return trim(input.toLowerCase()) as NeutralCreatureQuantity
}

export interface NeutralPower {
    type: "passive" | "attack" | "action"
    name: string;
    description: string;
}
const parseNeutralPower = (type: string, input: string): NeutralPower => {
    if (type !== "passive" && type !== "attack" && type !== "action") throw Error(`Unknown power type: ${type}`)

    const inputParts = trim(input).split(":")

    return {
        type,
        name: trim(inputParts[0]),
        description: trim(inputParts.slice(1).join(":")),
    }
}

export type NeutralCreatureMorale = Nominal<string, "neutral creature morale">
const parseNeutralCreatureMorale = (input: string | undefined): NeutralCreatureMorale => {
    if (input === undefined) return "" as NeutralCreatureMorale

    return trim(input.toLowerCase()) as NeutralCreatureMorale
}

export interface NeutralCreature {
    name: string
    armor: NeutralCreatureArmor
    hitdice: NeutralCreatureHitDice
    move: NeutralCreatureMove
    quantity: NeutralCreatureQuantity
    powers: NeutralPower[]
    morale: NeutralCreatureMorale
    raw: string
}

export const NeutralCreatureBlock = new CustomMarkdownBlock<NeutralCreature>({
    name: "neutralcreature",
    parser: (block) => {
        const keyedLines = block.content.map(line => ({ key:line.replace(/^([^:]+):.*$/, "$1"), value:line.replace(/^[^:]+:(.*)$/, "$1") }))
        const keyMap = keyedLines.reduce((a, b) => ({ ...a, [b.key]:b.value }), {} as {[key: string]:string})

        return {
            name: keyMap.name,
            armor: parseNeutralCreatureArmor(keyMap.armor),
            hitdice: parseNeutralCreatureHitDice(keyMap.hitdice),
            move: parseNeutralCreatureMove(keyMap.move),
            quantity: parseNeutralCreatureQuantity(keyMap.quantity),
            powers: keyedLines
                .filter(({ key }) => ["passive", "action", "attack"].indexOf(key) !== -1)
                .map(keyedLine => parseNeutralPower(keyedLine.key, keyedLine.value)),
            morale: parseNeutralCreatureMorale(keyMap.morale),
            raw: "[[neutralcreature]]\n" + block.content.map(l => `| ${l}`).join("\n")
        }
    },
    renderToReact: (data) => {
        return <NeutralCreatureStatBlock creature={data} />
    },
    getCompletions(context, text, startIndex, currentIndex, completionDatabase) {
        if (currentIndex === startIndex + 1) return null

        const currentTagLine = parseCurrentTagLine(context, text[currentIndex])
        if (currentTagLine.mode === "none") return null

        if (currentTagLine.mode === "name") {
            return { from: currentTagLine.match.from + 2, options: dnd5eCreatureKeyCompletions };;
        }

        const frm = currentTagLine.match.from + currentTagLine.name.length + 4
        switch (currentTagLine.name) {
            case "defense": return { from:frm, options: neutralCreatureArmorCompletions }
            case "move": return { from:frm, options: neutralCreatureMoveCompletions }
            default: return null
        }
    },
    template: `[[neutralcreature]]
| name: New Creature
| armor: unarmored
| hitdice: 1
| hitpoints: 5
| move: standard
| quantity: Solo
| passive: Name:Description
| action: Name:Description
| attack: Name:Description
`
})

const HDToSkillBonus = [
    0, // 0
    2, // 1
    2, // 2
    2, // 3
    4, // 4
    5, // 5
    5, // 6
    5, // 7
    6, // 8
    6, // 9
    6, // 10
    6, // 11
    6, // 12
    6, // 13
    6, // 14
    6, // 15
    6, // 16
    6, // 17
    6, // 18
    6, // 19
    6, // 20
 ] as const

const HDToProfBonus = [
    0, // 0
    2, // 1
    2, // 2
    2, // 3
    2, // 4
    3, // 5
    3, // 6
    3, // 7
    3, // 8
    4, // 9
    4, // 10
    4, // 11
    4, // 12
    5, // 13
    5, // 14
    5, // 15
    5, // 16
    6, // 17
    6, // 18
    6, // 19
    6, // 20
] as const

export const hitDiceToSkillBonus = (hd: NeutralCreatureHitDice, proficiency: "full" | "half" | "none"): number => {
    const skillBonus = HDToSkillBonus[hd as number]
    if (skillBonus === undefined) throw new Error(`Invalid HD: ${hd}`)

    var profBonus = HDToProfBonus[hd as number] as number
    if (profBonus === undefined) throw new Error(`Invalid HD: ${hd}`)
    if (proficiency === "half") profBonus = Math.ceil(profBonus / 2)
    if (proficiency === "none") profBonus = 0

    return skillBonus + profBonus
}