import React from 'react'
import { DieExpression, calculateDieExpressionAverage, calculateDieExpressionConcrete } from '../../../dice'
import { AbilityScoreSet, getDnd5eAbilityScoreModifier } from '../../../dnd'

interface DndDieExpressionOptions {
    expression: DieExpression
    abilities?: AbilityScoreSet
    proficiency?: number
    showAverage: boolean
}

const DndDieExpression = ({ expression, abilities, proficiency, showAverage }: DndDieExpressionOptions) => {
    const variables: {[key: string]: number} = {}
    if (proficiency) {
        variables.PROF = proficiency
    }
    if (abilities) {
        variables.STR = getDnd5eAbilityScoreModifier(abilities.STR.score)
        variables.DEX = getDnd5eAbilityScoreModifier(abilities.DEX.score)
        variables.CON = getDnd5eAbilityScoreModifier(abilities.CON.score)
        variables.INT = getDnd5eAbilityScoreModifier(abilities.INT.score)
        variables.WIS = getDnd5eAbilityScoreModifier(abilities.WIS.score)
        variables.CHA = getDnd5eAbilityScoreModifier(abilities.CHA.score)
        variables.MAX = Math.max(variables.STR, variables.DEX, variables.CON, variables.INT, variables.WIS, variables.CHA)
    }

    const average = calculateDieExpressionAverage(expression, variables)
    const concreteExpression = calculateDieExpressionConcrete(expression, variables)

    if (showAverage) {
        return <span>{average} ({concreteExpression})</span>
    } else {
        return <span>{concreteExpression}</span>
    }
}

export default DndDieExpression