import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import MarkdownSandbox from './MarkdownSandbox';

if (window.location.pathname.slice(-1) !== "/") {
  window.location.replace(window.location.pathname + "/");
}
else {
  const Views = {
    "_/markdown/": MarkdownSandbox,
    default: App
  } as { [key:string]: any }
  const PageView = Views[window.location.pathname.slice(1)] || Views.default

  Amplify.configure(config);

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <PageView />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
