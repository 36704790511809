import { BlockNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllBlockFunction, ParseAllBlockFunction, ParseAllInlineFunction, BlockParserFunctions } from "../parser"

const matchTagLineRE = /^\|.*\|\s*$/
const matchTableLineRE = /^\|.*:/
const isNotMacroContentInjected = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number): boolean => range.markdown.search(matchTagLineRE) !== -1 || range.markdown.search(matchTableLineRE) !== -1

const processMacroContentRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => ({
    type: "block",
    name: "macrocontent",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {
        raw: ranges.removePrefix(2).flatten().markdown
    },
    children: parseBlock(ranges.removePrefix(2))
})

export const MacroContentParserFunctions: BlockParserFunctions = {
    name: "macrocontent",
    peek: (range, index, obj, ancestors) => {
        if (ancestors[ancestors.length - 1] !== "macro") return false
        
        return range.markdown.search(matchTagLineRE) === -1 && range.markdown.search(matchTableLineRE) === -1
    },
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotMacroContentInjected(peekAll),
        processMacroContentRangesInjected(parseBlocks, parseInline),
        "Expected start of macro content"
    )
}
