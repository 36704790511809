import { HeadingLevel } from "@aws-amplify/ui-react";
import { CustomMarkdownBlock } from "./_utils";
import React from "react";
import FunctionalRandomTableIndex from "./components/FunctionalRandomTableIndex";
import { RandomTableReference } from "../../state/randomtables/types";

interface RandomTableIndexData {
    title: string;
    level: HeadingLevel;
    references: RandomTableReference[];
}
export const RandomTableIndexBlock = new CustomMarkdownBlock<RandomTableIndexData>({
    name: "randomtableindex",
    parser: (block) => {
        if (block.content.length === 0) throw new Error("expecting at least one row")

        let title = block.content.shift();
        if (title === undefined) throw new Error("expecting a title")

        const references = block.content.map((line, index): RandomTableReference => {
            const parts = line.split("|");

            if (parts.length === 3) {
                return {
                    title: null,
                    randomTable: parts[0].replace(/(^\s*|\s*$)/g, ''),
                    path: parts[1].replace(/(^\s*|\s*$)/g, ''),
                    setTitles: []
                }
            }
            if (parts.length === 4) {
                return {
                    title: parts[0].replace(/(^\s*|\s*$)/g, ''),
                    randomTable: parts[1].replace(/(^\s*|\s*$)/g, ''),
                    path: parts[2].replace(/(^\s*|\s*$)/g, ''),
                    setTitles: []
                }
            }

            throw Error(`Line ${index} does not have three parts`)
        });

        return {
            title: title.replace(/^(#+ )/, ''),
            level: title.search(/^#+ /) === -1 ? 6 : title.replace(/^(#+) .*$/, "$1").length as HeadingLevel,
            references
        }
    },
    renderToReact: (data) => {
        return <FunctionalRandomTableIndex
            title={data.title}
            level={data.level}
            references={data.references}
        />
    },
    getCompletions: (context, text, startIndex, currentIndex, completionDatabase) => {
        const match = context.matchBefore(/^\| .*/)
        if (match === null) return null

        return { from: match.from + 1, options: completionDatabase.randomTables }
    },
    template: `[[randomtableindex]]
| Index title
| `
})