import React from 'react'
import { HeadingLevel } from "@aws-amplify/ui-react";
import { CustomMarkdownBlock } from "./_utils";
import QueryTable from "./components/QueryTable";
import { trim } from '../util';

interface QueryData {
    level: HeadingLevel;
    title: string;
    query: string;
}
export const QueryBlock = new CustomMarkdownBlock<QueryData>({
    name: "query",
    parser: (block) => {
        const first = block.content[0] && block.content[0].search(/^#/) === 0 ? block.content.shift() : undefined;

        return {
            level: first ? first.replace(/^(#+).*$/, '$1').length as HeadingLevel : 6,
            title: first ? trim(first.replace(/^#+ (.*)$/, '$1')) : '',
            query: block.content.join("\n")
        }
    },
    renderToReact: (data) => { 
        return <QueryTable
            level={data.level}
            title={data.title.replace(/(^\s+|\s+$)/g, '')}
            query={data.query}
        />
    },
    template: `[[query]]
| SELECT title, tags
| FROM pages
| WHERE tags = 'yourtag'
`
})