import { BlockNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllBlockFunction, ParseAllBlockFunction, ParseAllInlineFunction, BlockParserFunctions } from "../parser"

const isNotTableCellInjected = (peekAll: PeekAllBlockFunction) => (char: string, index: number, obj: MarkdownRange): boolean | number => {
    if (index === 0) return false
    if (!obj.matches(index, "|")) return false

    return true
}

const CellStartRE = /^\|\s+/
const processTableCellRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => ({
    type: "block",
    name: "tablecell",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    children: parseInline(ranges.removePrefix(CellStartRE))
})

export const TableCellParserFunctions: BlockParserFunctions = {
    name: "tablecell",
    peek: (range, index, obj, ancestors) => {
        if (ancestors[ancestors.length - 1] !== "tablerow") return false
        
        return range.markdown[0] === "|"
    },
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eatCharacters(
        isNotTableCellInjected(peekAll),
        processTableCellRangesInjected(parseBlocks, parseInline)
    )
}
