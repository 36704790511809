import { DirPath, FilePath } from "../../data/types";
import { Storage } from 'aws-amplify';
import { getObjectACL } from "../../data/s3";
import { PageMarkdownStore } from "../usePageMarkdown";
import { pagemarkdownDefaultTemplate } from "./pagemarkdownDefaultTemplates";

export const publicRandomTablePath = "randomtables_public.json" as FilePath;
export const privateRandomTablePath = "randomtables_private.json" as FilePath;

const loadStore = async (path: FilePath): Promise<string> => {
    const result = await Storage.get(path, { download: true, cacheControl: 'no-cache' });

    if (result.Body === undefined) return pagemarkdownDefaultTemplate(path)

    const text = await (result.Body as Blob).text()

    return text
}

export const pagemarkdownLoad = async (initializeOnly: boolean, path: DirPath, store: PageMarkdownStore) => {
    if (initializeOnly && store.status !== "uninitialized") return

    const file = path + "index.md" as FilePath

    try {
        const markdown = await loadStore(file)
        const acl = await getObjectACL(`public/${file}`)

        store.setStatusLoaded(path, acl, markdown, true)
    } catch (error: any) {
        if (error.toString() === 'NoSuchKey: The specified key does not exist.') return store.setStatusLoaded(path, "private", pagemarkdownDefaultTemplate(file), false)
        if (error.message === 'Access Denied') return store.setStatusAccessDenied(path)

        throw error
    }
}
