import { S3 } from 'aws-sdk';
import Auth from '@aws-amplify/auth';
import awsConfig from '../aws-exports';
import { BasicACL } from './types';

let currentClient = {
    username: "",
    client: null as null | S3
}

const getS3Client = async () => {
    const user = await Auth.currentAuthenticatedUser();
    if (currentClient.username === user.username) return currentClient.client;

    const credentials = await Auth.currentCredentials();
    currentClient = {
        username: user.username,
        client: new S3({
            apiVersion: '2013-04-01',
            credentials: Auth.essentialCredentials(credentials),
            region: awsConfig.aws_user_files_s3_bucket_region
        })
    }
    
    return currentClient.client;
}

export const getObjectACL = async (path: string): Promise<BasicACL> => {
    const s3Client = await getS3Client();
    if (!s3Client) throw Error("Could not load S3 Client");

    const aclResult = await s3Client.getObjectAcl({
        Bucket: awsConfig.aws_user_files_s3_bucket, 
        Key: path
    }).promise();

    if (!aclResult.Grants || aclResult.Grants.length === 0) return 'private';

    for (let grant of aclResult.Grants) {
        if (grant.Grantee !== undefined &&
            grant.Grantee.Type === "Group" &&
            grant.Grantee.URI === "http://acs.amazonaws.com/groups/global/AllUsers" &&
            grant.Permission === "READ") {
                return 'public-read';
            }
    }

    return 'private';
}

export const putObjectACL = async (path: string, acl: BasicACL): Promise<void> => {
    const s3Client = await getS3Client();
    if (!s3Client) throw Error("Could not load S3 Client");

    await s3Client.putObjectAcl({
        Bucket: awsConfig.aws_user_files_s3_bucket, 
        Key: path,
        ACL: acl
    }).promise();
}

export const getObjectExists = async (path: string): Promise<boolean> => {
    const s3Client = await getS3Client();
    if (!s3Client) throw Error("Could not load S3 Client");

    try {
        await s3Client.headObject({
            Bucket: awsConfig.aws_user_files_s3_bucket, 
            Key: path
        }).promise();

        return true;
    } catch (error: any) {
        switch (error.name) {
            case 'NotFound': return false;
            default: throw error;
        }
    }
}

export default getS3Client;