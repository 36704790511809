import { getHeadingID, getHeadingText } from "../../data/util"
import { parseMarkdown } from "../../markdown/parser"
import { SectionReference } from "./types"

export const pageGenerateSections = (markdown: string): SectionReference[] => {
    const ast = parseMarkdown(markdown)
    if (ast.type === "error") return []

    let sections = [{
        id: "general",
        indexes: [],
        label: "General",
        web: true,
        print: true,
    }] as SectionReference[]

    for (let i=0; i<ast.children.length; i++) {
        const thisBlock = ast.children[i]
        if (thisBlock.type !== "block" || thisBlock.name !== "section") {
            sections[0].indexes.push(i)
            continue
        }
        
        const heading = getHeadingText(thisBlock.children[0].children)
        sections.push({
            id: getHeadingID(heading),
            indexes: [i],
            label: heading,
            web: false,
            print: true,
        })
    }

    return sections
}
