import { FilePath } from "../../data/types";
import { Storage } from 'aws-amplify';
import { MetadataStore, MetadataStoreData } from "../useMetadataStore";

export const publicMetadataPath = "metadata_public.json" as FilePath
export const privateMetadataPath = "metadata_private.json" as FilePath

export const EmptyStoreData = {
    pages: {},
    items: {},
    metadata: {},
    itemmetadata: {}
} as MetadataStoreData

const loadStore = async (path: FilePath): Promise<MetadataStoreData> => {
    let result;
    
    try {
        result = await Storage.get(path, { download: true, cacheControl: 'no-cache' });
    } catch (error: any) {
        if (error.message === 'NoSuchKey') return EmptyStoreData

        throw error
    }
    if (result.Body === undefined) return EmptyStoreData

    const text = await (result.Body as Blob).text();
    const data = JSON.parse(text);

    data.items = data.items || {};
    data.itemmetadata = data.itemmetadata || {};

    return data
}

export const metadataStoreLoad = async (initializeOnly: boolean, mode: MetadataStore["mode"], store: MetadataStore) => {
    if (initializeOnly && store.status !== "uninitialized") return

    try {
        const [data, publicData] = await Promise.all([
            loadStore(mode === "public" ? publicMetadataPath : privateMetadataPath),
            mode === "private" ? loadStore(publicMetadataPath) : null
        ])

        store.setStatusLoaded(mode, data, publicData)
    } catch (error: any) {
        store.setStatusError(error)
    }
}