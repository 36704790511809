import React from 'react'
import { Button } from 'react-bootstrap'
import { usePageState } from '../../state/usePageState'

const activeSection: React.CSSProperties = {
    fontWeight: "bold"
}

export const SectionLinks = () => {
    const sections = usePageState(state => state.sections)
    const setWebSections = usePageState(state => state.setWebSections)
    if (sections.length === 0) return null

    return <>
        {sections.map((thisSection, index) => <Button key={index} variant="link" onClick={() => setWebSections([thisSection.id])} style={thisSection.web ? activeSection : undefined}>{thisSection.label}</Button>)}
    </>
}