import { parse } from 'yaml'
import { DirPath } from '../../data/types';
import { getTitleFromFilename } from '../../data/util';

export const pagemarkdownGenerateFrontmatter = (path: DirPath, markdown: string) => {
    const frontmatters = markdown.match(/^---\n(.*?)\n---\n/s);

    if (!frontmatters || !frontmatters.length) return {};

    const result = parse(frontmatters[1]);

    // default title
    if (result.title === undefined) {
        const lastSegment = path.split("/").pop() || ''
        result.title = getTitleFromFilename(lastSegment)
    }

    return result;
}