import React from 'react'
import { NodeWithAncestors } from '../../../markdown/ast'
import { MarkdownContextRenderer } from '../../../markdown/markdown'
import { getHeadingID, getHeadingText } from '../../../data/util'

export const HeadingOverride = ({ indexes, children, meta: { level }, ancestors, className, style }: NodeWithAncestors<"block">) => {
    switch (level) {
        case "1": return <h1 id={getHeadingID(getHeadingText(children))} className={className} style={style}>{children.map((node, i) => <MarkdownContextRenderer key={i} node={node} indexes={[...indexes, i]} ancestors={ancestors} />)}</h1>
        case "2": return <h2 id={getHeadingID(getHeadingText(children))} className={className} style={style}>{children.map((node, i) => <MarkdownContextRenderer key={i} node={node} indexes={[...indexes, i]} ancestors={ancestors} />)}</h2>
        case "3": return <h3 id={getHeadingID(getHeadingText(children))} className={className} style={style}>{children.map((node, i) => <MarkdownContextRenderer key={i} node={node} indexes={[...indexes, i]} ancestors={ancestors} />)}</h3>
        case "4": return <h4 id={getHeadingID(getHeadingText(children))} className={className} style={style}>{children.map((node, i) => <MarkdownContextRenderer key={i} node={node} indexes={[...indexes, i]} ancestors={ancestors} />)}</h4>
        case "5": return <h5 id={getHeadingID(getHeadingText(children))} className={className} style={style}>{children.map((node, i) => <MarkdownContextRenderer key={i} node={node} indexes={[...indexes, i]} ancestors={ancestors} />)}</h5>
        case "6": return <h6 id={getHeadingID(getHeadingText(children))} className={className} style={style}>{children.map((node, i) => <MarkdownContextRenderer key={i} node={node} indexes={[...indexes, i]} ancestors={ancestors} />)}</h6>
        default: throw new Error(`Unknown heading level: ${level}`)
    }
}