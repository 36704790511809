import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useUser } from '../../state/useUser';

interface SignInModalOptions {
    show: boolean,
    close: () => void
}

const SignInModal = ({ show, close }: SignInModalOptions) => {
    const signIn = useUser(state => state.signIn)
    const [validation, setValidation] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    return <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
            <Modal.Title>Sign in</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                {validation !== "" && <Alert variant="danger">{validation}</Alert>}
                <Form.Group>
                    <Form.Label>Username</Form.Label>
                    <Form.Control autoFocus value={username} onChange={({ target }) => setUsername(target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" value={password} onChange={({ target }) => setPassword(target.value)} />
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={close}>
                Close
            </Button>
            <Button variant="primary" onClick={() => signIn(username, password).then(close).catch(error => setValidation(error.message))}>
                Sign in
            </Button>
        </Modal.Footer>
    </Modal>
}

export default SignInModal;