import React, { useContext, useMemo } from 'react'
import { AST, GetClassNameFn, GetStylesFn, Node, NodeRenderers, RendererContext, getRenderer } from './ast'
import { parseMarkdown } from './parser'
import { MarkdownErrorView } from './tree/MarkdownErrorView'
import { PlainTextView } from './render/plaintext'
import { LinkView } from './render/link'
import { ImageView } from './render/image'
import { ParagraphView } from './render/paragraph'
import { HeadingView } from './render/heading'
import { TableCellView } from './render/tablecell'
import { TableRowView } from './render/tablerow'
import { TableHeadView } from './render/tablehead'
import { TableBodyView } from './render/tablebody'
import { TableView } from './render/table'
import { BlockquoteView } from './render/blockquote'
import { OrderedListView } from './render/orderedlist'
import { ListItemView } from './render/listitem'
import { UnorderedListView } from './render/unorderedlist'
import { CodeView } from './render/code'
import { EmphasisView } from './render/emphasis'
import { InlineCodeView } from './render/inlinecode'
import { StrikethroughView } from './render/strikethrough'
import { BreakView } from './render/break'
import { BRView } from './render/br'
import { NoRendererBlockView, NoRendererInlineView } from './render/norenderer'
import { ErrorBlockView, ErrorInlineView } from './render/error'
import { BlockMarkdownErrorBoundary, InlineMarkdownErrorBoundary } from './MarkdownErrorBoundary'
import { MacroView } from './render/macrop'
import { FrontmatterView } from './render/frontmatter'
import { createMarkdownError } from './error'
import { SectionView } from './render/section'

const rendererDefaults: RendererContext = {
    getStyles: () => undefined,
    getClassName: () => undefined,
    renderers: {
        inline: {
            br: BRView,
            code: InlineCodeView,
            emphasis: EmphasisView,
            error: ErrorInlineView,
            image: ImageView,
            link: LinkView,
            plaintext: PlainTextView,
            strikethrough: StrikethroughView,
            norenderer: NoRendererInlineView
        },
        block: {
            blockquote: BlockquoteView,
            break: BreakView,
            code: CodeView,
            error: ErrorBlockView,
            frontmatter: FrontmatterView,
            heading: HeadingView,
            listitem: ListItemView,
            macro: MacroView,
            orderedlist: OrderedListView,
            paragraph: ParagraphView,
            section: SectionView,
            table: TableView,
            tablebody: TableBodyView,
            tablecell: TableCellView,
            tablehead: TableHeadView,
            tablerow: TableRowView,
            unorderedlist: UnorderedListView,
            norenderer: NoRendererBlockView
        }
    }
}
export const MarkdownViewContext = React.createContext(rendererDefaults)

interface MarkdownRendererConsumerOptions<T extends Node> {
    node: T
    indexes: number[]
    ancestors: string[]
}
export const MarkdownContextRenderer = <T extends Node>({ node, indexes, ancestors }: MarkdownRendererConsumerOptions<T>) => {
    const rendererContext = useContext(MarkdownViewContext)
    const Renderer = getRenderer(rendererContext.renderers, node.type, node.name)
    const newAncestors = [...ancestors, `${node.type}-${node.name}`]
    const styles = rendererContext.getStyles(node, indexes, newAncestors)
    const className = rendererContext.getClassName(node, indexes, newAncestors)
    
    switch (node.type) {
        case "inline":
            return <InlineMarkdownErrorBoundary>
                <Renderer {...node} indexes={indexes} ancestors={newAncestors} className={className} style={styles} />
            </InlineMarkdownErrorBoundary>
        case "block":
            return <BlockMarkdownErrorBoundary>
                <Renderer {...node} indexes={indexes} ancestors={newAncestors} className={className} style={styles} />
            </BlockMarkdownErrorBoundary>
    }
    
}

interface MarkdownOptions {
    ast?: AST
    markdown?: string
    overrideRenderers?: NodeRenderers
    getStyles?: GetStylesFn
    getClassName?: GetClassNameFn
    inlineMode?: boolean
}
const defaultOverrideRenderers = { inline:{}, block:{} } as NodeRenderers
export const Markdown = ({ ast, markdown, overrideRenderers=defaultOverrideRenderers, getStyles, getClassName, inlineMode=false }: MarkdownOptions) => {
    const renderableAST = useMemo(() => {
        if (ast) return ast
        if (markdown) return parseMarkdown(markdown)

        return createMarkdownError("Markdown component expects an AST or markdown property", 0, "") 
    }, [ast, markdown])

    const rendererContext = useMemo(() => ({
        getStyles: getStyles || rendererDefaults.getStyles,
        getClassName: getClassName || rendererDefaults.getClassName,
        renderers: {
            inline: {
                ...rendererDefaults.renderers.inline,
                ...overrideRenderers.inline
            },
            block: {
                ...rendererDefaults.renderers.block,
                ...overrideRenderers.block
            }
        }
    } as RendererContext), [overrideRenderers, getClassName, getStyles])

    switch (renderableAST.type) {
        case "markdown":
            if (inlineMode && renderableAST.children.length === 1 && renderableAST.children[0].name === "paragraph") {
                return <MarkdownViewContext.Provider value={rendererContext}>
                    {renderableAST.children[0].children.map((node, i) => <MarkdownContextRenderer key={i} node={node} indexes={[i]} ancestors={[]} />)}
                </MarkdownViewContext.Provider>
            } else {
                return <MarkdownViewContext.Provider value={rendererContext}>
                    {renderableAST.children.map((node, i) => <MarkdownContextRenderer key={i} node={node} indexes={[i]} ancestors={[]} />)}
                </MarkdownViewContext.Provider>
            }
        case "error":
            return <MarkdownErrorView {...renderableAST} />
    }
}