import { BaseNode } from "./ast"
import { MarkdownParserError } from "./error"
import { InlineParserMatcherFunction, BlockParserMatcherFunction } from "./parser"

type MarkdownRangeSplitFunction = (char: string, index: number, obj: MarkdownRange) => boolean
type ProcessMarkdownRangeFunction<T> = (range: MarkdownRange) => T
export type RangeResult<T> = [T, MarkdownRange] | null

export class MarkdownRange {
    markdown: string
    start: number
    end: number

    constructor(markdown: string, start: number, end: number) {
        this.markdown = markdown
        this.start = start
        this.end = end
    }

    slice(start: number | undefined=this.start, end: number | undefined=this.end): MarkdownRange {
        return new MarkdownRange(
            this.markdown.slice(start - this.start, end - this.start),
            start,
            end
        )
    }

    split(test: MarkdownRangeSplitFunction, firstOnly: boolean=false): MarkdownRange[] {
        const result = [] as MarkdownRange[]
        var lastEnd = 0
    
        for (var i=0; i<this.markdown.length; i++) {
            if (test(this.markdown[i], i, this)) {
                result.push(this.slice(this.start + lastEnd, this.start + i))
                lastEnd = i

                if (firstOnly) {
                    result.push(this.slice(this.start + lastEnd, this.end))
                    return result
                }
            }
        }
        if (lastEnd !== i) {
            result.push(this.slice(this.start + lastEnd, this.end))
        }
        
        return result
    }

    eat<T>(test: InlineParserMatcherFunction, process: ProcessMarkdownRangeFunction<T>, noMatch: boolean | string): RangeResult<T> {
        var breakIndex = this.findIndex(test)
        if (breakIndex === -1) {
            if (typeof(noMatch) === "string") throw new MarkdownParserError(noMatch, this.start)
            if (noMatch === false) return null

            breakIndex = this.markdown.length
        }

        return [process(this.slice(this.start, this.start + breakIndex)), this.slice(this.start + breakIndex)]
    }

    removePrefix(prefix: number | RegExp): MarkdownRange {
        if (typeof(prefix) === "number") {
            return this.slice(this.start + prefix, this.end)
        } else {
            return this.slice(this.start + this.markdown.length - this.markdown.replace(prefix, "").length, this.end)
        }
    }

    removeSuffix(suffix: number | RegExp): MarkdownRange {
        if (typeof(suffix) === "number") {
            return this.slice(this.start, this.end - suffix)
        } else {
            return this.slice(this.start, this.start + this.markdown.replace(suffix, "").length)
        }
    }

    findIndex(test: InlineParserMatcherFunction): number {
        for (let i=0; i<this.markdown.length; i++) {
            const splitIndex = test(this.markdown[i], i, this)

            switch (splitIndex) {
                case false: continue
                case true: return i
                default: return splitIndex
            }
        }

        return -1
    }

    isEscaped(index: number) {
        // walk back
        // if there is an odd number of \'s before the character, then it is escaped
        for (var i=index-1, count=0; this.markdown[i] !== "\\" && i>=0; i--) {}
        if (count % 2 === 1) return true
    
        return false
    }

    matches(index: number, test: string | RegExp, length: number=1): boolean {
        if (typeof(test) === "string") {
            if (this.markdown.slice(index - test.length + length, index + length) !== test) return false

            return !this.isEscaped(index - test.length + 1)
        }

        const match = this.markdown.slice(0, index + length).match(test)
        if (match === null || match.length === 0) return false
        return !this.isEscaped(index - match[0].length + 1)
    }

    toArray(): NonEmptyMarkdownRangeArray {
        return new MarkdownRangeArray([this]) as NonEmptyMarkdownRangeArray
    }
}

type ProcessMarkdownRangeArrayFunction<T> = (ranges: MarkdownRangeArray & [MarkdownRange, ...MarkdownRange[]]) => T
export type RangesResult<T> = [T, MarkdownRangeArray] | null

export type NonEmptyMarkdownRangeArray = MarkdownRangeArray & [MarkdownRange, ...MarkdownRange[]]
export class MarkdownRangeArray extends Array<MarkdownRange> {
    constructor(input: MarkdownRange[]) {
        super(input.length);

        for (let i=0; i<input.length; i++) {
            this[i] = input[i]
        }
    }

    isNonEmpty(this: MarkdownRangeArray): this is NonEmptyMarkdownRangeArray {
        return this.length > 0
    }

    getStart(this: NonEmptyMarkdownRangeArray): number {
        return this[0].start
    }

    getEnd(this: NonEmptyMarkdownRangeArray): number {
        return this[this.length-1].end
    }

    flatten(this: NonEmptyMarkdownRangeArray): MarkdownRange {
        return new MarkdownRange(
            this.map(range => range.markdown).join(""),
            this.getStart(),
            this.getEnd()
        )
    }

    slice(start?: number | undefined, end?: number | undefined): MarkdownRangeArray {
        return new MarkdownRangeArray(super.slice(start, end))
    }

    findIndex(test: BlockParserMatcherFunction): number {
        for (let i=0; i<this.length; i++) {
            const splitIndex = test(this[i], i, this)

            switch (splitIndex) {
                case false: continue
                case true: return i
                default: return splitIndex
            }
        }

        return -1
    }

    split(this: NonEmptyMarkdownRangeArray, test: BlockParserMatcherFunction): [MarkdownRangeArray, MarkdownRangeArray]
    split(this: NonEmptyMarkdownRangeArray, test: BlockParserMatcherFunction, message: string): [NonEmptyMarkdownRangeArray, MarkdownRangeArray]
    split(this: NonEmptyMarkdownRangeArray, test: BlockParserMatcherFunction, message?: string): [MarkdownRangeArray | NonEmptyMarkdownRangeArray, MarkdownRangeArray] {
        const splitPoint = this.findIndex(test)
        if (splitPoint === -1) return [this, new MarkdownRangeArray([])]

        const firstArray = this.slice(0, splitPoint)
        if (typeof(message) === "string" && !firstArray.isNonEmpty()) {
            throw new MarkdownParserError(message, this.getStart())
        }

        return [firstArray, this.slice(splitPoint)]
    }

    splitOnCharacter(this: NonEmptyMarkdownRangeArray, test: InlineParserMatcherFunction): [MarkdownRangeArray, MarkdownRangeArray]
    splitOnCharacter(this: NonEmptyMarkdownRangeArray, test: InlineParserMatcherFunction, message: string): [NonEmptyMarkdownRangeArray, MarkdownRangeArray]
    splitOnCharacter(this: NonEmptyMarkdownRangeArray, test: InlineParserMatcherFunction, message?: string): [MarkdownRangeArray | NonEmptyMarkdownRangeArray, MarkdownRangeArray] {
            for (let i=0; i<this.length; i++) {
            const breakIndex = this[i].findIndex(test)

            if (breakIndex !== -1) {
                // block is made up of prior ranges...
                const blockRanges = this.slice(0, i)
                // and if the match wasn't the start of the current range, add the start of this too
                if (breakIndex !== 0) {
                    blockRanges.push(this[i].slice(this[i].start, this[i].start + breakIndex))
                }
                if (!blockRanges.isNonEmpty()) {
                    if (message === undefined) {
                        return [new MarkdownRangeArray([]), this]
                    }

                    if (typeof(message) === "string") {
                        throw new MarkdownParserError(message, this.getStart())
                    } else {
                    }
                }

                // remainder is made up of the ranges after this one...
                const remainingRanges = this.slice(i + 1)
                // and if the match wasn't at the end of the current range, add the end of this too
                if (breakIndex !== this[i].markdown.length) {
                    remainingRanges.unshift(this[i].slice(this[i].start + breakIndex, this[i].end))
                }

                return [blockRanges as NonEmptyMarkdownRangeArray, remainingRanges]
            }
        }

        return [this, new MarkdownRangeArray([])]
    }

    eat<T extends BaseNode>(this: NonEmptyMarkdownRangeArray, test: BlockParserMatcherFunction, process: ProcessMarkdownRangeArrayFunction<T>, message: string): RangesResult<T> {
        const [blockRanges, remainder] = this.split(test, message)

        return [process(blockRanges), remainder]
    }

    eatCharacters<T>(this: NonEmptyMarkdownRangeArray, test: InlineParserMatcherFunction, process: ProcessMarkdownRangeArrayFunction<T>, message?: string): RangesResult<T> {
        const [blockRanges, remainder] = message === undefined
            ? this.splitOnCharacter(test)
            : this.splitOnCharacter(test, message)
        
        if (blockRanges.isNonEmpty()) return [process(blockRanges), remainder]

        return null
    }

    removePrefix(this: NonEmptyMarkdownRangeArray, prefix: number | RegExp): NonEmptyMarkdownRangeArray
    removePrefix(this: MarkdownRangeArray, prefix: number | RegExp): MarkdownRangeArray {
        return new MarkdownRangeArray(this.map(range => range.removePrefix(prefix)))
    }

    removeSuffix(this: NonEmptyMarkdownRangeArray, suffix: number | RegExp): NonEmptyMarkdownRangeArray
    removeSuffix(this: MarkdownRangeArray, suffix: number | RegExp): MarkdownRangeArray {
        return new MarkdownRangeArray(this.map(range => range.removeSuffix(suffix)))
    }

    error(this: NonEmptyMarkdownRangeArray, message: string, start: number=this.getStart()): MarkdownParserError {
        return new MarkdownParserError(message, start)
    }

    static fromMarkdown(markdown: string): MarkdownRangeArray {
        const ranges = [] as MarkdownRange[]

        for (var lineStart=0, i=1; i<markdown.length; i++) {
            if (markdown[i - 1] === "\n") {
                ranges.push(new MarkdownRange(markdown.slice(lineStart, i), lineStart, i))
                lineStart = i
            }
        }
        if (markdown[markdown.length - 1] === "\n") {
            ranges.push(new MarkdownRange(markdown.slice(lineStart, markdown.length), lineStart, markdown.length))
            lineStart = i
        }

        return new MarkdownRangeArray(ranges)
    }
}
