import { create } from "zustand"
import { RandomTable, RandomTableDatabase, RandomTableGeneratedData, RandomTableIndexResult, RandomTableReference, RandomTableResult } from "./randomtables/types"
import { shared } from "use-broadcast-ts"
import { randomtablesLoad } from "./randomtables/randomtablesLoad"
import { randomtablesSave } from "./randomtables/randomtablesSave"
import { randomtablesSetMode } from "./randomtables/randomtablesSetMode"
import { BasicACL, DirPath } from "../data/types"
import { randomtablesAddPage } from "./randomtables/randomtablesAddPage"
import { randomtablesRemovePage } from "./randomtables/randomtablesRemovePage"
import { Completion } from "@codemirror/autocomplete"
import { useCallback, useMemo, useState } from "react"
import { randomtableExpandReferences } from "./randomtables/randomtablesExpandReferences"
import { randomtablesReferencesRandomValues } from "./randomtables/randomtablesReferenceRandomValues"
import { randomtablesRandomValues } from "./randomtables/randomtableRandomValues"
import { randomtableGetGeneratedData } from "./randomtables/randomtableGetGeneratedData"

export interface RandomTableStore {
    status: "uninitialized" | "loading" | "loaded" | "error" | "accessdenied"
    mode: "public" | "private"
    statusDetail: string

    data: RandomTableDatabase
    publicData: RandomTableDatabase | null

    randomTableTagCompletions: Completion[]

    setStatusUninitialized: (mode: RandomTableStore["mode"]) => void
    setStatusLoading: () => void
    setStatusLoaded: (mode: RandomTableStore["mode"], data: RandomTableStore["data"], publicData: RandomTableStore["publicData"]) => void
    setStatusError: (error: any) => void
    setStatusAccessDenied: () => void

    save: () => Promise<void>
    setData: (data: RandomTableStore["data"], publicData: RandomTableStore["publicData"], save?: boolean) => Promise<void>

    load: (initializeOnly: boolean, mode: RandomTableStore["mode"]) => Promise<void>
    setMode: (mode: RandomTableStore["mode"]) => Promise<void>
    addPage: (path: DirPath, acl: BasicACL, randomtables: RandomTable[]) => Promise<void>
    removePage: (path: DirPath) => Promise<void>
}

const EmptyGeneratedData: RandomTableGeneratedData = {
    randomTableTagCompletions: [],
}

export const useRandomTables = create<RandomTableStore>()(shared(
    (set, get) => ({
        status: "uninitialized",
        mode: "public",
        statusDetail: "",

        data: {},
        publicData: null,

        randomTableTagCompletions: [],
        
        setStatusUninitialized: (mode) => set(() => ({ status: "uninitialized", statusDetail: "", mode, data: {}, publicData: null, ...EmptyGeneratedData } as Partial<RandomTableStore>)),
        setStatusLoading: () => set(() => ({ status: "loading", statusDetail: "", data: {}, publicData: null, ...EmptyGeneratedData } as Partial<RandomTableStore>)),
        setStatusLoaded: (mode, data, publicData) => set(state => ({
            status: "loaded",
            statusDetail: "",
            data,
            publicData,
            ...randomtableGetGeneratedData(data)
        } as Partial<RandomTableStore>)),
        setStatusError: (error) => set(() => ({ status: "error", statusDetail: error.toString() } as Partial<RandomTableStore>)),
        setStatusAccessDenied: () => set(() => ({ status: "accessdenied", statusDetail: "" } as Partial<RandomTableStore>)),

        save: () => randomtablesSave(get()),
        setData: (data, publicData, save=true) => {
            const newState = { data, publicData, ...randomtableGetGeneratedData(data) } as RandomTableStore
            set(() => newState)

            return save ? randomtablesSave(newState) : Promise.resolve()
        },

        load: async (initializeOnly, mode) => randomtablesLoad(initializeOnly, mode, get()),
        setMode: async (mode) => randomtablesSetMode(mode, get()),
        addPage: async (path, acl, randomtables) => randomtablesAddPage(path, acl, randomtables, get()),
        removePage: async (path) => randomtablesRemovePage(path, get()),
    }),
    { name: 'randomtables-store' }
))

export const useRandomTableCompletions = (): RandomTableGeneratedData => useRandomTables(state => ({
    randomTableTagCompletions: state.randomTableTagCompletions
}))

export const useRandomTableIndexRandomValues = (references: RandomTableReference[]): [RandomTableIndexResult[], ()=>void] => {
    const data = useRandomTables(state => state.data)
    const expandedReferences = useMemo(() => randomtableExpandReferences(data, references), [data, references])
    const [randomValues, updateRandomValues] = useState(randomtablesReferencesRandomValues(expandedReferences, data));
    const randomize = useCallback(() => {
        updateRandomValues(randomtablesReferencesRandomValues(expandedReferences, data));
    }, [data, expandedReferences])

    return [randomValues, randomize];
}

export const useRandomTableRandomValues = (data: RandomTable): [RandomTableResult[], (rollIndex?: number, column?: number) => void] => {
    const [randomValues, updateRandomValues] = useState(randomtablesRandomValues(data))

    const randomize = useCallback((rollIndex?: number, column?: number) => {
        let newRandomValues = randomtablesRandomValues(data).map(randomValue => {
            // if rollIndex isn't provided, every value is regenerated
            if (rollIndex === undefined) return randomValue

            // if column isn't provided, regenerate if the rollIndex matches
            if (column === undefined && randomValue.rollIndex === rollIndex) return randomValue

            // if column is provided, regenerate if both rollIndex and column match
            if (column !== undefined && randomValue.rollIndex === rollIndex && randomValue.column === column) return randomValue

            // if we get here, use the old value
            const oldValue = randomValues.find(v => v.rollIndex === randomValue.rollIndex && v.column === randomValue.column)
            if (oldValue === undefined) throw new Error(`Could not find old random value for ${randomValue.rollIndex}:${randomValue.column}`)
            return oldValue
        })

        updateRandomValues(newRandomValues);
    }, [data, randomValues]);

    return [randomValues, randomize];
}

export const useRandomTableReferences = (references: RandomTableReference[]): RandomTableReference[] => {
    const database = useRandomTables(state => state.data)
    const expandedReferences = useMemo(() => randomtableExpandReferences(database, references), [database, references])

    return expandedReferences
}
