import { HeadingLevels } from "./types";
import { Node } from '../markdown/ast'

export const getTitleFromFilename = (filename: string): string => {
    const justName = filename.replace(/\.\w+$/, '').replace(/^[a-z]/, matches => matches[0].toUpperCase());

    if (filename.search("-")) {
        // kebab style
        return justName.replace(/-([a-z])/g, matches => ' ' + matches[1].toUpperCase());
    }
    if (filename.search("_")) {
        // snake style
        return justName.replace(/_([a-z])/g, matches => ' ' + matches[1].toUpperCase());
    }
    if (filename.search("[a-z][A-Z]")) {
        // camel style
        return justName.replace(/([a-z])([A-Z])/g, matches => matches[1] + ' ' + matches[2]);
    }

    return justName;
}

export const splitArray = <T>(arr: T[], fn: (a: T, b: T) => boolean): T[][] => {
    if (arr.length < 2) return [arr];

    let result: T[][] = [];
    let subArr: T[] = [];

    for (let i=0; i<arr.length - 1; i++) {
        subArr.push(arr[i]);

        if (fn(arr[i], arr[i+1])) {
            result.push(subArr);
            subArr = [];
        }
    }

    if (subArr.length) result.push(subArr);

    return result;
}

export const incrementHeadingLevel = (level: HeadingLevels): HeadingLevels => Math.min(level + 1, 6) as HeadingLevels;

export const trim = (input: string) => input.replace(/(^\s+|\s+$)/g, '')

var debouncers = {} as { [name: string]: number }
export const createDebouncer = (name: string) => (fn: () => void, buffer: number) => {
    if (debouncers[name]) {
        clearTimeout(debouncers[name])
    }

    debouncers[name] = window.setTimeout(() => {
        delete debouncers[name]
        fn()
    }, buffer)
}

export const getHeadingID = (heading: string) => heading === undefined || heading.toLowerCase === undefined ? "" : heading.toLowerCase().replace(/[^-\w_]+/g, '-').replace(/(^-|-$)/g, '');

export const getHeadingText = (nodes: Node[]): string => nodes.map(node => node.type === "inline" && node.name === "plaintext"
    ? node.value.trim()
    : getHeadingText(node.children).trim()
).join("")


type GetCurrentFn<T> = () => T
type OnChangeFn<T> = (value: T) => void
type TeardownFn = () => void
export const watchArbitraryResult = <T>(getCurrent: GetCurrentFn<T>, onChange: OnChangeFn<T>, interval: number): TeardownFn => {
    var currentValue = getCurrent()
    onChange(currentValue)

    const intervalID = setInterval(() => {
        const newValue = getCurrent()
        if (newValue === currentValue) return

        currentValue = newValue
        onChange(currentValue)
    }, interval)

    return () => clearInterval(intervalID)
}