import { InlineNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllInlineFunction, ParseAllInlineFunction, InlineParserFunctions } from "../parser"

const notInlineCodeInjected = (peekAll: PeekAllInlineFunction) => (char: string, index: number, obj: MarkdownRange): boolean | number => {
    if (index === 0) return false
    if (obj.matches(index, "`")) return index + 1

    return false
}

const processInlineCodeInjected = (parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): InlineNode => ({
    type: "inline",
    name: "code",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    value: ranges.flatten().slice(ranges.getStart() + 1, ranges.getEnd() - 1).markdown,
    children: []
})

export const InlineCodeParserFunctions: InlineParserFunctions = {
    name: "inlinecode",
    peek: (char, index, range: MarkdownRange) => range.matches(index, "`"),
    parse: (ranges, parseInline, peekAll) => ranges.eatCharacters(
        notInlineCodeInjected(peekAll),
        processInlineCodeInjected(parseInline),
        "Expected inline code"
    )
}
