import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

interface MoveButtonsOptions {
    left?: boolean;
    right?: boolean;
    onMove: (direction: "left" | "right") => void;
}

const MoveButtons = ({ left=true, right=true, onMove }: MoveButtonsOptions) => {
    return <ButtonGroup className="me-2">
        <Button variant="outline-success" disabled={!left} onClick={() => onMove('left')} title="Private"><FontAwesomeIcon icon={faCaretLeft} /></Button>
        <Button variant="outline-success" disabled={!right} onClick={() => onMove('right')} title="Public"><FontAwesomeIcon icon={faCaretRight} /></Button>
    </ButtonGroup>
}

export default MoveButtons;