import React from 'react'
import { NeutralCreatureMove } from '../../neutralcreature'
import DndPropertyLine from '../dnd/DndPropertyLine'

interface NeutralMoveOptions {
    move: NeutralCreatureMove
}

const MoveLabels = {
    standard: "30 ft.",
    double: "60 ft.",
    half: "15 ft."
}

const NeutralMoveLine = ({ move }: NeutralMoveOptions) => {
    return <DndPropertyLine label="Speed">
        {MoveLabels[move]}
    </DndPropertyLine>
}

export default NeutralMoveLine