import { Storage } from 'aws-amplify';
import { putObjectACL } from '../../data/s3';
import { privateRandomTablePath, publicRandomTablePath } from './randomtablesLoad';
import { RandomTableStore } from '../useRandomTables';

export const randomtablesSave = async (store: RandomTableStore) => {
    if (store.status === "loaded") throw Error("Random tables are not initialized");

    if (store.mode === "private") {
        // save main store data as private db
        await Storage.put(privateRandomTablePath, JSON.stringify(store.data), { cacheControl: 'no-cache' });

        // save separate public data
        await Storage.put(publicRandomTablePath, JSON.stringify(store.publicData), { cacheControl: 'no-cache' });
        await putObjectACL(`public/${publicRandomTablePath}`, "public-read");
    }
    if (store.mode === "public") {
        // save main store data as public db
        await Storage.put(publicRandomTablePath, JSON.stringify(store.data), { cacheControl: 'no-cache' });
        await putObjectACL(`public/${publicRandomTablePath}`, "public-read");
    }
}