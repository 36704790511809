import { Completion } from "@codemirror/autocomplete";
import { MediaItem } from "./types";

export const pagemediaGetLinkCompletions = (mediaItems: MediaItem[]): Completion[] => mediaItems
    .filter(mediaItem => mediaItem.metadata.type === "other")
    .map(mediaItem => ({
        label: `[${mediaItem.metadata.title}]`,
        type: "macro",
        apply: `[${mediaItem.metadata.title}](${mediaItem.path})`,
        detail: "File"
    }));