import React from 'react'
import { NeutralCreatureArmor, NeutralCreatureHitDice, hitDiceToSkillBonus } from '../../neutralcreature'
import DndPropertyLine from '../dnd/DndPropertyLine'

interface NeutralACOptions {
    armor: NeutralCreatureArmor
    hitdice: NeutralCreatureHitDice
}

const ArmorACs = {
    unarmored: (dexterityBonus: number) => 10 + dexterityBonus,
    leather: (dexterityBonus: number) => 11 + dexterityBonus,
    hide: (dexterityBonus: number) => 12 + Math.min(2, dexterityBonus),
    scale: (dexterityBonus: number) => 14 + Math.min(2, dexterityBonus),
    chain: (dexterityBonus: number) => 16,
    plate: (dexterityBonus: number) => 18
}

export const NeutralAC = ({ armor, hitdice }: NeutralACOptions) => {
    return <DndPropertyLine label="Armor Class">
        {ArmorACs[armor](hitDiceToSkillBonus(hitdice, "full"))} ({armor})
    </DndPropertyLine>
}