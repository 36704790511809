import React from 'react'
import { Col, Row } from 'react-bootstrap'
import EditTableOfContents from './EditTableOfContents'
import MarkdownCodeMirror from './MarkdownCodeMirror'
import MediaEditable from './MediaEditable'

const BodyEdit = () => {
    return <Row>
        <Col xs={{ span: 12, order: 2}} sm={{ span: 8, order: 1}} md={{ span: 8, order: 1}}>
            <MarkdownCodeMirror />
            <MediaEditable />
        </Col>
        <Col xs={{span:12, order:1}} sm={{span:4, order:2}} md={{span:4, order:2}}>
            <EditTableOfContents />
        </Col>
    </Row>
}

export default BodyEdit