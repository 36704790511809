import { InlineNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { InlineParserFunctions, ParseAllInlineFunction, PeekAllInlineFunction } from "../parser"

const notPlaintext = (char: string, index: number, obj: MarkdownRange): boolean | number => {
    if (obj.matches(index, /[_*[!<`]$/)) return true
    if (obj.matches(index, "~~")) return index - 1
    
    return false
}

const processPlainTextRanges = (ranges: NonEmptyMarkdownRangeArray): InlineNode => ({
    type: "inline",
    name: "plaintext",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    value: ranges.flatten().markdown,
    children: []
})

export const parsePlaintext = (ranges: NonEmptyMarkdownRangeArray) => ranges.eatCharacters(
    notPlaintext,
    processPlainTextRanges,
    "Expected plain text"
)



const notPlaintextInjected = (peekAll: PeekAllInlineFunction) => (char: string, index: number, obj: MarkdownRange): boolean | number => {
    const match = peekAll(obj.toArray(), index)
    if (match && match.name !== "plaintext") return match.match
    
    return false
}

const processPlainTextRangesInjected = (parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): InlineNode => ({
    type: "inline",
    name: "plaintext",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    value: ranges.flatten().markdown,
    children: []
})

export const PlaintextParserFunctions: InlineParserFunctions = {
    name: "plaintext",
    peek: (char, index, range: MarkdownRange) => true,
    parse: (ranges, parseInline, peekAll) => ranges.eatCharacters(
        notPlaintextInjected(peekAll),
        processPlainTextRangesInjected(parseInline),
        "Expected plain text"
    )
}
