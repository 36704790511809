import { getStableValues, WhereNode } from "./interpretWhere";

export type TableReference =
    { table: "page" } |
    { table: "item", type?: string }

export const getTableReference = (table: any, where: WhereNode): TableReference => {
    if (typeof(table) !== "string" && table !== "pages" && table !== "items") throw Error(`Unknown table: ${table}`);

    if (table === "pages") return { table: "page" }

    const stableValues = getStableValues(where);
    if (stableValues.type === undefined || Array.isArray(stableValues.type)) return { table:"item" }

    return { table:"item", type:stableValues.type }
}
    