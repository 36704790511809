import { DirPath, FilePath } from "../../data/types";
import { Storage } from 'aws-amplify';
import { pagemediaItemType } from "./pagemediaItemType";
import { MediaItem } from "./types";
import { PageMediaStore } from "../usePageMedia";
import { getObjectACL } from "../../data/s3";

export const publicRandomTablePath = "randomtables_public.json" as FilePath;
export const privateRandomTablePath = "randomtables_private.json" as FilePath;

const loadStore = async (path: FilePath): Promise<MediaItem[]> => {
    const result = await Storage.get(path, { download: true, cacheControl: 'no-cache' }) as any;

    if (result.Body === undefined) return []

    const text = await (result.Body as Blob).text()
    const data = JSON.parse(text) as MediaItem[]

    const fixedData = await Promise.all(data.map(pagemediaItemType))

    return fixedData
}

export const pagemediaLoad = async (initializeOnly: boolean, path: DirPath, store: PageMediaStore) => {
    if (initializeOnly && store.status !== "uninitialized") return

    try {
        const items = await loadStore(path + "media.json" as FilePath)
        const acl = await getObjectACL(`public/${path}media.json`)

        store.setStatusLoaded(path, acl, items, true)
    } catch (error: any) {
        if (error.toString() === 'NoSuchKey: The specified key does not exist.') return store.setStatusLoaded(path, "private", [], false)
        if (error.message === 'Access Denied') return store.setStatusAccessDenied(path)

        throw error
    }
}
