import { InlineNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllInlineFunction, ParseAllInlineFunction, InlineParserFunctions } from "../parser"

const notBRInjected = (peekAll: PeekAllInlineFunction) => (char: string, index: number, obj: MarkdownRange): boolean | number => {
    if (obj.matches(index, ">")) return index + 1

    return false
}

const processBRInjected = (parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): InlineNode => ({
    type: "inline",
    name: "br",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    value: "",
    children: []
})

export const BRParserFunctions: InlineParserFunctions = {
    name: "br",
    peek: (char, index, range: MarkdownRange) => range.matches(index, "<br>", 4),
    parse: (ranges, parseInline, peekAll) => ranges.eatCharacters(
        notBRInjected(peekAll),
        processBRInjected(parseInline),
        "Expected line break"
    )
}
