import React from 'react'
import './neutral/NeutralStatBlock.css'
import DndCreatureHeading from './dnd/DndCreatureHeading';
import { NeutralCreature } from '../neutralcreature';
import NeutralBasicProperties from './neutral/NeutralBasicProperties';
import NeutralPowerBlock from './neutral/NeutralPowerBlock';
import { getHeadingID } from '../../util';

interface NeutralCreatureOptions {
    creature: NeutralCreature
}
const Dnd5eCreatureStatBlock = ({ creature }: NeutralCreatureOptions) => {
    return <div id={creature.name ? getHeadingID(creature.name) : undefined} className={"neutral-stat-block " + (creature.powers === undefined || creature.powers.length <= 4 ? "single" : "double")} data-clip={creature.raw} data-title={creature.name}>
        <div className="neutral-stat-block-content">
            <DndCreatureHeading name={creature.name} />
            <NeutralBasicProperties {...creature} />
            <NeutralPowerBlock type="passive" powers={creature.powers} />
            <NeutralPowerBlock type="attack" powers={creature.powers} />
            <NeutralPowerBlock type="action" powers={creature.powers} />
        </div>
    </div>
}

export default Dnd5eCreatureStatBlock