import { useEffect, useState } from "react"

const arraysEqual = <T>(a: T[], b: T[]): boolean => {
    if (a.length !== b.length) return false

    return a.map((v, i) => b[i] === v).reduce((a, b) => a && b)
}

export const usePersistantArrayReference = <T>(value: T[]): T[] => {
    const [persistantValue, setPersistantValue] = useState(value)

    useEffect(() => {
        if (!arraysEqual(persistantValue, value)) setPersistantValue(value)
    }, [persistantValue, value])

    return persistantValue
}