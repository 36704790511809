import React from 'react'
import './dnd/Dnd5eStatBlock.css'
import { Dnd5eCreature } from '../../dnd'
import DndAbilityScores from './dnd/DndAbilityScores';
import DndPassivePowers from './dnd/DndPassivePowers';
import DndReactionPowers from './dnd/DndReactionPowers';
import DndActionPowers from './dnd/DndActionPowers';
import DndLegendaryPowers from './dnd/DndLegendaryPowers';
import DndCreatureHeading from './dnd/DndCreatureHeading';
import DndBasicProperties from './dnd/DndBasicProperties';
import DndAdvancedProperties from './dnd/DndAdvancedProperties';
import { getHeadingID } from '../../util';

interface Dnd5eCreatureOptions {
    creature: Dnd5eCreature
}
const Dnd5eCreatureStatBlock = ({ creature }: Dnd5eCreatureOptions) => {
    return <div id={creature.name ? getHeadingID(creature.name) : undefined} className={"dnd5e-stat-block " + (creature.powers === undefined || creature.powers.length <= 4 ? "single" : "double")} data-clip={creature.raw} data-title={creature.name}>
        <div className="dnd5e-stat-block-content">
            <DndCreatureHeading {...creature} />
            <DndBasicProperties {...creature} />
            <DndAbilityScores abilities={creature.abilities} />
            <DndAdvancedProperties {...creature} />
            <DndPassivePowers powers={creature.powers} showBreak={true} />
            <DndReactionPowers powers={creature.powers} showBreak={true} />
            <DndActionPowers abilities={creature.abilities} proficiency={creature.proficiency} powers={creature.powers} showBreak={true} />
            <DndLegendaryPowers name={creature.name} proficiency={creature.proficiency} abilities={creature.abilities} powers={creature.powers} showBreak={true} />
        </div>
    </div>
}

export default Dnd5eCreatureStatBlock