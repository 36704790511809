import React from 'react'
import { AbilityScoreSet, AttackPower, calculateToHit } from '../../../dnd';
import DndDieExpression from './DndDieExpression';

interface DndAttackPowerOptions {
    proficiency?: number;
    abilities?: AbilityScoreSet;
    power: AttackPower;
}

const DndAttackPower = ({ proficiency, abilities, power }: DndAttackPowerOptions) => {
    return <p className="power">
        <strong>{power.name}{power.legendaryActionPoints > 1 ? <span> Costs {power.legendaryActionPoints} Actions</span> : null}.</strong>&nbsp;
        <em>{power.range}:</em> +{abilities && proficiency ? calculateToHit(proficiency, abilities, power.hit) : power.hit.toString()} to hit, {power.hitlabel}.&nbsp;
        <em>Hit:</em>&nbsp;
        {abilities && proficiency
            ? <DndDieExpression
                abilities={abilities}
                proficiency={proficiency}
                expression={power.damage}
                showAverage={true}
            />
            : power.damage
        }.&nbsp;
        {power.description}
    </p>
}

export default DndAttackPower