import { InlineNode } from "../ast"
import { MarkdownParserError } from "../error"
import { MarkdownRange, NonEmptyMarkdownRangeArray, RangesResult } from "../markdownrange"
import { ParseAllInlineFunction, PeekAllInlineFunction } from "../parser"

const nonLinkHREF = (char: string, index: number, obj: MarkdownRange): boolean | number => {
    if (index === 0) return false
    if (obj.matches(index, /[) ]$/)) return index + 1

    return false
}
const processLinkHREFRanges = (linkText: InlineNode) => (ranges: NonEmptyMarkdownRangeArray): InlineNode => {
    const hrefRange = ranges.flatten().markdown
    const href = hrefRange.replace(/\n\r/g, '').slice(1, -1)

    return {
        ...linkText,
        meta: {
            href,
            title: hrefRange[hrefRange.length - 1] === " " ? "missing title" : ""
        }
    }
}

const nonTitle = (quote: string) => (char: string, index: number, obj: MarkdownRange): boolean | number => {
    if (obj.matches(index, `${quote})`)) return index + 1
    
    return false
}
const processLinkTitleRanges = (quote: string, link: InlineNode) => (ranges: NonEmptyMarkdownRangeArray): InlineNode => {
    link.meta.title = ranges.flatten().markdown.replace(/\r\n/g, '').replace(new RegExp(`(^${quote}|${quote}\\)$)`, 'g'), '')
    return link
}

export const parseLinkMetadata = (ranges: NonEmptyMarkdownRangeArray, parseInline: ParseAllInlineFunction, peekAll: PeekAllInlineFunction, linkText: InlineNode): RangesResult<InlineNode> => {
    var linkMatch = ranges.eatCharacters(nonLinkHREF, processLinkHREFRanges(linkText), "Expected closing `)` or a space before a title")
    if (linkMatch === null) throw ranges.error("Expected a URL")
    var [link, linkRemainder] = linkMatch

    if (link.meta.title === "missing title") {
        if (!linkRemainder.isNonEmpty()) throw new MarkdownParserError("Expected quoted title after the space", link.end + 1)

        const quote = linkRemainder[0].markdown[0]
        if ("\"'".indexOf(quote) === -1) throw new MarkdownParserError("Expected quoted title starting with a quote", link.end + 1)

        return linkRemainder.eatCharacters(nonTitle(quote), processLinkTitleRanges(quote, link), "Expected closing quote and `)` for the title")
    }

    return [link, linkRemainder]
}
