import { MarkdownError } from "./ast";

const getLineBreaks = (text: string): number[] => {
    let newlineIndexes = [0] as number[];
    let currentIndex = 0;
    
    while ((currentIndex = text.indexOf('\n', currentIndex)) !== -1) {
       newlineIndexes.push(currentIndex + 1);
       currentIndex++;
    }

    return newlineIndexes
}

export interface ContextLine {
    line: number
    text: string
}

export const createMarkdownError = (message: string, index: number, markdown: string): MarkdownError => {
    const breaks = getLineBreaks(markdown)
    var line = 0
    for (let i=0; i<breaks.length; i++) {
        if (breaks[i] <= index && (i + 1 === breaks.length || breaks[i + 1] > index)) {
            line = i
            break
        }
    }

    var context = [] as ContextLine[]
    for (let i=Math.max(0, line - 2); i<Math.min(breaks.length, line + 2); i++) {
        context.push({
            line: i,
            text: markdown.slice(breaks[i], i + 1 === breaks.length ? markdown.length : breaks[i + 1]).replace(/\r?\n$/m, "")
        })
    }

    return {
        type: "error",
        message,
        index,
        line,
        lineIndex: index - breaks[line],
        context
    }
}

export class MarkdownParserError extends Error {
    index: number

    constructor(message: string, index: number) {
        super(message)
        this.index = index
    }
}
