import { BlockNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllBlockFunction, ParseAllBlockFunction, ParseAllInlineFunction, BlockParserFunctions } from "../parser"
import { parse as parseYAML } from 'yaml'

const isNotFrontmatterInjected = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number, obj: MarkdownRange[]): number | boolean => {
    if (index === 0) return false
    if (obj[index].markdown.slice(0, 3) !== "---") return false
    
    return index + 1
}

const processFrontmatterRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => {
    try {
        return {
            type: "block",
            name: "frontmatter",
            start: ranges.getStart(),
            end: ranges.getStart(),
            meta: parseYAML(ranges.slice(1, -1).map(range => range.markdown).join("\n")),
            children: []
        }
    } catch (err: any) {
        return {
            type: "block",
            name: "error",
            start: ranges.getStart(),
            end: ranges.getEnd(),
            meta: {
                errorMessage: err.toString()
            },
            children: []
        }
    }
}

export const FrontmatterParserFunctions: BlockParserFunctions = {
    name: "frontmatter",
    peek: (range, index, obj, ancestors) => range.start === 0 && range.markdown.slice(0, 3) === "---",
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotFrontmatterInjected(peekAll),
        processFrontmatterRangesInjected(parseBlocks, parseInline),
        "Expected start of frontmatter"
    )
}
