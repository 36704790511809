import { MarkdownRange } from "../markdownrange";
import { InlineParserFunctions } from "../parser";
import { parseComplexLink } from "./linkComplex";

export const ComplexImageParserFunctions: InlineParserFunctions = {
    name: "image",
    peek: (char, index, range: MarkdownRange) => range.matches(index + 1, "!["),
    parse: (ranges, parseInline, peekAll) => {
        // skip initial !
        const actualMatch = ranges.splitOnCharacter((char, index) => index !== 0)
        if (actualMatch === null || !actualMatch[1].isNonEmpty()) throw ranges.error("Expecting content (an image) to follow `!`")
    
        const linkMatch = parseComplexLink(actualMatch[1], parseInline, peekAll)
        if (linkMatch === null) throw ranges.error("Expecting a link definition `!`")
    
        return [{
            type: "inline",
            name: "image",
            start: ranges.getStart(),
            end: linkMatch[0].end,
            meta: {
                src: linkMatch[0].meta.href,
                alt: linkMatch[0].children.map(node => node.value).join(" "),
                title: linkMatch[0].meta.title
            },
            value: "",
            children: []
        }, linkMatch[1]]
    }
}
