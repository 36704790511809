import { InlineNode } from "../ast"
import { MarkdownParserError } from "../error"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllInlineFunction, ParseAllInlineFunction, InlineParserFunctions } from "../parser"

const notEmphasis = (char: string, index: number, obj: MarkdownRange): boolean => {
    return "*_".indexOf(char) === -1
}

const notEmphasisInjected = (peekAll: PeekAllInlineFunction, closer: string) => (char: string, index: number, obj: MarkdownRange): boolean | number => {
    if (index < closer.length) return false
    if (obj.matches(index, closer)) return index + 1
    
    return false
}

const processEmphasisRangesInjected = (parseInline: ParseAllInlineFunction, openerLength: number) => (ranges: NonEmptyMarkdownRangeArray): InlineNode => ({
    type: "inline",
    name: "emphasis",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {
        bold: openerLength === 2 || openerLength === 3 ? "true" : "false",
        italic: openerLength === 1 || openerLength === 3 ? "true" : "false",
        length: openerLength.toString()
    },
    value: "",
    children: parseInline(ranges.flatten().slice(ranges.getStart() + openerLength, ranges.getEnd() - openerLength).toArray())
})

const EmphasisStartRE = /[_*]$/
export const EmphasisParserFunctions: InlineParserFunctions = {
    name: "emphasis",
    peek: (char, index, range: MarkdownRange) => range.matches(index, EmphasisStartRE),
    parse: (ranges, parseInline, peekAll) => {
        const [opener, remainder] = ranges.splitOnCharacter(notEmphasis, "Expecting emphasis start")
        if (opener.getEnd() - opener.getStart() > 3) {
            // this is a **_** situation. i can't be bothered handling it
            throw opener.error("Escape emphasis characters being used in text", opener.getStart() + 3)
        }
    
        const openingText = opener.flatten().markdown
        const closingText = openingText.split("").reverse().join("")
    
        if (!remainder.isNonEmpty()) throw new MarkdownParserError("Expecting emphasis close", opener.getEnd())
    
        return ranges.eatCharacters(
            notEmphasisInjected(peekAll, closingText),
            processEmphasisRangesInjected(parseInline, openingText.length),
            "Expecting emphasis close"
        )
    }
}
