import { create } from "zustand"
import { BasicACL, DirPath, FilePath } from "../data/types"
import { pagemediaSave } from "./pagemedia/pagemediaSave"
import { ItemACL, MediaItem, MoveDirection, PageMediaGeneratedData } from "./pagemedia/types"
import { pagemediaLoad } from "./pagemedia/pagemediaLoad"
import { pagemediaSetPage } from "./pagemedia/pagemediaSetPage"
import { pagemediaUploadItem } from "./pagemedia/pagemediaUploadItems"
import { pagemediaSetItemACL } from "./pagemedia/pagemediaSetItemACL"
import { pagemmediaDelete } from "./pagemedia/pagemediaDelete"
import { Storage } from 'aws-amplify';
import { pagemediaResolveImages } from "./pagemedia/pagemediaResolveImages"
import { useCallback, useEffect, useState } from "react"
import { pagemediaSetACL } from "./pagemedia/pagemediaSetACL"
import { pagemmediaRemoveItem } from "./pagemedia/pagemediaRemoveItem"
import { pagemmediaUnremoveItem } from "./pagemedia/pagemediaUnremoveItem"
import { Completion } from "@codemirror/autocomplete"
import { pagemediaGetGeneratedData } from "./pagemedia/pagemediaGetGeneratedData"
import { useShallow } from "zustand/react/shallow"
import { pagemediaMoveItem } from "./pagemedia/pagemediaMove"
import { pagemediaUploadNextItem } from "./pagemedia/pagemediaProcessNextQueuedItem"
import { useUser } from "./useUser"

export interface PageMediaStore {
    status: "uninitialized" | "loading" | "loaded" | "error" | "accessdenied"
    statusDetail: string

    path: DirPath
    items: MediaItem[]
    acl: BasicACL
    exists: boolean

    mediaLinkCompletions: Completion[]

    setStatusUninitialized: (path: DirPath) => void
    setStatusLoading: () => void
    setStatusLoaded: (path: DirPath, acl: BasicACL, items: PageMediaStore["items"], exist: boolean) => void
    setStatusError: (error: any) => void
    setStatusAccessDenied: (path: DirPath) => void

    setExists: (exists: boolean) => void

    save: () => Promise<void>
    setItems: (items: PageMediaStore["items"], save?: boolean) => Promise<void>
    updateItem: (path: FilePath, changes: Partial<MediaItem>, save?: boolean) => Promise<void>
    updateItemMetadata: (path: FilePath, key: string, value: string) => Promise<void>
    setPage: (path: DirPath, acl: BasicACL) => Promise<void>
    delete: () => Promise<void>

    load: (initializeOnly: boolean, path: DirPath) => Promise<void>
    uploadItems: (source: File | File[]) => Promise<void>
    processNextQueuedItem: () => Promise<void>
    setItemACL: (path: FilePath, acl: ItemACL) => Promise<void>
    setACL: (acl: BasicACL) => Promise<void>
    removeItem: (path: FilePath) => Promise<void>
    unremoveItem: (path: FilePath) => Promise<void>
    moveItem: (path: FilePath, direction: MoveDirection) => Promise<void>
}

const EmptyGeneratedData: PageMediaGeneratedData = {
    mediaLinkCompletions: [],
}

export const usePageMedia = create<PageMediaStore>()((set, get) => ({
    status: "uninitialized",
    statusDetail: "",
    exists: false,

    path: "" as DirPath,
    items: [],
    acl: "private",

    mediaLinkCompletions: [],

    setStatusUninitialized: (path) => set(() => ({ status: "uninitialized", statusDetail: "", path, acl: "private", items: [], ...EmptyGeneratedData } as Partial<PageMediaStore>)),
    setStatusLoading: () => set(() => ({ status: "loading", statusDetail: "", items: [], ...EmptyGeneratedData } as Partial<PageMediaStore>)),
    setStatusLoaded: (path, acl, items, exists) => set(() => ({ status: "loaded", statusDetail: "", exists, path, acl, items, ...pagemediaGetGeneratedData(items) } as Partial<PageMediaStore>)),
    setStatusError: (error) => set(() => ({ status: "error", statusDetail: error.toString() } as Partial<PageMediaStore>)),
    setStatusAccessDenied: (path) => set(() => ({ status: "accessdenied", statusDetail: "", path } as Partial<PageMediaStore>)),

    setExists: (exists) => set(() => ({ exists })),

    save: () => pagemediaSave(get()),
    setItems: (items, save=true) => {
        const newState = { ...get(), items, ...pagemediaGetGeneratedData(items) } as PageMediaStore
        set(() => newState)

        if (save) return pagemediaSave(newState)

        return Promise.resolve()
    },
    updateItem: (path, changes, save=true) => {
        const state = get()
        const newState = {
            ...state,
            items: state.items.map(item => {
                if (item.path !== path) return item
                
                const newItem = { ...item, ...changes }
                if (newItem.status === "loaded") {
                    delete newItem["progress"]
                    delete newItem["source"]
                }
                
                return newItem
            })
        } as PageMediaStore
        set(() => newState)

        if (save) return pagemediaSave(newState)

        return Promise.resolve()
    },
    updateItemMetadata: (path, key, value) => {
        const state = get()
        const newState = {
            ...state,
            items: state.items.map(item => {
                if (item.path !== path) return item

                return {
                    ...item,
                    metadata: {
                        ...item.metadata,
                        [key]: value
                    }
                }
            })
        } as PageMediaStore
        set(() => newState)

        return pagemediaSave(newState)
    },
    setPage: (path) => pagemediaSetPage(path, get()),

    load: (initializeOnly, path) => pagemediaLoad(initializeOnly, path, get()),
    uploadItems: (files) => pagemediaUploadItem(files, get()),
    processNextQueuedItem: () => pagemediaUploadNextItem(get()),
    setItemACL: (file, acl) => pagemediaSetItemACL(file, acl, get()),
    setACL: async (acl) => {
        const store = get()
        const newStore = { ...store, acl }

        await pagemediaSetACL(acl, get())

        set(() => newStore)

        return pagemediaSave(newStore)
    },
    removeItem: (path) => pagemmediaRemoveItem(path, get()),
    unremoveItem: (path) => pagemmediaUnremoveItem(path, get()),
    moveItem: (path, direction) => pagemediaMoveItem(path, direction, get()),

    delete: () => pagemmediaDelete(get()),
}))

export const getMediaPath = (path: string) => Storage.get(path)

export const useResolvedImages = () => {
    const items = usePageMedia(state => state.items)
    const [resolvedItems, setResolvedItems] = useState([] as MediaItem[]);

    useEffect(() => {
        Promise.all(items.map(pagemediaResolveImages)).then(setResolvedItems)
    }, [items]);

    return resolvedItems;
}

export const useSignedURL = (path: FilePath, req: boolean): [FilePath | null, () => Promise<FilePath>] => {
    const [signedPath, setSignedPath] = useState(null as FilePath | null)

    const getSignedPath = useCallback(async () => {
        const signedPath = await Storage.get(path)
        return signedPath as FilePath
    }, [path])
    useEffect(() => {
        if (!req) {
            setSignedPath(null)
        } else {
            getSignedPath().then(signedPath => setSignedPath(signedPath as FilePath))
        }
    }, [req, setSignedPath, getSignedPath])

    return [signedPath, getSignedPath]
}

export const setPageMediaPath = (path: DirPath) => {
    const store = usePageMedia.getState()
    store.setStatusUninitialized(path)
    pagemediaLoad(false, path, store)
}

export const useMediaLinkCompletions = (): PageMediaGeneratedData => usePageMedia(store => ({
    mediaLinkCompletions: store.mediaLinkCompletions
}))

export const usePageMediaOperators = () => usePageMedia(useShallow(state => ({
    updateItemMetadata: state.updateItemMetadata,
    setItemACL: state.setItemACL,
    removeItem: state.removeItem,
    unremoveItem: state.unremoveItem,
    moveItem: state.moveItem,
})))

var currentUserStatus = ""
useUser.subscribe(state => {
    if (currentUserStatus !== state.status) {
        currentUserStatus = state.status
        const pageMediaState = usePageMedia.getState()
        pagemediaLoad(false, pageMediaState.path, usePageMedia.getState())
    }
})