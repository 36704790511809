import { HeadingLevel } from "@aws-amplify/ui-react";
import React from "react";
import H from "../../../components/view/H";
import { splitArray, incrementHeadingLevel, getHeadingID } from "../../util";
import { usePathTitles } from "../../../state/useMetadataStore";
import { RandomTableReference } from "../../../state/randomtables/types";
import { useRandomTableReferences } from "../../../state/useRandomTables";

interface LinkRowOptions {
    reference: RandomTableReference;
}
const LinkRow = ({ reference }: LinkRowOptions) => {
    return <tr>
        <td><a href={`/${reference.path}#${getHeadingID(reference.randomTable)}`}>{reference.randomTable}</a></td>
        <td><dl className="row">{reference.setTitles.map((v,i) => <React.Fragment key={i}><dt className="col-sm-12">{v}</dt></React.Fragment>)}</dl></td>
    </tr>
}

interface LinksTableOptions {
    title: string;
    level: HeadingLevel;
    href?: string;
    references: RandomTableReference[];
}
const LinksTable = ({ title, level, href, references }: LinksTableOptions) => {
    return <div>
        <H level={level} href={href}>{title}</H>
        <table className="table table-striped random-table-index">
            <thead>
                <tr>
                    <th>Table</th>
                    <th>Columns</th>
                </tr>
            </thead>
            <tbody>
                {references.map((result, i) => <LinkRow key={i} reference={result} />)}
            </tbody>
        </table>
    </div>;
}

const getReferencePaths = (references: RandomTableReference[]): string[] => references.reduce<string[]>(
    (paths, link) => paths.indexOf(link.path) === -1
        ? [...paths, link.path] as string[]
        : paths,
    []
)

interface FunctionalRandomLinksOptions {
    title: string;
    level: HeadingLevel;
    references: RandomTableReference[];
}
const FunctionalRandomLinksTable = ({ title, level, references }: FunctionalRandomLinksOptions) => {
    const showPage = references.length > 1 || references.filter(reference => reference.path === "*").length > 0;
    const links = useRandomTableReferences(references);
    const pathTitles = usePathTitles(getReferencePaths(links))

    if (links.length === 0) {
        return <React.Fragment>
            <H level={level}>{title}</H>
            <p><strong>Loading...</strong></p>
        </React.Fragment>
    }
    else if (showPage) {
        return <React.Fragment>
            <H level={level}>{title}</H>
            {splitArray(links, (a,b) => a.path !== b.path).map((pageLinks, i) => <LinksTable key={i} title={pathTitles[pageLinks[0].path]} level={incrementHeadingLevel(level)} href={pageLinks[0].path} references={pageLinks} />)}
        </React.Fragment>;
    }
    else {
        return <LinksTable title={title} level={level} references={links} />;
    }
}

export default FunctionalRandomLinksTable