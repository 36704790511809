import React from 'react'
import { Dnd5eCreatureSize, Dnd5eCreatureType } from '../../../dnd'
import { getHeadingID } from '../../../util'

interface DndCreatureHeadingOptions {
    name?: string
    size?: Dnd5eCreatureSize
    type?: Dnd5eCreatureType
}

const DndCreatureHeading = ({ name, size, type }: DndCreatureHeadingOptions) => {
    if (!(name || size || type)) return null

    return <div className="block creature-heading">
        {name ? <h6 id={getHeadingID(name)}>{name}</h6> : null}
        {size || type ? <p>{size} {type}</p> : null}
    </div>
}

export default DndCreatureHeading