import { CustomMarkdownBlock } from "./_utils";
import { parseRandomTable } from "../randomtable";
import React from "react";
import FunctionalRandomTable from "./components/FunctionalRandomTable";
import { RandomTable } from "../../state/randomtables/types";

export const RandomTableBlock = new CustomMarkdownBlock<RandomTable>({
    name: "randomtable",
    parser: (block) => {
        const randomTable = parseRandomTable(block)
        if (randomTable === null) throw new Error("expecting a title, a header, header break, and at least one row")
    
        return randomTable
    },
    renderToReact: (data) => {
        return <FunctionalRandomTable level={data.level} data={data} />
    },
    template: `[[randomtable|boundsets=false|diecolumns=0]]
| Table name
| Colours | Foods |
| -------- | -------- |
| Fuscia | Bacon |
| Teal | Pineapple |
| Vermillion | Marshmallow |
`
})
