import { BlockNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllBlockFunction, ParseAllBlockFunction, ParseAllInlineFunction, BlockParserFunctions } from "../parser"

const SectionStartRE = /^(#+)(.*)/m

const isNotSectionInjected = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number, obj: MarkdownRange[]): boolean => {
    if (index === 0) return false
    if (range.markdown[0] !== "#") return false

    const initialOpening = obj[0].markdown.replace(SectionStartRE, '$1')
    const initialOpeningLength = Math.max(1, Math.min(6, initialOpening.length - 1)).toString()
    const thisOpening = range.markdown.replace(SectionStartRE, '$1')
    const thisOpeningLength = Math.max(1, Math.min(6, thisOpening.length - 1)).toString()
    return thisOpeningLength <= initialOpeningLength
}

const processSectionRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => {
    return {
        type: "block",
        name: "section",
        start: ranges.getStart(),
        end: ranges.getEnd(),
        meta: {},
        children: parseBlock(ranges)
    }
}

export const SectionParserFunctions: BlockParserFunctions = {
    name: "section",
    peek: (range, index, obj, ancestors) => {
        if (ancestors[ancestors.length - 1] === "section") return false
        
        return range.markdown[0] === "#"
    },
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotSectionInjected(peekAll),
        processSectionRangesInjected(parseBlocks, parseInline),
        "Expected start of section"
    )
}
