import { BlockNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllBlockFunction, ParseAllBlockFunction, ParseAllInlineFunction, BlockParserFunctions } from "../parser"

const isNotMacroTagInjected = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number): boolean => index !== 0

const processMacroTagRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => {
    const tag = ranges.flatten().markdown.slice(2)
    const colonIndex = tag.indexOf(":")

    return {
        type: "block",
        name: "tag",
        start: ranges.getStart(),
        end: ranges.getEnd(),
        meta: {
            name: tag.slice(0, colonIndex).trim(),
            value: tag.slice(colonIndex + 1).trim()
        },
        children: []
    }
}

const matchTableLineRE = /^\|.*:.*\s*/
export const MacroTagParserFunctions: BlockParserFunctions = {
    name: "tag",
    peek: (range, index, obj, ancestors) => {
        if (ancestors[ancestors.length - 1] !== "macro") return false
        
        return range.markdown.search(matchTableLineRE) !== -1
    },
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotMacroTagInjected(peekAll),
        processMacroTagRangesInjected(parseBlocks, parseInline),
        "Expected start of a macro tag"
    )
}
