import { Storage } from 'aws-amplify';
import { MetadataStore } from "../useMetadataStore"
import { privateMetadataPath, publicMetadataPath } from './metadataStoreLoad';
import { putObjectACL } from '../../data/s3';

export const metadataStoreSave = async (store: MetadataStore) => {
    if (store.status === "loaded") throw Error("Metadata is not initialized");

    if (store.mode === "private") {
        // save main store data as private db
        await Storage.put(privateMetadataPath, JSON.stringify({
            pages: store.pages,
            metadata: store.metadata,
            items: store.items,
            itemmetadata: store.itemmetadata
        }), { cacheControl: 'no-cache' });

        // save separate public data
        await Storage.put(publicMetadataPath, JSON.stringify(store.publicData), { cacheControl: 'no-cache' });
        await putObjectACL(`public/${publicMetadataPath}`, "public-read");
    }
    if (store.mode === "public") {
        // save main store data as public db
        await Storage.put(publicMetadataPath, JSON.stringify({
            pages: store.pages,
            metadata: store.metadata,
            items: store.items,
            itemmetadata: store.itemmetadata
        }), { cacheControl: 'no-cache' });
        await putObjectACL(`public/${publicMetadataPath}`, "public-read");
    }
}