import Auth from '@aws-amplify/auth';
import { UserStore, setStatusError, setStatusUser } from "../useUser";

export const userSignUp = async (username: string, password: string, email: string="", phone_number: string="", store: UserStore) => {
    try {
        const { user } = await Auth.signUp({
            username,
            password,
            attributes: {
                email,          // optional
                phone_number,   // optional - E.164 number convention
                // other custom attributes 
            },
            autoSignIn: { // optional - enables auto sign in after user is confirmed
                enabled: true,
            }
        })

        setStatusUser(user)
    } catch (error: any) {
        console.error(error)
        setStatusError(error)
    }
}