import { InlineNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllInlineFunction, ParseAllInlineFunction, InlineParserFunctions } from "../parser"

const notStrikethroughInjected = (peekAll: PeekAllInlineFunction) => (char: string, index: number, obj: MarkdownRange): boolean | number => {
    if (index < 2) return false
    if (obj.matches(index, "~~")) return index + 1

    return false
}

const processStrikethroughInjected = (parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): InlineNode => ({
    type: "inline",
    name: "strikethrough",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    value: "",
    children: parseInline(ranges.flatten().slice(ranges.getStart() + 2, ranges.getEnd() - 2).toArray())
})

export const StrikethroughParserFunctions: InlineParserFunctions = {
    name: "strikethrough",
    peek: (char, index, range: MarkdownRange) => range.matches(index + 1, "~~"),
    parse: (ranges, parseInline, peekAll) => ranges.eatCharacters(
        notStrikethroughInjected(peekAll),
        processStrikethroughInjected(parseInline),
        "Expected strikethrough"
    )
}
