import { FilePath } from "../../data/types";
import { getTitleFromFilename } from "../../data/util";

const metaTitle = `---
title: Title
---

[[query]]
| SELECT title, tags
| FROM pages
| WHERE title is not null
`
export const metaTitleTemplate = (path: FilePath): string => metaTitle

const metaTagKey = `---
title: {{title}}
---

[[tagvalues]]
| {{key}}

[[query]]
| SELECT title, \`{{key}}\`, tags
| FROM pages
| WHERE \`{{key}}\` is not null
| ORDER BY title ASC
`
export const metaTagKeyTemplate = (path: FilePath, key: string): string => metaTagKey
    .replace(/\{\{title\}\}/g, getTitleFromFilename(key))
    .replace(/\{\{key\}\}/g, key)

const metaTagValue = `---
title: "{{title}}"
---

[[query]]
| SELECT title, tags
| FROM pages
| WHERE {{key}} = "{{value}}"
| ORDER BY title ASC
`
export const metaTagValueTemplate = (path: FilePath, key: string, value: string): string => metaTagValue
    .replace(/\{\{title\}\}/g, `${getTitleFromFilename(key)}: ${getTitleFromFilename(value)}`)
    .replace(/\{\{key\}\}/g, key)
    .replace(/\{\{value\}\}/g, value)

const itemTitle = `---
title: Title ({{type}})
---

[[query]]
| SELECT title
| FROM items
| WHERE type = "{{type}}" AND title is not null
`
export const itemTitleTemplate = (path: FilePath, type: string): string => itemTitle
    .replace(/\{\{type\}\}/g, type)

const itemTagKey = `---
title: {{title}}
---

[[query]]
| SELECT DISTINCT \`{{key}}\`
| FROM items
| WHERE type = "{{type}}" and \`{{key}}\` is not null
| ORDER BY \`{{key}}\`

[[query]]
| SELECT title, \`{{key}}\`
| FROM items
| WHERE type = "{{type}}" AND \`{{key}}\` is not null
| ORDER BY title ASC
`
export const itemTagKeyTemplate = (path: FilePath, type: string, key: string): string => itemTagKey
    .replace(/\{\{type\}\}/g, type)
    .replace(/\{\{title\}\}/g, `${getTitleFromFilename(key)} (${type})`)
    .replace(/\{\{key\}\}/g, key)

const itemTagValue = `---
title: "{{title}}"
---

[[query]]
| SELECT title
| FROM items
| WHERE type = "{{type}}" AND \`{{key}}\` = "{{value}}"
| ORDER BY title ASC
`
export const itemTagValueTemplate = (path: FilePath, type: string, key: string, value: string): string => itemTagValue
    .replace(/\{\{type\}\}/g, type)
    .replace(/\{\{title\}\}/g, `${getTitleFromFilename(key)}: ${getTitleFromFilename(value)} (${type})`)
    .replace(/\{\{key\}\}/g, key)
    .replace(/\{\{value\}\}/g, value)

const randomTable = `---
title: Random Tables
---

## Random Table Collections

[[query]]
| SELECT title
| FROM pages
| WHERE type = 'randomtablecollection'

[[randomtablelinks]]
| ## All Random Tables
| * | * |
`
export const randomTableTemplate = (path: FilePath): string => randomTable

const fallback = `---
title: {{title}}
---

`
export const fallbackTemplate = (path: FilePath, filename: string): string => fallback
    .replace(/\{\{title\}\}/g, getTitleFromFilename(filename))

export const pagemarkdownDefaultTemplate = (path: FilePath): string => {
    const [type, ...parts] = path.replace(/(^\/|\/index\.md$)/g, '').split("/");

    if (type === "meta") {
        const [key, value] = parts;

        if (key === "title") return metaTitleTemplate(path);

        if (value === undefined) {
            return metaTagKeyTemplate(path, key);
        }
        else {
            return metaTagValueTemplate(path, key, value);
        }
    };
    if (type === "items") {
        const [type, key, value] = parts;

        if (type === undefined) {
            // /items/
            return fallbackTemplate(path, "type");
        }
        else if (key === undefined) {
            // /items/[type]/
            return itemTitleTemplate(path, type);
        }
        else if (value === undefined) {
            // /items/[type]/[key]/
            return itemTagKeyTemplate(path, type, key);
        }
        else {
            // /items/[type]/[key]/[value]/
            return itemTagValueTemplate(path, type, key, value);
        }
    }

    if (type === "randomtables" && parts.length === 0) randomTableTemplate(path);

    return fallbackTemplate(path, path.replace(/(^\/|\/$)/g, '').split("/").pop() || '');
}
    