import { MetadataStoreData } from "../useMetadataStore";
import { metadataStoreGetItemTagCompletions } from "./metadataStoreGetItemTagCompletions";
import { metadataStoreGetItemTagValueCompletions } from "./metadataStoreGetItemTagValueCompletions";
import { metadataStoreGetLinkCompletions } from "./metadataStoreGetLinkCompletions";
import { metadataStoreGetPageTagCompletions } from "./metadataStoreGetPageTagCompletions";
import { metadataStoreGetPageTagValueCompletions } from "./metadataStoreGetPageTagValueCompletions";
import { metadataStoreGetTemplates } from "./metadataStoreGetTemplates";

export const metadataStoreGetGeneratedData = (store: MetadataStoreData) => ({
    templates: metadataStoreGetTemplates(store),
    pageLinkCompletions: metadataStoreGetLinkCompletions(store),
    pageTagCompletions: metadataStoreGetPageTagCompletions(store),
    itemTagCompletions: metadataStoreGetItemTagCompletions(store),
    pageTagValueCompletions: metadataStoreGetPageTagValueCompletions(store),
    itemTagValueCompletions: metadataStoreGetItemTagValueCompletions(store),
})