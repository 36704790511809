import { DirPath } from "../../data/types";
import { getHeadingID } from "../../data/util";
import { MetadataStoreData } from "../useMetadataStore";
import { metadataStoreGetMetadataValue } from "./metadataStoreGetMetadataValue";
import { TypedTagCompletions } from "./types";

export const metadataStoreGetItemTagValueCompletions = (store: MetadataStoreData): TypedTagCompletions => {
    const result: TypedTagCompletions = {
        general: {
            type: []
        }
    };

    for (let type in store.itemmetadata) {
        result.general.type.push({
            label: type,
            type: "macro",
            detail: "Tagged title tag value"
        });

        for (let key in store.itemmetadata[type]) {
            if (key !== "type" && metadataStoreGetMetadataValue(`/items/${type}/${getHeadingID(key)}/` as DirPath, 'autocomplete', "true", store) === "true") {
                result[type] = result[type] || {};
                result[type][key] = Object.keys(store.itemmetadata[type][key]).map(value => ({
                    label: value,
                    type: "macro",
                    detail: "Tagged title tag value"
                }));
            }
        }
    }
    
    return result;
};
