import { BasicACL, DirPath } from '../data/types';
import { useMetadataCompletions, useMetadataStore } from './useMetadataStore';
import { useRandomTableCompletions, useRandomTables } from './useRandomTables';
import { setPageMediaPath, useMediaLinkCompletions, usePageMedia } from './usePageMedia';
import { setPageMarkdownPath, usePageMarkdown } from './usePageMarkdown';
import { create } from 'zustand';
import { IDArray, PageElement, PageElementStatus, PageElementStatuses, PageGeneratedData, SectionReference, TableOfContentsTiered } from './page/types';
import { parseRandomTables } from '../data/randomtable';
import { autocompletion } from '@codemirror/autocomplete';
import { getAllCompletions } from '../data/autocomplete';
import { pageGenerate } from './page/pageGenerate';
import { EditorView } from 'codemirror';
import { pageGoToHeading } from './page/pageGoToHeading';
import { useEffect, useState } from 'react';
import { pageGetVisibleIds } from './page/pageGetVisibleIDs';
import { pageMergeStatus } from './page/pageMergeStatus';
import { pageWatchHeadings } from './page/pageWatchHeadings';
import { watchArbitraryResult } from '../data/util';
import { pageGenerateSections } from './page/pageGenerateSections';
import { useShallow } from 'zustand/react/shallow';

export interface PageData extends PageGeneratedData {
    status: 'uninitialized' | 'loading' | 'loaded' | 'editable' | 'saving' | 'accessdenied' | 'error'
    elementStatus: PageElementStatuses

    markdown: string
    acl: BasicACL
    changed: boolean
    dialog: 'signin' | 'print' | ''
    editor: null | EditorView

    updateStatus: (status: PageElementStatus, element?: PageElement) => void
    edit: () => void
    save: () => Promise<void>
    cancel: () => void
    delete: () => Promise<void>

    setACL: (acl: BasicACL) => void
    setMarkdown: (markdown: string) => void
    showDialog: (dialog: PageData["dialog"]) => void
    setEditor: (editor: EditorView) => void

    tocTiered: TableOfContentsTiered[]
    goToHeading: (id: number | string) => void

    sections: SectionReference[]
    setSections: (sections: SectionReference[]) => void
    setWebSections: (sections: string[]) => void
    setPrintSections: (sections: string[]) => void
}

const emptyGeneratedData = {
    tocTiered: [],
    sections: [],
} as PageGeneratedData

export const usePageState = create<PageData>()((set, get) => ({
    status: 'uninitialized',
    elementStatus: {
        markdown: 'uninitialized',
        media: 'uninitialized',
    },
    
    markdown: '',
    acl: 'private',
    changed: false,
    dialog: '',
    editor: null,

    tocTiered: [],
    sections: [],

    updateStatus: (status, element) => set(state => element !== undefined
        ? {
            status: pageMergeStatus(state.status, { ...state.elementStatus, [element]: status }),
            elementStatus: { ...state.elementStatus, [element]: status }
        }
        : {
            status,
            elementStatus: { markdown: status, media: status }
        },
    ),
    edit: () => {
        const { markdown, acl } = usePageMarkdown.getState()
        set(state => ({ status: 'editable', markdown, ...pageGenerate(markdown, state), acl, changed: false }))
    },
    save: async () => {
        const { status, markdown, acl } = usePageState.getState()
        if (status !== "editable") throw new Error("Page is not in editable state")
        set(() => ({ status: 'saving' }))

        const path = usePageMarkdown.getState().path
        const randomTables = parseRandomTables(markdown)

        await usePageMarkdown.getState().setMarkdown(path, acl, markdown, true, true)
        await usePageMedia.getState().setACL(acl)
        await useRandomTables.getState().addPage(path, acl, randomTables)

        const { metadata, itemMetadata } = usePageMarkdown.getState()
        await useMetadataStore.getState().addPage(path, acl, metadata, itemMetadata)

        set(() => ({ status: 'loaded', markdown: '', ...emptyGeneratedData, acl: 'private', changed: false }))
    },
    cancel: () => set(() => ({ status: 'loaded', markdown: '', ...emptyGeneratedData, acl: 'private', changed: false })),
    delete: async () => {
        const path = usePageMarkdown.getState().path

        await Promise.all([
            usePageMarkdown.getState().delete(),
            usePageMedia.getState().delete(),
            useRandomTables.getState().removePage(path),
            useMetadataStore.getState().removePage(path),
        ])
    },

    setACL: (acl: BasicACL) => set(() => ({ acl, changed: true })),
    setMarkdown: (markdown: string) => set(state => ({ markdown, changed: true, ...pageGenerate(markdown, state) })),
    showDialog: (dialog) => set(() => ({ dialog })),
    setEditor: (editor) => set(() => ({ editor })),

    goToHeading: (id) => pageGoToHeading(id, get()),

    setSections: (sections) => set({ sections }),
    setWebSections: (indexes) => set(state => ({
        ...state,
        sections: state.sections.map(section => section.web === (indexes.indexOf(section.id) !== -1) ? section : { ...section, web:(indexes.indexOf(section.id) !== -1) })
    })),
    setPrintSections: (indexes) => set(state => ({
        ...state,
        sections: state.sections.map(section => section.print === (indexes.indexOf(section.id) !== -1) ? section : { ...section, print:(indexes.indexOf(section.id) !== -1) })
    })),
}))

usePageMarkdown.subscribe(({ status, markdown }) => {
    usePageState.getState().updateStatus(status, 'markdown')
    if (status === "loaded") usePageState.getState().setSections(pageGenerateSections(markdown))
})
usePageMedia.subscribe(({ status }) => usePageState.getState().updateStatus(status, 'media'))

export const useCompletions = (): any => {
    const randomTableCompletions = useRandomTableCompletions()
    const metadataCompletions = useMetadataCompletions()
    const mediaLinkCompletions = useMediaLinkCompletions()

    return autocompletion({
        override: [getAllCompletions({
            randomTables: randomTableCompletions.randomTableTagCompletions,
            link: [
                ...metadataCompletions.pageLinkCompletions,
                ...mediaLinkCompletions.mediaLinkCompletions
            ],
            frontmatter: {
                tag: metadataCompletions.pageTagCompletions,
                tagValue: metadataCompletions.pageTagValueCompletions
            },
            item: {
                tag: metadataCompletions.itemTagCompletions,
                tagValue: metadataCompletions.itemTagValueCompletions
            }
        })]
    });
};

export const useHeadingVisibility = (tocTiered: TableOfContentsTiered[]) => {
    const editor = usePageState(state => state.editor)
    const [visibleIDs, setVisibleIDs] = useState([] as IDArray)
    useEffect(() => {
        const onScroll = () => setVisibleIDs(pageGetVisibleIds(editor, tocTiered))
        document.addEventListener("scroll", onScroll);

        return () => document.removeEventListener("scroll", onScroll);
    }, [tocTiered, editor])

    return visibleIDs
}

export const useSections = (type: "web" | "print") => usePageState(useShallow(state => ({
    sections: state.sections.filter(section => type === "web" ? section.web : section.print),
    setSections: type === "web" ? state.setWebSections : state.setPrintSections
})))

setInterval(() => {
    const store = usePageState.getState()
    if (store.status === "editable") return

    const toc = pageWatchHeadings(store)
    if (store.tocTiered !== toc) {
        if (window.location.hash.length) document.querySelector(window.location.hash)?.scrollIntoView();
        usePageState.setState({ tocTiered: toc });
    }
}, 100);

watchArbitraryResult(
    () => window.location.pathname.slice(1) + (window.location.pathname.search(/\/$/) === -1 ? '/' : '') as DirPath,
    (path) => {
        usePageState.getState().updateStatus("loading")
        setPageMarkdownPath(path)
        setPageMediaPath(path)
    },
    10
)

let currentEditable = false as boolean
function handleImagePaste(event: ClipboardEvent) {
    const items = event.clipboardData?.items;
    if (items && items.length && items[0].type.startsWith('image/')) {
        const file = items[0].getAsFile()
        if (file) usePageMedia.getState().uploadItems([file])
    }
}
usePageState.subscribe(({ status }) => {
    if (currentEditable !== (status === "editable")) {
        currentEditable = status === "editable";

        if (currentEditable) {
            document.addEventListener('paste', handleImagePaste);
        } else {
            document.removeEventListener('paste', handleImagePaste);
        }
    }
});