import { getPageTagValuePath, useTagValues } from "../../state/useMetadataStore";
import { CustomMarkdownBlock } from "./_utils";
import React from 'react'


interface TagValuesOptions {
    tag: string
}
const TagValues = ({ tag }: TagValuesOptions) => {
    const values = useTagValues(tag)
    if (values.length === 0) return <>Values: None</>

    return <React.Fragment>
        Values:
        <ul>
        {values.map((value, index) => value.search(/[^-\w_]/) === -1
            ? <li key={index}><a href={getPageTagValuePath(tag, value)}>{value}</a></li>
            : <li key={index}>{value}</li>
        ).join(", ")}
        </ul>
    </React.Fragment>;
}


interface TagValuesData {
    tag: string;
}
export const TagValueBlock = new CustomMarkdownBlock<TagValuesData>({
    name: "tagvalues",
    parser: (block) => {
        if (block.content.length !== 1) throw new Error("one tag name expected");

        return {
            tag: block.content[0]
        }
    },
    renderToReact: (data) => <TagValues tag={data.tag} />,
    template: `[[tagvalues]]
| tagname
`
})