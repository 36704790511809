import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BasicACL } from '../../data/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { ItemACL } from '../../state/pagemedia/types';

interface ACLToggleOptions {
    value: BasicACL;
    onChange: (acl: BasicACL) => void;
}
const ACLToggle = ({ value, onChange }: ACLToggleOptions) => {
    return <ButtonGroup className="me-2">
        <Button variant={value === 'private' ? 'success' : 'outline-success'} onClick={() => onChange('private')} title="Private"><FontAwesomeIcon icon={faLock} /></Button>
        <Button variant={value === 'public-read' ? 'success' : 'outline-success'} onClick={() => onChange('public-read')} title="Public"><FontAwesomeIcon icon={faLockOpen} /></Button>
    </ButtonGroup>
}

interface ACLToggleItemOptions {
    value: ItemACL;
    onChange: (acl: ItemACL) => void;
}
export const ACLToggleItem = ({ value, onChange }: ACLToggleItemOptions) => {
    return <ButtonGroup className="me-2">
        <Button variant={value === 'default' ? 'success' : 'outline-success'} onClick={() => onChange('default')} title="Default"><FontAwesomeIcon icon={faUnlock} /></Button>
        <Button variant={value === 'private' ? 'success' : 'outline-success'} onClick={() => onChange('private')} title="Private"><FontAwesomeIcon icon={faLock} /></Button>
        <Button variant={value === 'public-read' ? 'success' : 'outline-success'} onClick={() => onChange('public-read')} title="Public"><FontAwesomeIcon icon={faLockOpen} /></Button>
    </ButtonGroup>
}

export default ACLToggle;