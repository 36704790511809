import { HeadingLevel } from "@aws-amplify/ui-react";
import H from "../../../components/view/H";
import MetadataBadges from "../../../components/view/MetadataBadges";
import { Metadata } from "../../../state/metadatastore/types";

interface TaggedTitleOptions {
    title: string;
    level: HeadingLevel;
    tags: Metadata;
}
const TaggedTitle = ({ title, level, tags }: TaggedTitleOptions) => <>
    <H level={level}>{title}</H>
    <MetadataBadges table="item" metadata={tags}  />
</>

export default TaggedTitle
