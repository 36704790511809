import { Completion } from "@codemirror/autocomplete";
import { MetadataStoreData } from "../useMetadataStore";

export const metadataStoreGetLinkCompletions = (store: MetadataStoreData): Completion[] => Object.keys(store.pages)
    .filter(path => store.pages[path].title === undefined)
    .map(path => ({
        label: `[${store.pages[path].title}]`,
        type: "macro",
        apply: `[${store.pages[path].title}](/${path})`,
        detail: "Page"
    }));
