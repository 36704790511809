import {  DieExpression, parseDieExpressionRequired } from "./dice"
import { trim } from "./util"

export const parseDnd5eName = (input: string | undefined): string | undefined => {
    if (input === undefined) return undefined

    return trim(input)
}

// Creature sizes
export const Dnd5eCreatureSizes = ["tiny","small","medium","large","huge","gargantuan"] as const
export type Dnd5eCreatureSize = typeof Dnd5eCreatureSizes[number]
export const parseDnd5eCreatureSize = (input: string | undefined): Dnd5eCreatureSize | undefined => {
    if (input === undefined) return undefined

    const size =  trim(input.toLowerCase()) as Dnd5eCreatureSize
    if (Dnd5eCreatureSizes.indexOf(size) === -1) throw Error(`Unknown D&D 5e creature size: ${size}`)

    return size
}

// Creature types
export const Dnd5eCreatureTypes = ["aberration","beast","celestial","construct","dragon","elemental","fey","fiend","giant","humanoid","monstrocity","ooze","plant","undead"] as const
export type Dnd5eCreatureType = typeof Dnd5eCreatureTypes[number]
export const parseDnd5eCreatureType = (input: string | undefined): Dnd5eCreatureType | undefined => {
    if (input === undefined) return undefined
    
    const typ =  trim(input.toLowerCase()) as Dnd5eCreatureType
    if (Dnd5eCreatureTypes.indexOf(typ) === -1) throw Error(`Unknown D&D 5e creature type: ${typ}`)

    return typ
}

// Armor classes
export interface Dnd5eArmorClass {
    ac: number;
    label: string;
}
export const parseDnd5eArmorClass = (input: string | undefined): Dnd5eArmorClass | undefined => {
    if (input === undefined) return undefined

    const ac =  trim(input.toLowerCase())
    if (ac.search(/^\d+( |$)/) === -1) throw Error(`Expected AC as a number or a number followed by a label: '${ac}'`)

    return {
        ac: parseInt(ac.replace(/^(\d+)( |$)/, "$1")),
        label: ac.search(/^\d+ /) === 0 ? input.replace(/(^\d+ )$/, "") : ""
    }
}

// Speeds
export const parseDnd5eSpeed = (label: string, required: boolean, input: string | undefined): number => {
    if (input === undefined && required) throw Error(`Missing D&D 5e ${label} speed`)
    if (input === undefined) return 0

    const speed =  trim(input.toLowerCase())
    if (speed.search(/^\d+$/) === -1) throw Error(`D&D 5e ${label} speed is not valid: ${speed}`)

    return parseInt(speed, 10)
}

// Ability scores
export const Dnd5eAbilityScores = ["STR","DEX","CON","INT","WIS","CHA"] as const
export type Dnd5eAbilityScore = typeof Dnd5eAbilityScores[number]
export type Dnd5eAbilityScoreItem = { ability: Dnd5eAbilityScore, score: number, proficient: boolean }
export type AbilityScoreSet = {
    [key in Dnd5eAbilityScore]: Dnd5eAbilityScoreItem
}
const emptyAbilityScoreSet: AbilityScoreSet = {
    STR: { ability: "STR", score: 0, proficient: false },
    DEX: { ability: "DEX", score: 0, proficient: false },
    CON: { ability: "CON", score: 0, proficient: false },
    INT: { ability: "INT", score: 0, proficient: false },
    WIS: { ability: "WIS", score: 0, proficient: false },
    CHA: { ability: "CHA", score: 0, proficient: false },
}
const parseAbilityScore = (ability: Dnd5eAbilityScore, input: string): Dnd5eAbilityScoreItem => {
    const proficient = input.slice(0, 1) === "*"
    const score = input.slice(0, 1) === "*" ? parseInt(input.slice(1), 10) : parseInt(input, 10)

    return {
        ability,
        score,
        proficient
    }
}
export const parseDnd5eAbilityScoreSet = (input: string | string): AbilityScoreSet | undefined => {
    if (input === undefined) return undefined
    
    const scores = trim(input)
    if (scores.search(/^([*]?\d+( |$))*$/) === -1) throw Error(`Invalid ability score set: ${scores}`)

    const parts = scores.split(" ")
    if (parts.length !== 6) throw Error(`Invalid number of ability scores: ${parts.length}`)

    const parsedInput = Dnd5eAbilityScores.map((ability, index) => parseAbilityScore(ability, parts[index]))

    return parsedInput.reduce((a, b) => ({ ...a, [b.ability]: b }), emptyAbilityScoreSet)
}
export const getDnd5eAbilityScoreModifier = (score: number): number => {
    switch (score) {
        case 1: return -5;
        case 2: case 3: return -4;
        case 4: case 5: return -3;
        case 6: case 7: return -2;
        case 8: case 9: return -1;
        case 10: case 11: return +0;
        case 12: case 13: return +1;
        case 14: case 15: return +2;
        case 16: case 17: return +3;
        case 18: case 19: return +4;
        case 20: case 21: return +5;
        case 22: case 23: return +6;
        case 24: case 25: return +7;
        case 26: case 27: return +8;
        case 28: case 29: return +9;
        case 30: return +10;
        default: throw Error(`Invalid ability score: ${score}`);
    }
}
export const formatDnd5eAbilityScoreModifier = (modifier: number): string => {
    if (modifier > -1) return `+${modifier}`;

    return modifier.toString()
}

// Proficiencies
export const Dnd5eSkills = ["acrobatics", "animal handling", "arcana", "athletics", "deception", "history", "insight", "intimidation", "investigation", "medicine", "nature", "perception", "performance", "persuasion", "religion", "sleight of hand", "stealth", "survival"]
export type Dnd5eSkill = typeof Dnd5eSkills[number]
export const Dnd5eSkillAbility: { [key:Dnd5eSkill ]: Dnd5eAbilityScore } = {
    "acrobatics": "DEX",
    "animal handling": "WIS",
    "arcana": "INT",
    "athletics": "STR",
    "deception": "CHA",
    "history": "INT",
    "insight": "WIS",
    "intimidation": "CHA",
    "investigation": "INT",
    "medicine": "WIS",
    "nature": "INT",
    "perception": "WIS",
    "performance": "CHA",
    "persuasion": "CHA",
    "religion": "INT",
    "sleight of hand": "DEX",
    "stealth": "DEX",
    "survival": "WIS",
}
export const parseDnd5eSkills = (input: string | undefined): Dnd5eSkill[] => {
    if (input === undefined) return []

    const skills =  trim(input.toLowerCase())
    if (skills.search(new RegExp(`^((${Dnd5eSkills.join("|")})(,$))*`)) === -1) throw Error(`Invalid profiency list: ${skills}`)

    return skills.split(",") as Dnd5eSkill[]
}
export const calculateSkillModifier = (abilities: AbilityScoreSet, skill: Dnd5eSkill, proficient: boolean, proficiency: number): number => {
    return getDnd5eAbilityScoreModifier(abilities[Dnd5eSkillAbility[skill]].score) + (proficient ? proficiency : 0)
}

// Challenge ratings
export const Dnd5eChallengeRatings = ["0","1/8","1/4","1/2","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30"] as const
export type Dnd5eChallengeRating = typeof Dnd5eChallengeRatings[number]
export const parseDnd5eChallengeRating = (input: string | undefined): Dnd5eChallengeRating | undefined => {
    if (input === undefined) return undefined

    const cr =  trim(input.toLowerCase()) as Dnd5eChallengeRating
    if (Dnd5eChallengeRatings.indexOf(cr) === -1) throw Error(`Unknown D&D 5e challenge rating: ${cr}`)

    return cr
}
export const getDnd5eChallengeRatingXP = (cr: Dnd5eChallengeRating): string => {
    switch (cr) {
        case "0": return "10"
        case "1/8": return "25"
        case "1/4": return "50"
        case "1/2": return "100"
        case "1": return "200"
        case "2": return "450"
        case "3": return "700"
        case "4": return "1,100"
        case "5": return "1,800"
        case "6": return "2,300"
        case "7": return "2,900"
        case "8": return "3,900"
        case "9": return "5,000"
        case "10": return "5,900"
        case "11": return "7,200"
        case "12": return "8,400"
        case "13": return "10,000"
        case "14": return "11,500"
        case "15": return "13,000"
        case "16": return "15,000"
        case "17": return "18,000"
        case "18": return "20,000"
        case "19": return "22,000"
        case "20": return "25,000"
        case "21": return "33,000"
        case "22": return "41,000"
        case "23": return "50,000"
        case "24": return "62,000"
        case "25": return "75,000"
        case "26": return "90,000"
        case "27": return "105,000"
        case "28": return "120,000"
        case "29": return "135,000"
        case "30": return "155,000"
    }
}

// Proficiency modifieer
export const parseDnd5eProficiencyModifier = (input: string | undefined): number | undefined => {
    if (input === undefined) return undefined

    const pm =  trim(input.toLowerCase())
    if (pm.search(/^\+?\d+$/) === -1) throw Error(`Invalid D&D 5e proficiency modifier: ${pm}`)

    return parseInt(pm, 10)
}

// To hit
interface DndToHit {
    raw: string;
    parts: (number | Dnd5eAbilityScore | "PROF")[]
}
export const parseToHit = (input: string): DndToHit => ({
    raw: input,
    parts: input.split("+").map(trim).map(v => {
        if (v.search(/^\d+$/) === 0) return parseInt(v, 10)
        if (Dnd5eAbilityScores.indexOf(v as Dnd5eAbilityScore) !== -1) return v as Dnd5eAbilityScore
        if (v === "PROF" || v === "PROFICIENCY") return v as "PROF"
        if (v === "") return 0

        throw Error(`Unknown to-hit element: ${v}`)
    })
})
export const calculateToHit = (proficiency: number, abilities: AbilityScoreSet, hit: DndToHit): number => {
    return hit.parts.map(v => {
        if (typeof(v) === "number") return v
        if (v === "PROF") return proficiency
        if (abilities[v] !== undefined) return getDnd5eAbilityScoreModifier(abilities[v].score)

        return 0
    }).reduce((a, b) => a + b, 0)
}

// Powers
export interface BasicPower {
    type: "passive" | "reaction" | "action"
    name: string;
    description: string;
    legendaryActionPoints: number;
}
export interface AttackPower {
    type: "attack";
    name: string;
    range: string;
    hitlabel: string;
    description: string;
    hit: DndToHit;
    damage: DieExpression;
    legendaryActionPoints: number;
}
export type CreaturePower = BasicPower | AttackPower
export const parseBasicPower = (type: string, input: string): BasicPower => {
    if (type !== "passive" && type !== "reaction" && type !== "action") throw Error(`Unknown power type: ${type}`)

    const inputParts = trim(input).split(":")
    const legendaryActionPoints = input[0].search(/^\d+$/) === 0 ? parseInt(inputParts[0], 10): 0
    const remainingParts = inputParts[1].search(/^\d+$/) === 0 ? inputParts.slice(1) : inputParts.slice(0)

    return {
        type,
        name: trim(remainingParts[0]),
        description: trim(remainingParts.slice(1).join(":")),
        legendaryActionPoints
    }
}
export const parseAttackPower = (input: string): AttackPower => {
    const inputParts = input.split(":")

    if (inputParts.length < 6) throw Error("attack powers must have name:range:hit:hitlabel:damage:description and optionally start with a number of legendary action points")

    const legendaryActionPoints = inputParts[0].trim().search(/^\d+$/) === 0 ? parseInt(inputParts[0].trim(), 10) : 0

    const remainingParts = inputParts[0].trim().search(/^\d+$/) === 0 ? inputParts.slice(1) : inputParts
    if (remainingParts.length !== 6) throw Error(`attack powers must have 6 or 7 parts; this has ${remainingParts.length}`)

    const [
        name,
        range,
        tohit,
        hitlabel,
        damage,
        description
    ] = remainingParts

    return {
        type: "attack",
        name: trim(name),
        range: trim(range),
        hit: parseToHit(trim(tohit)),
        hitlabel: trim(hitlabel),
        damage: parseDieExpressionRequired(trim(damage)),
        description: trim(description),
        legendaryActionPoints
    }
}

export interface Dnd5eCreature {
    name?: string;
    size?: Dnd5eCreatureSize;
    type?: Dnd5eCreatureType;
    ac?: Dnd5eArmorClass;
    hitpoints?: DieExpression;
    walk?: number;
    climb?: number;
    swim?: number;
    fly?: number;
    abilities?: AbilityScoreSet;
    skills?: Dnd5eSkill[];
    proficiency?: number;
    damagevulnerabilities?: string;
    damageimmunities?: string;
    conditionimmunities?: string;
    damageresistances?: string;
    senses?: string;
    languages?: string;
    cr?: Dnd5eChallengeRating;
    powers: CreaturePower[];
    raw: string;
}
