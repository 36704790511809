import { HeadingLevel } from "@aws-amplify/ui-react";
import { faDiceD20 } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import H from "../../../components/view/H";
import { Markdown } from "../../../markdown/markdown";
import { RandomTableReference } from "../../../state/randomtables/types";
import { useRandomTableIndexRandomValues } from "../../../state/useRandomTables";

interface FunctionalRandomTableIndexOptions {
    title: string;
    level: HeadingLevel;
    references: RandomTableReference[];
}

const FunctionalRandomTableIndex = ({ title, level, references }: FunctionalRandomTableIndexOptions) => {
    const [randomValues, randomize] = useRandomTableIndexRandomValues(references);

    return <div>
        <H level={level}>{title}</H>
        <table className="table table-striped random-table-index">
            <thead>
                <tr>
                    <th>Table</th>
                    <th>Random results <FontAwesomeIcon icon={faDiceD20} style={{cursor:"pointer"}} onClick={randomize} /></th>
                </tr>
            </thead>
            <tbody>
                {randomValues.map((result, i) => <tr key={i}>
                    <td><a href={`/${result.path}#${result.id}`}>{result.title}</a></td>
                    <td><dl className="row">{result.results.map((v,i) => <React.Fragment key={i}>
                        <dt className="col-sm-12 col-md-6" style={{color:v.color}}><Markdown markdown={v.key} inlineMode={true} /></dt>
                        <dd className="col-sm-12 col-md-6">[{v.row+1}] <Markdown markdown={v.value} inlineMode={true} /></dd></React.Fragment>)}</dl></td>
                </tr>)}
            </tbody>
        </table>
    </div>;
}

export default FunctionalRandomTableIndex