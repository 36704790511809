import { BasicACL, DirPath } from "../../data/types";
import { RandomTableStore } from "../useRandomTables";
import { RandomTable } from "./types";

export const randomtablesAddPage = async (path: DirPath, acl: BasicACL, randomtables: RandomTable[], store: RandomTableStore, save: boolean=true) => {
    // main store is always updatable
    const data = { ...store.data, [path]: randomtables }

    // public store should only be updated if the page is public
    const publicData = store.publicData === null || (store.mode === "public" && acl === "private")
        ? store.publicData
        : { ...store.publicData, [path]: randomtables }

    store.setData(data, publicData, save)
}

