import { Metadata } from "../../state/metadatastore/types";
import { StaticType, WhereNode } from "./interpretWhere"

type ColumnType = string | string[]

type ColumnResolver = (metadata: Metadata) => ColumnType;
type ClauseResolver = (metadata: Metadata) => boolean;

const clauseColumn = (column: string): ColumnResolver => (metadata: Metadata) => metadata[column]

const clauseEqual = (columnFn: ColumnResolver, value: StaticType): ClauseResolver => (metadata: Metadata) => {
    const column = columnFn(metadata);

    if (Array.isArray(column)) {
        if (value === undefined) return column.length === 0;
        return column.indexOf(value.toString()) !== -1;
    }

    return column === value;
}

const clauseUnequal = (columnFn: ColumnResolver, value: StaticType): ClauseResolver => (metadata: Metadata) => {
    const column = columnFn(metadata);

    if (Array.isArray(column)) {
        if (value === undefined) return column.length !== 0;
        return column.indexOf(value.toString()) === -1;
    }

    return column !== value;
}

const clauseAnd = (clauseFns: ClauseResolver[]): ClauseResolver => (metadata: Metadata) => {
    return clauseFns.map(fn => fn(metadata)).reduce((a, b) => a && b, true)
}

const clauseOr = (clauseFns: ClauseResolver[]): ClauseResolver => (metadata: Metadata) => {
    return clauseFns.map(fn => fn(metadata)).reduce((a, b) => a || b, false)
}

const clauseLike = (columnFn: ColumnResolver, regex: RegExp): ClauseResolver => (metadata: Metadata) => {
    const column = columnFn(metadata);

    if (Array.isArray(column)) return column.map(s => s.search(regex) !== -1).filter(b => b).length !== 0;
    if (column === undefined) return false

    return column.search(regex) !== -1;
}

const clauseNotLike = (columnFn: ColumnResolver, regex: RegExp): ClauseResolver => (metadata: Metadata) => {
    const column = columnFn(metadata);

    if (Array.isArray(column)) return column.map(s => s.search(regex) !== -1).filter(b => b).length === 0;
    if (column === undefined) return true

    return column.search(regex) === -1;
}

const functionalWhere = (where: WhereNode): ClauseResolver => {
    if (where.type === "column" || where.type === "regex" || where.type === "static") {
        throw new Error("The where clause must be a boolean expression")
    }

    switch (where.type) {
        case "any": return () => true
        case "!=": return clauseUnequal(clauseColumn(where.column.name), where.value.value);
        case "=": return clauseEqual(clauseColumn(where.column.name), where.value.value);
        case "AND": return clauseAnd(where.clauses.map(functionalWhere));
        case "LIKE": return clauseLike(clauseColumn(where.column.name), where.regex.regex);
        case "NOT LIKE": return clauseNotLike(clauseColumn(where.column.name), where.regex.regex);
        case "OR": return clauseOr(where.clauses.map(functionalWhere));
    }
}

export default functionalWhere
