import React from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { markdown, markdownLanguage } from '@codemirror/lang-markdown';
import { languages } from '@codemirror/language-data';
import { useCompletions, usePageState } from '../../state/usePageState';
import { useShallow } from 'zustand/react/shallow';

const MarkdownEditor = () => {
    const completionOverride = useCompletions();
    const { currentMarkdown, setMarkdown, setEditor } = usePageState(useShallow(state => ({ currentMarkdown: state.markdown, setMarkdown: state.setMarkdown, setEditor: state.setEditor })))

    return <CodeMirror
        basicSetup={{ lineNumbers: false, foldGutter: false }}
        value={currentMarkdown}
        onChange={setMarkdown}
        extensions={[markdown({ base: markdownLanguage, codeLanguages: languages }), completionOverride]}
        onCreateEditor={setEditor} />;
}

export default MarkdownEditor;
