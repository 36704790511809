import { RandomTable, RandomTableResult } from "./types";

interface RandomRow {
    title: string
    row: number,
    roll: number,
    rollIndex: number,
    color: string
}
const getRandomRows = (data: RandomTable): RandomRow[] => {
    if (data.rollcolumns === undefined) {
        const dieValue = Math.floor(Math.random() * data.length)

        return [{
            title: `d${data.length}`,
            row: dieValue,
            roll: dieValue + 1,
            rollIndex: 0,
            color: "#000000"
        }]
    }

    return data.rollcolumns.map((rollcolumn, index) => {
        const dieValue = Math.floor(Math.random() * rollcolumn.die) + 1

        return {
            title: rollcolumn.title,
            row: rollcolumn.ranges.findIndex(range => range.start !== -1 && range.start <= dieValue && dieValue < range.end),
            roll: dieValue,
            rollIndex: index,
            color: rollcolumn.color
        }
    })
}

export const randomtablesRandomValues = (randomtable: RandomTable): RandomTableResult[] => {
    const values = [] as RandomTableResult[];

    if (randomtable.boundsets) {
        const randomRows = getRandomRows(randomtable)
        for (let j=0; j<randomtable.sets.length; j++) {
            for (let i=0; i<randomRows.length; i++) {
                values.push({
                    row: randomRows[i].row,
                    roll: randomRows[i].roll,
                    column: j,
                    rollIndex: randomRows[i].rollIndex,
                    key: randomRows.length === 1 ? randomtable.sets[j].title : `${randomtable.sets[j].title} (${randomRows[i].title})`,
                    value: randomtable.sets[j].options[randomRows[i].row],
                    color: randomRows[i].color
                });
            }
        }
    } else {
        for (let j=0; j<randomtable.sets.length; j++) {
            const randomRows = getRandomRows(randomtable)
            for (let i=0; i<randomRows.length; i++) {
                values.push({
                    row: randomRows[i].row,
                    roll: randomRows[i].roll,
                    column: j,
                    rollIndex: randomRows[i].rollIndex,
                    key: randomRows.length === 1 ? randomtable.sets[j].title : `${randomtable.sets[j].title} (${randomRows[i].title})`,
                    value: randomtable.sets[j].options[randomRows[i].row],
                    color: randomRows[i].color
                });
            }
        }
    }

    return values;
}
