import { PageMediaStore } from '../usePageMedia';
import { FilePath } from '../../data/types';
import { putObjectACL } from '../../data/s3';

export const pagemmediaUnremoveItem = async (path: FilePath, store: PageMediaStore, save: boolean=true): Promise<void> => {
    if (store.status !== "loaded") throw Error("Files are not initialized")

    const mediaItem = store.items.find(item => item.path === path)
    if (mediaItem === undefined) throw Error("Specified item does not exist")
    if (mediaItem.status !== "deleted") throw Error("Can't readd item that isn't deleted")

    await putObjectACL(`public/${mediaItem.path}`, mediaItem.acl === "default" ? store.acl : mediaItem.acl)
    if (mediaItem.thumbPath) await putObjectACL(`public/${mediaItem.thumbPath}`, mediaItem.acl === "default" ? store.acl : mediaItem.acl)

    await store.updateItem(path, { status: "loaded" }, save)
}
