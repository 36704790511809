import React, { memo } from 'react';
import { Navbar, Container, Nav, ButtonGroup, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ACLToggle from './edit/ACLToggle';
import SelectTemplate from './menu/SelectTemplate';
import { useIsLoggedIn, useUser } from '../state/useUser';
import { usePageState } from '../state/usePageState';
import { usePageMarkdown } from '../state/usePageMarkdown';
import { useShallow } from 'zustand/react/shallow';
import './Menu.css'

const LoadedButtons = () => {
    const { exists, initializeFromTemplate } = usePageMarkdown(useShallow(state => ({
        exists: state.exists,
        initializeFromTemplate: state.initializeFromTemplate
    })))
    const { edit, deletePage, showDialog } = usePageState(useShallow(state => ({
        edit: state.edit,
        deletePage: state.delete,
        showDialog: state.showDialog,
    })))

    return <Nav className="me-auto">
        <ButtonGroup className="me-2" aria-label="Actions">
            <Button variant="success" onClick={edit}>Edit</Button>
        </ButtonGroup>
        {!exists && <SelectTemplate className="me-2" onChange={initializeFromTemplate} />}
        {exists && <Button variant="danger" className="me-2" onClick={() => window.confirm("Are you sure you want to delete this page? This can not be reversed.") && deletePage()}>Delete</Button>}
        <Button onClick={() => showDialog('print')}>Print</Button>
    </Nav>
}

const EditingButtons = () => {
    const { cancel, save, changed, setACL } = usePageState(useShallow(state => ({
        cancel: state.cancel,
        save: state.save,
        changed: state.changed,
        setACL: state.setACL,
    })))
    const acl = usePageMarkdown(state => state.acl)

    return <Nav className="me-auto">
        <ButtonGroup className="me-2" aria-label="Actions">
            <Button variant="outline-danger" className="me-0" onClick={cancel}>Cancel</Button>
            <Button variant="success" className="me-0" onClick={save} disabled={!changed}>Save</Button>
        </ButtonGroup>
        <ACLToggle value={acl} onChange={setACL} />
    </Nav>
}

const LoggedInMenu = () => {
    const {
        status,
    } = usePageState(useShallow(state => ({
        status: state.status,
    })))
    const { user, signOut } = useUser(useShallow(state => ({ user: state.user, signOut: state.signOut })))

    return <Navbar id="topmenu" bg="dark" variant="dark" fixed="top">
        <Container>
            <Navbar.Brand href="/">RPG Notes</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    {status === "loaded" && <LoadedButtons />}
                    {status === "editable" && <EditingButtons />}
                </Nav>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
                <Navbar.Text className="me-3">Signed in as: {user && user.getUsername()}</Navbar.Text>
                <Button onClick={signOut}>Sign out</Button>
            </Navbar.Collapse>
        </Container>
    </Navbar>;
}


const LoggedOutMenu = () => {
    const showDialog = usePageState(state => state.showDialog)

    return <Navbar id="topmenu" bg="dark" variant="dark" fixed="top">
        <Container>
            <Navbar.Brand href="/" onClick={e => { window.history.pushState({}, "", "/"); e.preventDefault(); }}>RPG Notes</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav"></Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
                <Button onClick={() => showDialog('signin')}>Sign in</Button>
            </Navbar.Collapse>
        </Container>
    </Navbar>;
}


const Menu = memo(() => {
    const isLoggedIn = useIsLoggedIn()

    if (!isLoggedIn) return <LoggedOutMenu />

    return <LoggedInMenu />
});

export default Menu;