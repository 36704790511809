import React from 'react'
import { AbilityScoreSet, Dnd5eChallengeRating, calculateSkillModifier, getDnd5eChallengeRatingXP } from '../../../dnd'
import DndPropertyLine from './DndPropertyLine'
import DndSavingThrows from './DndSavingThrows'
import DndSkills from './DndSkills'

interface DndAdvancedPropertiesOptions {
    abilities?: AbilityScoreSet
    skills?: string[]
    damagevulnerabilities?: string
    damageimmunities?: string
    damageresistances?: string
    conditionimmunities?: string
    senses?: string
    languages?: string
    cr?: Dnd5eChallengeRating
    proficiency?: number
}

const DndAdvancedProperties = ({ abilities, skills, damagevulnerabilities, damageimmunities, damageresistances, conditionimmunities, senses, languages, cr, proficiency }: DndAdvancedPropertiesOptions) => {
    if (!(abilities || (skills && skills.length) || damagevulnerabilities || damageimmunities || damageresistances || conditionimmunities || senses || languages || cr || proficiency)) return null

    let finalSenses = []
    if (abilities && skills && proficiency) finalSenses.push('passive Perception ' + (8 + calculateSkillModifier(abilities, "perception", skills.indexOf("perception") !== -1, proficiency)))
    if (senses && senses.length) finalSenses.push(senses)

    return <div className="block advanced-properties">
        <DndSavingThrows abilities={abilities} proficiency={proficiency} />
        <DndSkills proficiency={proficiency} abilities={abilities} skills={skills} />
        <DndPropertyLine label="Damage Vulnerabilities" value={damagevulnerabilities} />
        <DndPropertyLine label="Damage Immunities" value={damageimmunities} />
        <DndPropertyLine label="Damage Resistances" value={damageresistances} />
        <DndPropertyLine label="Condition Immunities" value={conditionimmunities} />
        <DndPropertyLine label="Senses" value={finalSenses.join(", ")} />
        <DndPropertyLine label="Languages" value={languages} />
        {cr ? <DndPropertyLine label="Challenge">{cr} ({getDnd5eChallengeRatingXP(cr)} XP)</DndPropertyLine> : null}
        {proficiency ? <DndPropertyLine label="Proficiency Bonus">+{proficiency}</DndPropertyLine> : null}
    </div>
}

export default DndAdvancedProperties