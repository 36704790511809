import { EditorView } from "codemirror"
import { IDArray, TableOfContentsTiered } from "./types"
import { numberRE } from "./pageGoToHeading";

function hasWebHideClass(el: Element | null): boolean {
    while (el) {
        if (el.classList.contains("web-hide")) {
            return true;
        }
        el = el.parentElement;
    }
    return false;
}

export function isElementInViewport(el: Element | null): boolean {
    if (el === null || hasWebHideClass(el)) return false;

    const rect = el.getBoundingClientRect();
    const vWidth = window.innerWidth || document.documentElement?.clientWidth;
    const vHeight = window.innerHeight || document.documentElement?.clientHeight;
    const efp = (x: number, y: number) => document.elementFromPoint(x, y);

    if (!vWidth || !vHeight) {
      return false;
    }

    return (
      rect.right >= 0 &&
      rect.bottom >= 0 &&
      rect.left <= vWidth &&
      rect.top <= vHeight &&
      (el.contains(efp(rect.left, rect.top)) ||
        el.contains(efp(rect.right, rect.top)) ||
        el.contains(efp(rect.right, rect.bottom)) ||
        el.contains(efp(rect.left, rect.bottom)))
    );
}

export function isIDInViewport(editor: EditorView, id: number): boolean {
    // domAtPos - ignore "domless" lines, use isElementInViewport for the rest
    const dom = editor.domAtPos(id)
    if (!(dom as any).precise) return false;

    return isElementInViewport(dom.node.parentElement)

    // gets blocks at the height of the top and bottom of the viewport
    // const topBlock = editor.lineBlockAtHeight(window.scrollY)
    // const bottomBlock = editor.lineBlockAtHeight(window.scrollY + window.innerHeight)
    // console.log(topBlock.from <= id && id <= bottomBlock.to, topBlock.from, id, bottomBlock.to)
    // return topBlock.from <= id && id <= bottomBlock.to

    // using visible blocks (returns items after the ones in the actual viewport)
    // const visibleBlock = editor.viewportLineBlocks
    // return visibleBlock[0].from <= id && id <= visibleBlock[visibleBlock.length - 1].to

    // with lineBlockAt
    // const block = editor.lineBlockAt(id)
    // return window.scrollY <= block.top && block.top <= window.scrollY + window.innerHeight
}

export const pageGetVisibleIds = (editor: EditorView | null, tocTiered: TableOfContentsTiered[]): IDArray => tocTiered.map((item): IDArray => {
    if (item.id === undefined) return []

    let id = typeof(item.id) === "string" && item.id.search(numberRE) === 0 ? parseInt(item.id, 10) : item.id

    const visible = editor && typeof(id) === "number"
        ? isIDInViewport(editor, id)
        : isElementInViewport(document.getElementById(id.toString()))

    if (visible) {
        return [id, ...pageGetVisibleIds(editor, item.children)]
    }

    return pageGetVisibleIds(editor, item.children)
}).flat().sort()
