import { putObjectACL } from "../../data/s3";
import { FilePath } from "../../data/types";
import { PageMediaStore } from "../usePageMedia";
import { ItemACL } from "./types";

export const pagemediaSetItemACL = async (path: FilePath, acl: ItemACL, store: PageMediaStore) => {
    if (store.status !== "loaded") throw Error("Files are not initialized")

    const actualACL = acl === store.acl ? "default" : acl

    const mediaItem = store.items.find(item => item.path === path)
    if (mediaItem === undefined) throw Error("Specified item does not exist")
    if (mediaItem.status !== "loaded") throw Error("Can't change ACL of incomplete item")

    await putObjectACL(`public/${mediaItem.path}`, actualACL === "default" ? store.acl : actualACL)
    if (mediaItem.thumbPath) await putObjectACL(`public/${mediaItem.thumbPath}`, actualACL === "default" ? store.acl : actualACL)

    store.updateItem(path, { acl: actualACL })
}