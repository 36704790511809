import { BlockNode } from "../ast"
import { BlockParserFunctions, ParseAllBlockFunction, ParseAllInlineFunction, PeekAllBlockFunction } from "../parser"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"

const isNotUnorderedListItemInjected = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number): boolean => {
    if (index === 0) return false
    if (range.markdown.slice(0, 2) === "  ") return false

    return true
}

const processUnorderedListItemRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => ({
    type: "block",
    name: "listitem",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    children: parseBlock(ranges.removePrefix(2))
})

export const UnorderedListItemParserFunctions: BlockParserFunctions = {
    name: "unorderedlistitem",
    peek: (range, index, obj, ancestors) => {
        if (ancestors[ancestors.length - 1] !== "unorderedlist") return false
        
        return ["* ", "- "].indexOf(range.markdown.slice(0, 2)) !== -1
    },
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotUnorderedListItemInjected(peekAll),
        processUnorderedListItemRangesInjected(parseBlocks, parseInline),
        "Expected start of unordered list item"
    )
}
