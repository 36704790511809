import React from "react"

export interface BaseNode {
    type: string
    start: number
    end: number
    meta: { [key: string]: string }
}

export interface InlineNode extends BaseNode {
    type: "inline"
    name: string
    value: string
    children: InlineNode[]
}
export type InlineNodeType = InlineNode["type"]

export interface BlockNode extends BaseNode {
    type: "block"
    name: string
    children: Node[]
}
export type BlockNodeType = BlockNode["type"]

export type Node = InlineNode | BlockNode
export type NodeType = Node["type"]

export interface MarkdownNode extends BaseNode {
    type: "markdown"
    children: BlockNode[]
}

export interface ContextLine {
    line: number
    text: string
}
export interface MarkdownError {
    type: "error"
    message: string
    index: number
    line: number
    lineIndex: number
    context: ContextLine[]
}

export type AST = MarkdownNode | MarkdownError

export type NodeWithAncestors<K> = Extract<Node, { type: K }> & { indexes: number[], ancestors: string[], className?: string, style?: React.CSSProperties }
export type NodeRendererByType<K> = (item: NodeWithAncestors<K>) => JSX.Element | null
export type NodeTypeRenderers<K> = {
    [K: string]: NodeRendererByType<K>
}
export type NodeRenderers = { [K in NodeType]: NodeTypeRenderers<K> }
export const getRenderer = <K extends NodeType>(renderers: NodeRenderers, type: K, name: string): NodeRendererByType<K> => {
    const typeRenders = renderers[type]

    if (!typeRenders[name]) {
        return typeRenders.norenderer
    }

    return typeRenders[name]
}

export type TransformInlineFunction = (node: InlineNode) => InlineNode | null
export type TransformBlockFunction = (node: BlockNode) => BlockNode | null
export interface Transformers {
    inline: TransformInlineFunction[],
    block: TransformBlockFunction[]
}
export const mergeTransformers = (transformers: Transformers): [TransformInlineFunction, TransformBlockFunction] => {
    return [
        transformers.inline.length === 0
            ? (n: InlineNode) => n
            : (n: InlineNode) => {
                for (let transformer of transformers.inline) {
                    const newNode = transformer(n)
                    if (newNode === null) return null
                    n = newNode
                }
                
                return n
            },
        transformers.block.length === 0
            ? (n: BlockNode) => n
            : (n: BlockNode) => {
                for (let transformer of transformers.block) {
                    const newNode = transformer(n)
                    if (newNode === null) return null
                    n = newNode
                }
                
                return n
            }
    ]
}

export type GetStylesFn = (node: Node, indexes: number[], ancestors: string[]) => React.CSSProperties | undefined
export type GetClassNameFn = (node: Node, indexes: number[], ancestors: string[]) => string | undefined

export interface RendererContext {
    getStyles: GetStylesFn
    getClassName: GetClassNameFn
    renderers: NodeRenderers
}