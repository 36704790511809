import React from 'react'
import { DieExpression } from '../../../dice'
import { Dnd5eArmorClass, AbilityScoreSet } from '../../../dnd'
import DndDieExpression from './DndDieExpression'
import DndPropertyLine from './DndPropertyLine'
import { DndAC } from './DndAC'

interface DndBasicPropertiesOptions {
    ac?: Dnd5eArmorClass
    abilities?: AbilityScoreSet
    proficiency?: number
    hitpoints?: DieExpression
    walk?: number
    climb?: number
    swim?: number
    fly?: number
}

const DndBasicProperties = ({ ac, abilities, proficiency, hitpoints, walk, climb, swim, fly }: DndBasicPropertiesOptions) => {
    if (!(ac || abilities || proficiency || hitpoints || walk || climb || swim || fly)) return null

    const speed = []
    if (walk) speed.push(`${walk}ft.`)
    if (climb) speed.push(`${climb}ft. climbing`)
    if (swim) speed.push(`${swim}ft. swimming`)
    if (fly) speed.push(`${fly}ft. flying`)

    return <div className="block basic-properties">
        <DndAC ac={ac} />
        {hitpoints ? <DndPropertyLine label="Hit Points">
            <DndDieExpression
                abilities={abilities}
                proficiency={proficiency}
                expression={hitpoints}
                showAverage={true}
            />
        </DndPropertyLine> : null}
        {walk || climb || swim || fly ? <DndPropertyLine label="Speed">{speed.join(", ")}</DndPropertyLine> : null}
    </div>
}

export default DndBasicProperties