import { HeadingLevel } from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiceD20 } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import H from "../../../components/view/H";
import { Markdown } from "../../../markdown/markdown";
import { RandomTable, RandomTableResult } from "../../../state/randomtables/types";
import { useRandomTableRandomValues } from "../../../state/useRandomTables";

const getOptionStyle = (row: number, column: number, randomValues: RandomTableResult[]): React.CSSProperties => ({
    fontWeight: randomValues.findIndex(v => v.row === row && v.column === column) !== -1
        ? "bold"
        : "normal"
})

interface DieIconOptions {
    onClick?: () => void,
    style?: React.CSSProperties
}
const DieIcon = ({ onClick, style={} }: DieIconOptions) => {
    return <FontAwesomeIcon icon={faDiceD20} style={{...style, cursor:onClick === undefined ? "auto" : "pointer"}} onClick={onClick} />
}

interface DiceIconsOptions {
    row: number
    column: number
    randomValues: RandomTableResult[]
    randomize: (rollIndex?: number, column?: number) => void
}
const DiceIcons = ({ row, column, randomValues, randomize }: DiceIconsOptions) => {
    return <>{randomValues.filter(v => v.row === row && v.column === column).map((v, index) => <DieIcon key={index} style={{color: v.color, marginRight:5}} onClick={() => randomize(v.rollIndex, v.column)} />)}</>
}

interface FunctionalRandomTableOptions {
    data: RandomTable;
    level: HeadingLevel;
}
const FunctionalRandomTable = ({ data, level }: FunctionalRandomTableOptions) => {
    const [randomValues, randomize] = useRandomTableRandomValues(data);

    return <div>
        <H level={level}>{data.title}</H>
        <dl className="row">{randomValues.map((v,i) => <React.Fragment key={i}>
            <dt key={i} style={{color:v.color}} className="col-xs-12 col-sm-6 col-md-4">{v.key}</dt>
            <dd className="col-xs-12 col-sm-6 col-md-8" style={{color:v.color}}>
                [{v.roll}]&nbsp;
                <Markdown markdown={v.value} inlineMode={true} />
                <DieIcon style={{color: v.color}} onClick={() => randomize(v.rollIndex, v.column)} />
            </dd>
        </React.Fragment>)}</dl>
        <table className="table table-striped random-table">
            <thead>
                <tr>
                    {data.rollcolumns === undefined && <th>d{data.length} <DieIcon onClick={() => randomize(0)} /></th>}
                    {data.rollcolumns && data.rollcolumns.map((rollcolumn, index) => <th key={index} style={{color: rollcolumn.color}}><Markdown markdown={rollcolumn.title} inlineMode={true} /> <DieIcon onClick={() => randomize(index)} /></th>)}
                    {data.sets.map((set, i) => <th key={i}>{set.title}</th>)}
                </tr>
            </thead>
            <tbody>
                {Array.from(Array(data.length).keys()).map(i => <tr key={i}>
                    {data.rollcolumns === undefined && <td>{i+1}</td>}
                    {data.rollcolumns && data.rollcolumns.map((rollcolumn, index) => <td key={index} style={{color: rollcolumn.color}}>{rollcolumn.ranges[i].raw}</td>)}
                    {data.sets.map((set, j) => <td key={j} style={getOptionStyle(i, j, randomValues)}><Markdown markdown={set.options[i]} inlineMode={true} /> <DiceIcons row={i} column={j} randomValues={randomValues} randomize={randomize} /></td>)}
                </tr>)}
            </tbody>
        </table>
    </div>
}

export default FunctionalRandomTable
