import React from 'react'
import { Dnd5eCreatureBlock } from "./5ecreature"
import { QueryBlock } from "./query"
import { RandomTableBlock } from "./randomTable"
import { RandomTableIndexBlock } from "./randomTableIndex"
import { RandomTableLinksBlock } from "./randomTableLinks"
import { SnippetBlock } from "./snippet"
import { TagValueBlock } from "./tagValues"
import { TaggedTitleBlock } from "./taggedTitle"
import { Element } from 'react-markdown/lib/ast-to-react'
import { Completion, CompletionContext, CompletionResult } from '@codemirror/autocomplete'
import { NeutralCreatureBlock } from './neutralcreature'
import { BlockStateError } from './_utils'
import { BlockNode } from '../../markdown/ast'
import { CompletionDatabase } from '../../state/page/types'

const macroBlocks = [
    Dnd5eCreatureBlock,
    NeutralCreatureBlock,
    QueryBlock,
    RandomTableBlock,
    RandomTableIndexBlock,
    RandomTableLinksBlock,
    SnippetBlock,
    TaggedTitleBlock,
    TagValueBlock,
]

export const parseMacro = (markdown: string) => {
    for (let macroBlock of macroBlocks) {
        try {
            const matched = macroBlock.parse(markdown)
            if (matched.length) return matched[0]
        } catch (err: any) {
            console.error(err)
            return { status:"error", error:`Error parsing ${macroBlock.name} macro: ${err.toString()}` } as BlockStateError
        }
    }

    return { status:"error", error:`Unrecognized macro: ${markdown.split("\n").shift()}` } as BlockStateError
}

export const RenderMacroAST = (node: BlockNode): JSX.Element => {
    for (let macroBlock of macroBlocks) {
        if (macroBlock.name === node.meta.macro) {
            const el = macroBlock.renderFromAST(node.meta)
            if (el) return el
        }
    }

    return <p style={{ fontWeight:"bold", color:"red" }}>Unknown macro type: {node.meta.macro}</p>
}

export const interceptBlock = (node: Element, children: React.ReactNode & React.ReactNode[]) => {
    for (let i=0; i<macroBlocks.length; i++) {
        const replacement = macroBlocks[i].getReact(node)
        if (replacement !== null) return replacement
    }

    switch (node.tagName) {
        case "pre": return <pre>{children}</pre>
    }

    return <p><strong>Attempted to intercept unhandled tag type: {node.tagName}</strong></p>
}

export const renderMarkdownBlocks = (markdown: string): string => {
    let newMarkdown = markdown.replace(/^---\n(.*?)\n---\n/s, '');
    
    for (let i=0; i<macroBlocks.length; i++) {
        newMarkdown = macroBlocks[i].render(newMarkdown)
    }

    return newMarkdown;
}

export const getCompletions = (macroName: string, context: CompletionContext, text: string[], startIndex: number, currentIndex: number, completionDatabase: CompletionDatabase): CompletionResult | null => {
    for (let i=0; i<macroBlocks.length; i++) {
        if (macroName === macroBlocks[i].name) return macroBlocks[i].getCompletions(context, text, startIndex, currentIndex, completionDatabase)
    }
    
    return  null
}

export const getMacroTemplateCompletions = (): Completion[] => {
    return macroBlocks.sort((a, b) => a.name.localeCompare(b.name)).map(block => ({
        label: `[[${block.name}]]`,
        type: "macro",
        apply: block.template
    }))
}