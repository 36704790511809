import { DirPath } from "../../data/types"
import { PageMediaStore } from "../usePageMedia"

export const pagemediaSetPage = async (path: DirPath, store: PageMediaStore) => {
    if (path === store.path) return

    store.setStatusUninitialized(path)

    await store.load(false, path)
}
