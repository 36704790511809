import { Nominal } from "./types";

type DieTokenNumber = { type:"number", value:number, raw:string, start:number, end:number }
type DieTokenAdd = { type:"add", start:number, end:number }
type DieTokenSubtract = { type:"subtract", start:number, end:number }
type DieTokenMultiply = { type:"multiply", start:number, end:number }
type DieTokenDie = { type:"die", start:number, end:number }
type DieTokenVariable = { type:"variable", name:string, start:number, end:number }
type DieTokenLabel = { type:"label", label:string, start:number, end:number }
type DieTokenSpace = { type:"space" }
type DieToken = DieTokenNumber | DieTokenAdd | DieTokenSubtract | DieTokenMultiply | DieTokenDie | DieTokenVariable | DieTokenLabel

type DieExpressionNull = {
    type:"null"
}
type DieExpressionDie = {
    type:"die",
    multiplier:DieTokenNumber | DieTokenVariable,
    die:DieTokenNumber | DieTokenVariable
}
type DieExpressionAdd = {
    type:"add",
    left:DieExpressionSubtract | DieExpressionAdd | DieExpressionMultiply | DieExpressionDie | DieTokenNumber | DieTokenVariable | DieExpressionLabeled,
    right:DieExpressionDie | DieTokenNumber | DieTokenVariable | DieExpressionMultiply | DieExpressionLabeled
}
type DieExpressionSubtract = {
    type:"subtract",
    left:DieExpressionAdd | DieExpressionSubtract | DieExpressionMultiply | DieExpressionDie | DieTokenNumber | DieTokenVariable,
    right:DieExpressionDie | DieTokenNumber | DieTokenVariable | DieExpressionMultiply
}
type DieExpressionMultiply = {
    type:"multiply",
    left:DieExpressionMultiply | DieExpressionDie | DieTokenNumber | DieTokenVariable,
    right:DieExpressionDie | DieTokenNumber | DieTokenVariable
}
type DieExpressionLabeled = {
    type:"labeled",
    label:DieTokenLabel,
    expression:DieTokenNumber | DieTokenVariable | DieExpressionDie | DieExpressionMultiply | DieExpressionAdd | DieExpressionSubtract
}
type DieExpressionAST = DieTokenNumber | DieTokenVariable | DieExpressionNull | DieExpressionDie | DieExpressionAdd | DieExpressionSubtract | DieExpressionMultiply | DieExpressionLabeled

const parseDieExpressionCharacter = (input: string, index: number): DieTokenNumber | DieTokenAdd | DieTokenSubtract | DieTokenMultiply | DieTokenVariable | DieTokenLabel | DieTokenSpace => {
    if (input === "+") return { type:"add", start:index, end:index }
    if (input === "-") return { type:"add", start:index, end:index }
    if (input.search(/^\d+$/) === 0) return { type:"number", value:parseInt(input, 10), raw:input, start:index, end:index }
    if (input.search(/^[a-z]+$/) === 0) return { type:"label", label:input, start:index, end:index }
    if (input.search(/^[A-Z]+$/) === 0) return { type:"variable", name:input, start:index, end:index }
    if (input.search(/^\s+$/) === 0) return { type:"space" }

    throw Error(`Unregognized die expression part: ${input}`)
}
const mergeDieTokenNumber = (tokens: DieToken[], token: DieTokenNumber): DieToken[] => {
    if (tokens.length === 0) return [token]

    const lastToken = tokens[tokens.length-1]
    if (lastToken.type !== "number") return [...tokens, token]

    return [
        ...tokens.slice(0, tokens.length-1),
        {
            type:"number",
            value:parseInt(lastToken.raw + token.raw, 10),
            raw:lastToken.raw + token.raw,
            start:lastToken.start, end:lastToken.end + 1
        }
    ]
}
const mergeDieTokenVariable = (tokens: DieToken[], token: DieTokenVariable): DieToken[] => {
    if (tokens.length === 0) return [token]

    const lastToken = tokens[tokens.length-1]
    if (lastToken.type !== "variable") return [...tokens, token]

    return [
        ...tokens.slice(0, tokens.length-1),
        { type:"variable", name:lastToken.name + token.name, start:lastToken.start, end:lastToken.end + 1 }
    ]
}
const mergeDieTokenLabel = (tokens: DieToken[], token: DieTokenLabel): DieToken[] => {
    if (tokens.length === 0) return [token]

    const lastToken = tokens[tokens.length-1]
    if (lastToken.type !== "label") {
        if (token.label === "d") return [...tokens, { type:"die", start:token.start, end:token.end }]

        return [...tokens, token]
    }

    return [
        ...tokens.slice(0, tokens.length-1),
        { type:"variable", name:lastToken.label + token.label, start:lastToken.start, end:lastToken.end + 1 }
    ]
}
const parseDieExpressionToTokens = (input: string): DieToken[] => input
    .split("")
    .map(parseDieExpressionCharacter)
    .reduce((tokens, token) => {
        switch (token.type) {
            case "number": return mergeDieTokenNumber(tokens, token)
            case "variable": return mergeDieTokenVariable(tokens, token)
            case "label": return mergeDieTokenLabel(tokens, token)
            case "add": case "subtract": case "multiply": return [...tokens, token]
            case "space": return tokens
        }

        return tokens
    }, [] as DieToken[])
const eatDieTokenNumber = (ast: DieExpressionAST, tokens: DieToken[], index: number, token: DieTokenNumber): [DieExpressionAST, number] => {
    if (ast.type === "null") return [token, index + 1];

    throw Error(`Unexpected number at ${token.start}:${token.end}`)
}
const eatDieTokenVariable = (ast: DieExpressionAST, tokens: DieToken[], index: number, token: DieTokenVariable): [DieExpressionAST, number] => {
    if (ast.type === "null") return [token, index + 1];

    throw Error(`Unexpected variable at ${token.start}:${token.end}`)
}
const eatDieTokenAdd = (ast: DieExpressionAST, tokens: DieToken[], index: number, token: DieTokenAdd): [DieExpressionAST, number] => {
    // add should always appear before a number or variable name
    const nextToken = tokens[index + 1]
    if (nextToken === undefined) throw Error(`Expected a number or variable at ${token.end + 1}`)
    if (nextToken.type !== "number" && nextToken.type !== "variable") throw Error(`Expected a number or variable at ${nextToken.start}`)

    if (ast.type === "null") return [ast, index + 1] // ignore a + at the start of a string

    return [{ type:"add", left:ast, right: nextToken}, index + 2]
}
const eatDieTokenSubtract = (ast: DieExpressionAST, tokens: DieToken[], index: number, token: DieTokenSubtract): [DieExpressionAST, number] => {
    const nextToken = tokens[index + 1]
    if (nextToken === undefined) throw Error(`Expected a number or variable at ${token.end + 1}`)
    if (nextToken.type !== "number" && nextToken.type !== "variable") throw Error(`Expected a number or variable at ${nextToken.start}`)

    // skip a + at the start of a string
    if (ast.type === "null") {
        switch (nextToken.type) {
            case "number": return [{ type:"number", value:-nextToken.value, raw:"-"+nextToken.raw, start:token.start, end:token.end }, index + 2]
            case "variable": return [{ type:"subtract", left:{ type:"number", value:0, raw:"0", start:-1, end:-1 }, right: nextToken }, index + 2]
        }
    }
    
    if (ast.type === "labeled") throw Error(`Can't subtract from labeled sections: ${token.start}`)

    return [{ type:"subtract", left:ast, right:nextToken }, index + 2]
}
const eatDieTokenMultiply = (ast: DieExpressionAST, tokens: DieToken[], index: number, token: DieTokenMultiply): [DieExpressionAST, number] => {
    const nextToken = tokens[index + 1]
    if (nextToken === undefined) throw Error(`Expected a number or variable at ${token.end + 1}`)
    if (nextToken.type !== "number" && nextToken.type !== "variable") throw Error(`Expected a number or variable at ${nextToken.start}`)

    switch (ast.type) {
        case "null": throw Error("Can't start a die expression with a multiply")
        case "multiply": case "variable": case "number": case "die": return [{ type:"multiply", left:ast, right:nextToken }, index + 2]
        case "labeled": throw Error(`Can't multiply labeled sections: ${token.start}`)
        case "add": case "subtract":
            if (ast.right.type === "labeled") throw Error(`Can't multiply labeled sections: ${token.start}`)
            return [{ ...ast, right:{ type:"multiply", left:ast.right, right:nextToken } }, index + 2]
    }
}
const eatDieTokenLabel = (ast: DieExpressionAST, tokens: DieToken[], index: number, token: DieTokenLabel): [DieExpressionAST, number] => {
    switch (ast.type) {
        case "null": throw Error("Can't start a die expression with a label")
        case "labeled": throw Error(`Can't follow a labeled section with another label: ${token.start}`)
        case "add":
            if (ast.left.type !== "labeled") return [{ type:"labeled", label:token, expression: ast }, index + 1]
            if (ast.right.type === "labeled") throw Error(`Can't follow a labeled section with another label: ${token.start}`)
            return [{ type:ast.type, left:ast.left, right:{ type:"labeled", label:token, expression:ast.right } }, index + 1]
        case "subtract": throw Error(`Can't labeled a subtracted section: ${token.start}`)
        case "die": case "multiply": case "number": case "variable": return [{ type:"labeled", label:token, expression:ast }, index + 1]
    }
}
const eatDieTokenDie = (ast: DieExpressionAST, tokens: DieToken[], index: number, token: DieTokenDie): [DieExpressionAST, number] => {
    const nextToken = tokens[index + 1]
    if (nextToken === undefined) throw Error(`Expected a number or variable at ${token.end + 1}`)
    if (nextToken.type !== "number" && nextToken.type !== "variable") throw Error(`Expected a number or variable at ${nextToken.start}`)

    switch (ast.type) {
        case "null": return [{ type:"die", multiplier:{ type:"number", value:1, raw:"1", start:-1, end:-1 }, die:nextToken }, index + 2]
        case "number": case "variable": return [{ type:"die", multiplier:ast, die: nextToken }, index + 2]
        case "add": case "subtract": case "multiply":
            if (ast.right.type === "number" || ast.right.type === "variable") return [{ ...ast, right:{ type:"die", multiplier:ast.right, die: nextToken } }, index + 2]
            throw Error(`Simple die expressions must use a number or variable as the multiplier: ${token.start}`)
        case "die": case "labeled": throw Error(`Simple die expressions must use a number or variable as the multiplier: ${token.start}`)
    }
}
export const parseDieExpressionToAST = (input: string): DieExpressionAST => {
    const tokens = parseDieExpressionToTokens(input)
    if (tokens.length === 0) return { type:"null" }

    let ast: DieExpressionAST = { type:"null" }
    let index = 0

    while (index < tokens.length) {
        const thisToken = tokens[index]

        switch (thisToken.type) {
            case "number": [ast, index] = eatDieTokenNumber(ast, tokens, index, thisToken); break;
            case "variable": [ast, index] = eatDieTokenVariable(ast, tokens, index, thisToken); break;
            case "add": [ast, index] = eatDieTokenAdd(ast, tokens, index, thisToken); break;
            case "subtract": [ast, index] = eatDieTokenSubtract(ast, tokens, index, thisToken); break;
            case "multiply": [ast, index] = eatDieTokenMultiply(ast, tokens, index, thisToken); break;
            case "label": [ast, index] = eatDieTokenLabel(ast, tokens, index, thisToken); break;
            case "die": [ast, index] = eatDieTokenDie(ast, tokens, index, thisToken); break;
        }
    }

    return ast
}


export type DieExpression = Nominal<string, "DieExpression">;
export const parseDieExpression = (input: string | undefined): DieExpression | undefined => {
    if (input === undefined) return undefined

    parseDieExpressionToAST(input)
    return input as DieExpression
}
export const parseDieExpressionRequired = (input: string): DieExpression => {
    parseDieExpressionToAST(input)
    return input as DieExpression
}

export type DieExpressionModifier = Nominal<string, "DieExpressionModifier">;
export const parseDieExpressionModifier = (input: string): DieExpressionModifier => {
    return input as DieExpressionModifier
}

const combineASTAverage = (left: string | number, right: string | number, mode: "add" | "subtract" | "multiply" | "die"): string | number => {
    if (typeof(left) === "number" && typeof(right) === "number") {
        switch (mode) {
            case "add": return left + right
            case "subtract": return left - right
            case "multiply": return left * right
            case "die": return Math.floor(left * (right + 1) / 2)
        }
    }
    if (mode === "subtract") throw Error(`Can't calculate ${left} - ${right}`)
    if (mode === "multiply") throw Error(`Can't calculate ${left} x ${right}`)
    if (mode === "die") throw Error(`Can't calculate ${left} x ${right} / 2`)

    return left.toString() + " + " + right.toString()
}
const calculateASTAverage = (ast: DieExpressionAST, variables: {[key: string]: number}): string | number => {
    switch (ast.type) {
        case "add": return combineASTAverage(calculateASTAverage(ast.left, variables), calculateASTAverage(ast.right, variables), "add")
        case "die": return combineASTAverage(calculateASTAverage(ast.multiplier, variables), calculateASTAverage(ast.die, variables), "die")
        case "labeled": return `${calculateASTAverage(ast.expression, variables)} ${ast.label}`;
        case "multiply": return combineASTAverage(calculateASTAverage(ast.left, variables), calculateASTAverage(ast.right, variables), "multiply")
        case "null": return 0;
        case "number": return ast.value;
        case "subtract": return combineASTAverage(calculateASTAverage(ast.left, variables), calculateASTAverage(ast.right, variables), "subtract")
        case "variable":
            if (variables[ast.name] === undefined) throw Error(`Unknown variable: ${ast.name}`)
            return variables[ast.name];
    }
}
export const calculateDieExpressionAverage = (input: string, variables: {[key: string]: number}): string => {
    const ast = parseDieExpressionToAST(input)
    return calculateASTAverage(ast, variables).toString()
}

const calculateASTConcrete = (ast: DieExpressionAST, variables: {[key: string]: number}): string | number => {
    switch (ast.type) {
        case "add":
            const addA = calculateASTAverage(ast.left, variables)
            const addB = calculateASTAverage(ast.right, variables)
            return typeof(addA) === "number" && typeof(addB) === "number" ? addA + addB : `${addA} + ${addB}`
        case "die": return `${calculateASTAverage(ast.multiplier, variables)}d${calculateASTAverage(ast.die, variables)}`
        case "labeled": return `${calculateASTAverage(ast.expression, variables)} ${ast.label} damage`;
        case "multiply":
            const mulitplyA = calculateASTAverage(ast.left, variables)
            const mulitplyB = calculateASTAverage(ast.right, variables)
            return typeof(mulitplyA) === "number" && typeof(mulitplyB) === "number" ? mulitplyA * mulitplyB : `${mulitplyA} x ${mulitplyB}`
        case "null": return ''
        case "number": return ast.value
        case "subtract":
            const subtractA = calculateASTAverage(ast.left, variables)
            const subtractB = calculateASTAverage(ast.right, variables)
            return typeof(subtractA) === "number" && typeof(subtractB) === "number" ? subtractA - subtractB : `${subtractA} - ${subtractB}`
        case "variable":
            if (variables[ast.name] === undefined) throw Error(`Unknown variable: ${ast.name}`)
            return variables[ast.name];
    }
}
export const calculateDieExpressionConcrete = (input: string, variables: {[key: string]: number}): string => {
    const ast = parseDieExpressionToAST(input)
    return calculateASTConcrete(ast, variables).toString()
}