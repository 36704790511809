import { CompletionContext, CompletionResult } from "@codemirror/autocomplete";
import { getCompletions, getMacroTemplateCompletions } from "./markdownblocks/_all";
import { CompletionDatabase } from "../state/page/types";

interface ContextLine {
    text: string;
    number: number;
    from: number;
}

type GetCompletionsFn = (context: CompletionContext, line: ContextLine, text: string[], completionDatabase: CompletionDatabase) => CompletionResult | null

const macroStartRE = /^\[\[\w*/s
export const getMacroCompletions: GetCompletionsFn = (context, line, text, completionDatabase) => {
    if (line.text.slice(0,2) !== "[[") return null

    const match = context.matchBefore(macroStartRE);
    if (match === null) return null
    
    return {
        from: match.from,
        to: match.to + 2,
        options: getMacroTemplateCompletions()
    }
}

const macroStartLineRE = /^\[\[(\w+)(\|[^|\]])*\]\]$/
export const getMacroContentCompletions: GetCompletionsFn = (context, line, text, completionDatabase) => {
    if (line.text.slice(0, 2) !== "| ") return null

    const thisLine = line.number - 1;

    for (let i=thisLine; i>=0; i--) {
        if (text[i] && text[i].search(macroStartLineRE) === 0) {
            return getCompletions(text[i].replace(macroStartLineRE, '$1'), context, text, i, thisLine, completionDatabase)
        }
    }

    return null;
}

const linkStartRE = /\[\w*/
export const getLinkCompletions: GetCompletionsFn = (context, line, text, completionDatabase) => {
    const linkMatch = context.matchBefore(linkStartRE);
    if (linkMatch === null) return null

    return {
        from: linkMatch.from,
        to: linkMatch.to + 1,
        options: completionDatabase.link
    }
}

const frontMatterTagNameRE = /^[-\w_]*/
const frontMatterTagValueRE = /^[-\w_]+: .*/
const frontMatterTagListValueRE = /^- .*/
export const getFrontmatterCompletions: GetCompletionsFn = (context, line, text, completionDatabase) => {
    if (text[0] !== "---") return null
    if (text.slice(1, line.number - 1).filter((line: string) => line === '---').length !== 0) return null

    const currentTag = context.matchBefore(frontMatterTagNameRE);
    if (currentTag) return {
        from: currentTag.from,
        options: completionDatabase.frontmatter.tag
    };

    const currentTagSingleValue = context.matchBefore(frontMatterTagValueRE);
    if (currentTagSingleValue) {
        const tagName = line.text.split(":").shift() || ""

        return {
            from: currentTagSingleValue.from + tagName.length + 2,
            options: completionDatabase.frontmatter.tagValue[tagName]
        }
    }

    const currentTagArrayValue = context.matchBefore(frontMatterTagListValueRE);
    if (currentTagArrayValue) {
        const tagLine = text.slice(0, line.number-2).reverse().find((line: string) => line.slice(0,2) !== "- ")

        if (tagLine) {
            const tagName = tagLine.split(":").shift() || ""

            return {
                from: currentTagArrayValue.from + 2,
                options: completionDatabase.frontmatter.tagValue[tagName]
            }
        }
    }
    
    return null
}

const flatDoc = (doc: any): string[] => {
    if (doc.children && Array.isArray(doc.children)) {
        return doc.children.map(flatDoc).flat()
    }
    if (doc.text) {
        return doc.text;
    }

    return []
}

const completionFunctions: GetCompletionsFn[] = [
    getMacroCompletions,
    getMacroContentCompletions,
    getLinkCompletions,
    getFrontmatterCompletions
]
export const getAllCompletions = (completionDatabase: CompletionDatabase) => (context: CompletionContext): CompletionResult | null => {
    const line = context.state.doc.lineAt(context.pos);
    if (line.text.length === 0) return null

    const doc = context.state.doc as any;
    const text = flatDoc(doc);

    for (let i=0; i<completionFunctions.length; i++) {
        const completions = completionFunctions[i](context, line, text, completionDatabase)
        if (completions) return completions
    }

    return null
}
