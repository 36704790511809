import { Metadata } from "../../state/metadatastore/types";
import { MetadataStore, getItemTagPath, getItemTagValuePath, getPageTagPath, getPageTagValuePath } from "../../state/useMetadataStore";
import { QueryResultCell, QueryResultRow, RowReference } from "../query";
import { DirPath } from "../types";
import { TableReference } from "./from";

export interface QueryResultHeading {
    key: string;
    heading: string;
    linkable: boolean;
    path: DirPath;
}

export const selectHeadings = (metadataDatabase: MetadataStore, table: TableReference) => (column: any): QueryResultHeading => {
    if (column.expr.column === "random") {
        return { key:"random", heading:"Row", path:"" as DirPath, linkable:false }
    }

    const path = table.table === "page" ? getPageTagPath(column.expr.column) : getItemTagPath(column.expr.column, table.type)
    const linkable = metadataDatabase.getMetadataValue(path, 'linkable', "true") === "true"

    if (typeof(column.as) === "string") return { key:column.expr.column, heading:column.as, path, linkable };

    const metadata = metadataDatabase.pages[path];
    if (metadata === undefined) return { key:column.expr.column, heading:column.expr.column, path, linkable };

    const title = metadata.title;
    if (typeof(title) === "string") return { key:column.expr.column, heading:title, path, linkable };

    return { key:column.expr.column, heading:column.expr.column, path, linkable };
}

const metadataToCell = (metadata: Metadata, metadataDatabase: MetadataStore, table: TableReference, rowIndex: number) => (heading: QueryResultHeading): QueryResultCell => {
    if (heading.key === "random") {
        return { key:heading.key, linkable:false, items:[{ label:(rowIndex+1).toString(), path:"" as DirPath }]}
    }
    
    const value = metadata[heading.key];
    if (value === undefined) return { key: heading.key, linkable: false, items: [] };

    const linkable = table.table === "page"
        ? metadataDatabase.getMetadataValue(getPageTagPath(heading.key), 'linkable', "true") === "true"
        : metadataDatabase.getMetadataValue(getItemTagPath(heading.key, table.type), 'linkable', "true") === "true";

    if (typeof(value) === "string" || typeof(value) === "number") {
        return { key: heading.key, linkable, items: [{
            label: value.toString(),
            path: table.table === "page" ? getPageTagValuePath(heading.key, value.toString()) : getItemTagValuePath(heading.key, value.toString(), table.type)
        }] };
    }

    return {
        key: heading.key,
        linkable,
        items: value.map(v => ({
            label: v,
            path: table.table === "page" ? getPageTagValuePath(heading.key, v) : getItemTagValuePath(heading.key, v, table.type)
        })) };
}
export const resultToRow = (metadataDatabase: MetadataStore, headings: QueryResultHeading[], table: TableReference) => (reference: RowReference, index: number): QueryResultRow => {
    const metadata = reference.type === "page"
        ? metadataDatabase.pages[reference.path]
        : metadataDatabase.items[reference.path][reference.id];
        
    return {
        reference: reference,
        metadata: metadataDatabase.pages[reference.path],
        cells: headings.map(metadataToCell(metadata, metadataDatabase, table, index))
    }
}

const mergeUp = (arr: QueryResultRow[], next: QueryResultRow): QueryResultRow[] => {
    const prev = arr[arr.length - 1];
    if (!prev || JSON.stringify(prev.cells) !== JSON.stringify(next.cells)) return [...arr, next]

    return arr;
}
export const reduceToUnique = (rows: QueryResultRow[]): QueryResultRow[] => rows.reduce(mergeUp, [] as QueryResultRow[])