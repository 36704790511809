import Auth, { CognitoUser } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify';
import { useMetadataStore } from './useMetadataStore';
import { create } from 'zustand';
import { userSignUp } from './user/userSignUp';
import { userResendConfirmationCode } from './user/userResendConfirmationCode';
import { userConfirmSignUp } from './user/userConfirmSignup';
import { userSignIn } from './user/userSignIn';
import { userSignOut } from './user/userSignOut';
import { useRandomTables } from './useRandomTables';

export interface UserStore {
    status: "nouser" | "user" | "error"
    user: CognitoUser | null
    error: Error | null

    setStatusNoUser: () => void
    setStatusUser: (user: CognitoUser) => void
    setStatusError: (error: Error) => void

    signUp: (username: string, password: string, email: string, phone_number: string) => Promise<void>
    resendConfirmationCode: (username: string) => Promise<void>
    confirmSignUp: (username: string, code: string) => Promise<void>
    signIn: (username: string, password: string) => Promise<void>
    signOut: () => Promise<void>
}

export const useUser = create<UserStore>()((set, get) => ({
    status: "nouser",
    user: null,
    error: null,

    setStatusNoUser: () => set(() => ({ status: "nouser", user: null, error: null })),
    setStatusUser: (user) => set(() => ({ status: "user", user, error: null })),
    setStatusError: (error) => set(() => ({ status: "error", user: null, error })),

    signUp: (username, password, email, phone_number) => userSignUp(username, password, email, phone_number, get()),
    resendConfirmationCode: (username) => userResendConfirmationCode(username),
    confirmSignUp: (username, code) => userConfirmSignUp(username, code),
    signIn: (username, password) => userSignIn(username, password),
    signOut: () => userSignOut(),
}))

export const setStatusNoUser = () => {
    useUser.getState().setStatusNoUser()
    useMetadataStore.getState().setMode("public")
    useRandomTables.getState().setMode("public")
}

export const setStatusUser = (user: CognitoUser) => {
    useUser.getState().setStatusUser(user)
    useMetadataStore.getState().setMode("private")
    useRandomTables.getState().setMode("private")
}

export const setStatusError = (error: Error) => {
    useUser.getState().setStatusError(error)
    useMetadataStore.getState().setMode("public")
    useRandomTables.getState().setMode("public")
}

Auth.currentAuthenticatedUser().then(setStatusUser).catch(setStatusError)

Hub.listen("auth", ({ payload: { event, data } }) => {
    switch (event) {
      case "signIn": case "autoSignIn": setStatusUser(data); break;
      case "signOut": case 'autoSignIn_failure': setStatusNoUser(); break;
    }
})

export const useIsLoggedIn = () => useUser(state => state.status === "user")
