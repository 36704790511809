import { BlockNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { BlockParserFunctions, ParseAllBlockFunction, ParseAllInlineFunction, PeekAllBlockFunction } from "../parser"

const isNotParagraphInjected = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number): boolean => {
    if (index === 0) return false

    const match = peekAll(range.toArray())
    if (match === null || match.name === "paragraph") return false

    return true
}

const processParagraphRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => ({
    type: "block",
    name: "paragraph",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    children: parseInline(ranges)
})

export const ParagraphParserFunctions: BlockParserFunctions = {
    name: "paragraph",
    peek: (range: MarkdownRange) => true,
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotParagraphInjected(peekAll),
        processParagraphRangesInjected(parseBlocks, parseInline),
        "Expected start of paragraph"
    )
}
