import { BlockNode } from "../ast"
import { MarkdownRange, MarkdownRangeArray, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllBlockFunction, ParseAllBlockFunction, ParseAllInlineFunction, BlockParserFunctions } from "../parser"

const HeadingStartRE = /^(#+)(.*)/m

const isNotHeadingInjected = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number): boolean => index !== 0

const processHeadingRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => {
    const range = ranges[0]
    const opening = range.markdown.replace(HeadingStartRE, '$1')
    
    const textRange = new MarkdownRangeArray([range.slice(range.start + opening.length, range.end)])
    if (!textRange.isNonEmpty()) throw ranges.error("Expected non-empty heading")

    return {
        type: "block",
        name: "heading",
        start: ranges.getStart(),
        end: ranges.getEnd(),
        meta: {
            level: Math.max(1, Math.min(6, opening.length - 1)).toString()
        },
        children: parseInline(textRange)
    }
}

export const HeadingParserFunctions: BlockParserFunctions = {
    name: "heading",
    peek: (range: MarkdownRange) => range.markdown[0] === "#",
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotHeadingInjected(peekAll),
        processHeadingRangesInjected(parseBlocks, parseInline),
        "Expected start of heading"
    )
}
