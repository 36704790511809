import { InlineNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray, RangesResult } from "../markdownrange"
import { PeekAllInlineFunction, ParseAllInlineFunction, InlineParserFunctions } from "../parser"
import { parseLinkMetadata } from "./linkMetadata"
import { parseLinkText } from "./linkText"

export const parseComplexLink = (ranges: NonEmptyMarkdownRangeArray, parseInline: ParseAllInlineFunction, peekAll: PeekAllInlineFunction): RangesResult<InlineNode> => {
    const linkTextMatch = parseLinkText(ranges, parseInline, peekAll)
    if (linkTextMatch === null) throw new Error("Expected link text")
    const [linkText, linkTextRemainder] = linkTextMatch
    if (!linkTextRemainder.isNonEmpty()) throw new Error("Expected link href")

    return parseLinkMetadata(linkTextRemainder, parseInline, peekAll, linkText)
}

export const ComplexLinkParserFunctions: InlineParserFunctions = {
    name: "complexlink",
    peek: (char, index, range: MarkdownRange) => range.matches(index, "["),
    parse: (ranges, parseInline, peekAll) => {
        const linkTextMatch = parseLinkText(ranges, parseInline, peekAll)
        if (linkTextMatch === null) throw new Error("Expected link text")

        const [linkText, linkTextRemainder] = linkTextMatch
        if (!linkTextRemainder.isNonEmpty()) throw new Error("Expected link href")
    
        return parseLinkMetadata(linkTextRemainder, parseInline, peekAll, linkText)
    }
}
