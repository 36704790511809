import { BlockNode } from "../ast"
import { BlockParserFunctions, ParseAllBlockFunction, ParseAllInlineFunction, PeekAllBlockFunction } from "../parser"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"

const TestOrderedListItemStartRE = /^\d+\. /
const TestOrderedListItemRE = /^(\d+\. | {2})/

const isNotOrderedListItemInjected = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number): boolean => {
    if (index === 0) return false
    if (range.markdown.slice(0, 2) === "  ") return false

    return true
}

const processOrderedListItemRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => ({
    type: "block",
    name: "listitem",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    children: parseBlock(ranges.removePrefix(TestOrderedListItemRE))
})

export const OrderedListItemParserFunctions: BlockParserFunctions = {
    name: "orderedlistitem",
    peek: (range, index, obj, ancestors) => {
        if (ancestors[ancestors.length - 1] !== "orderedlist") return false
        
        return range.markdown.search(TestOrderedListItemStartRE) !== -1
    },
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotOrderedListItemInjected(peekAll),
        processOrderedListItemRangesInjected(parseBlocks, parseInline),
        "Expected start of ordered list item"
    )
}
