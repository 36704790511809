import React from 'react'
import { NodeWithAncestors } from '../ast'

export const ErrorBlockView = ({ type, name, children, meta, ancestors }: NodeWithAncestors<"block">) => {
    return <p style={{ fontWeight: "bold", color: "red" }}>
        ERROR (${type}:${name}): {meta.errorMessage}<br />
        Metadata: <code>{JSON.stringify(meta)}</code><br />
        Children: <code>{JSON.stringify(children)}</code>
    </p>
}

export const ErrorInlineView = ({ type, name, children, meta, ancestors }: NodeWithAncestors<"inline">) => {
    return <span style={{ fontWeight: "bold", color: "red" }}>
        ERROR (${type}:${name}): {meta.errorMessage}.
        Metadata: <code>{JSON.stringify(meta)}</code>.
        Children: <code>{JSON.stringify(children)}</code>.
    </span>
}
