import { putObjectACL } from "../../data/s3";
import { BasicACL } from "../../data/types";
import { PageMediaStore } from "../usePageMedia";

export const pagemediaSetACL = async (acl: BasicACL, store: PageMediaStore) => {
    if (store.status !== "loaded") throw Error("Files are not initialized")
    if (store.items.find(item => item.status !== "loaded" && item.status !== "deleted" && item.status !== "error") !== undefined) throw Error("Not all files are complete")

    for (let mediaItem of store.items) {
        await putObjectACL(`public/${mediaItem.path}`, mediaItem.acl === "default" ? acl : mediaItem.acl)
        if (mediaItem.thumbPath) await putObjectACL(`public/${mediaItem.thumbPath}`, mediaItem.acl === "default" ? acl : mediaItem.acl)
    }
}