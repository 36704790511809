import React from 'react'

interface DndPropertyLineOptions {
    label: string;
    value?: string;
    children?: any;
}

const DndPropertyLine = ({ label, value, children }: DndPropertyLineOptions) => {
    if (!(value || children)) return null

    return <p className="property">
        <strong>{label}</strong>&nbsp;
        {children}
        {value}
    </p>
}

export default DndPropertyLine