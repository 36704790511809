import React from 'react';
import { IDArray, TableOfContentsTiered } from '../../state/page/types';
import { useHeadingVisibility, usePageState } from '../../state/usePageState';
import { Link } from './Link';

const listStyles: React.CSSProperties = {
    listStyleType: "none",
    paddingLeft: "1em",
    borderLeft: "1px solid #DDD"
}

interface TableOfContentsItemOptions {
    id?: string;
    href?: string;
    level: number;
    visibleIDs: IDArray;
    label: string;
    items: TableOfContentsTiered[];
}
const TableOfContentsItem = ({ id, href, level, visibleIDs, label, items }: TableOfContentsItemOptions) => {
    const linkStyle = {
        fontSize: level === 1 ? "1.25em" : "1em",
        textDecoration: "none",
        fontWeight: visibleIDs.indexOf(id || "") !== -1 ? "bold" : "normal"
    } as React.CSSProperties

    return <li>
        {href ? <Link href={href} style={linkStyle}>{label}</Link> : label}
        {items.length ? <TableOfContentsList toc={items} visibleIDs={visibleIDs} level={level+1} /> : null}
    </li>
}


interface TableOfContentsListOptions {
    toc: TableOfContentsTiered[];
    visibleIDs: IDArray;
    level?: number;
}

const topLevelListStyles: React.CSSProperties = {
    ...listStyles,
    position: "sticky",
    top: 56,
    marginTop: "1rem",
    marginLeft: 0,
    paddingBottom: "4rem",
    borderLeftStyle: "none",
    height: "100vh",
    overflowY: "scroll"
}

const TableOfContentsList = ({ toc, visibleIDs, level=1 }: TableOfContentsListOptions) => <ul style={level === 1 ? topLevelListStyles : listStyles}>
    {toc.map((item, i) => <TableOfContentsItem
        key={i}
        id={item.id}
        href={item.href}
        level={item.level}
        visibleIDs={visibleIDs}
        label={item.label}
        items={item.children}
    />)}
</ul>;


const ViewTableOfContents = () => {
    const tocTiered = usePageState(state => state.tocTiered)
    const visibleIDs = useHeadingVisibility(tocTiered)

    return <TableOfContentsList toc={tocTiered} visibleIDs={visibleIDs} />
}

export default ViewTableOfContents