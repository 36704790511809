import { putObjectACL } from "../../data/s3";
import { PageMediaStore } from "../usePageMedia";
import { Storage } from 'aws-amplify';
import pica from "pica";

const getFileAsImage = async (file: File): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
    var img = document.createElement('img');
    var blob = URL.createObjectURL(file);
    img.src = blob;
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
});

export const pagemediaUploadNextItem = async (store: PageMediaStore) => {
    // one upload at a time
    if (store.items.findIndex(item => item.status === "uploading") !== -1) {
        console.log("Already an uploading item")
        return
    }

    const mediaItem = store.items.find(item => item.status === "queued")
    // no queued items
    if (mediaItem === undefined) {
        console.log("No queued items")
        return
    }
    
    await Storage.put(mediaItem.path, mediaItem.source, {
        progressCallback(progress) {
            if (progress.loaded === progress.total) {
                if (!mediaItem.thumbPath) store.updateItem(mediaItem.path, { status: "loaded" });
            } else {
                store.updateItem(mediaItem.path, {
                    status: "uploading",
                    progress: mediaItem.thumbPath
                        ? progress.loaded / progress.total / 2
                        : progress.loaded / progress.total
                });
            }
        }
    });
    if (mediaItem.acl === "public-read") await putObjectACL(`public/${mediaItem.path}`, store.acl);

    if (mediaItem.source && mediaItem.thumbPath) {
        const sourceImage = await getFileAsImage(mediaItem.source);
        const canvas = document.createElement("canvas");
        if (sourceImage.height < sourceImage.width) {
            canvas.height = 256 * sourceImage.height / sourceImage.width;
            canvas.width = 256;
        }
        else if (sourceImage.height > sourceImage.width) {
            canvas.height = 256;
            canvas.width = 256 * sourceImage.width / sourceImage.height;
        }
        else {
            canvas.height = 256;
            canvas.width = 256;
        }
        const picaInstance = pica();
        const thumbResult = await picaInstance.resize(sourceImage, canvas);
        const thumbBlob = await picaInstance.toBlob(thumbResult, `image/png`);
        await Storage.put(mediaItem.thumbPath, thumbBlob, {
            progressCallback(progress) {
                if (progress.loaded === progress.total) {
                    store.updateItem(mediaItem.path, {
                        status: "loaded",
                        metadata: {
                            ...mediaItem.metadata,
                            width: `${sourceImage.width}px`,
                            height: `${sourceImage.height}px`
                        }
                    });
                } else {
                    store.updateItem(mediaItem.path, {
                        status: "uploading",
                        progress: 0.5 + progress.loaded / progress.total
                    });
                }
            }
        });
        if (mediaItem.acl === "public-read") await putObjectACL(`public/${mediaItem.thumbPath}`, store.acl);

        setTimeout(() => store.processNextQueuedItem(), 0)
    }
}
