import React from 'react';
import { Badge } from 'react-bootstrap';
import TagLink from './TagLink';
import { TableReference } from '../../data/query/from';
import { getItemTagPath, getPageTagPath, useMetadataValue } from '../../state/useMetadataStore';
import { Metadata } from '../../state/metadatastore/types';

interface MetadataBadgeOptions {
    name: string;
    value: string | string[];
    tableReference: TableReference;
}

const whiteLinks: React.CSSProperties = {
    color: "white"
}

const MetadataBadge = ({ name, value, tableReference }: MetadataBadgeOptions) => {
    const colour = useMetadataValue(
        tableReference.table === "page"
            ? getPageTagPath(name)
            : getItemTagPath(name, tableReference.type),
        'colour',
        'rgb(13, 110, 253)'
    )

    if (name === "title") {
        return null;
    }
    else if (typeof(value) === "string") {
        return <Badge className="me-1" style={{backgroundColor:colour}}>
            <TagLink tableReference={tableReference} style={whiteLinks} tag={name} />:&nbsp;
            <TagLink tableReference={tableReference} style={whiteLinks} tag={name} value={value} />
        </Badge>
    }
    else if (Array.isArray(value)) {
        return <Badge className="me-1" style={{backgroundColor:colour}}>
            <TagLink tableReference={tableReference} style={whiteLinks} tag={name} />:&nbsp;
            {value.map(val => <TagLink tableReference={tableReference} key={val} style={whiteLinks} tag={name} value={val} />)}
        </Badge>
    }

    return null;
}


interface MetadataBadgesOptions {
    metadata: Metadata;
    table: "page" | "item";
}

const MetadataBadges = ({ metadata, table }: MetadataBadgesOptions) => {
    const tableReference: TableReference = table === "page"
        ? { table: "page" }
        : { table: "item", type: Array.isArray(metadata.type) ? metadata.type.join(",") : metadata.type };

    return <div id="tags" className="mb-2">
        {Object.keys(metadata).map(key => <MetadataBadge tableReference={tableReference} key={key} name={key} value={metadata[key]} />)}
    </div>
}

export default MetadataBadges;