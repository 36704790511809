import { DirPath } from "../../data/types";
import { MetadataStoreData, getPageTagPath } from "../useMetadataStore";
import { metadataStoreGetMetadataValue } from "./metadataStoreGetMetadataValue";
import { TagCompletions } from "./types";

export const metadataStoreGetPageTagValueCompletions = (store: MetadataStoreData): TagCompletions => {
    const result: TagCompletions = {};

    for (let key in store.metadata) {
        if (metadataStoreGetMetadataValue(`/${getPageTagPath(key)}` as DirPath, 'autocomplete', "true", store) === "true") {
            result[key] = Object.keys(store.metadata[key]).map(value => ({
                label: value,
                type: "macro",
                detail: "Frontmatter"
            }));
        }
    }
    
    return result;
};
