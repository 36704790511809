import React from 'react'
import { NeutralPower } from '../../neutralcreature';

interface NeutralPowerOptions {
    power: NeutralPower;
}

const NeutralPowerLine = ({ power }: NeutralPowerOptions) => {
    return <p className="power">
        <strong>{power.name}.</strong>&nbsp;
        {power.description}
    </p>
}

export default NeutralPowerLine