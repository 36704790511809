import React from 'react';
import { Button } from 'react-bootstrap';
import './EditTableOfContents.css'
import { useHeadingVisibility, usePageState } from '../../state/usePageState';
import { IDArray, TableOfContentsTiered } from '../../state/page/types';

interface TableOfContentsItemOptions {
    id: string;
    level: number;
    visibleIDs: IDArray;
    label: string;
    items: TableOfContentsTiered[];
}

const TableOfContentsItem = ({ id, level, visibleIDs, label, items }: TableOfContentsItemOptions) => {
    const goToHeading = usePageState(state => state.goToHeading)

    return <li>
        <Button variant="link" onClick={() => goToHeading(id)} className={visibleIDs.indexOf(id) !== -1 ? "visible" : ""}>{label}</Button>
        {items.length ? <TableOfContentsList toc={items} visibleIDs={visibleIDs} level={level+1} /> : null}
    </li>
}

interface TableOfContentsListOptions {
    toc: TableOfContentsTiered[];
    visibleIDs: IDArray;
    level?: number;
}

const TableOfContentsList = ({ toc, visibleIDs, level=1 }: TableOfContentsListOptions) => <ul className={level === 1 ? "toc-list toc-list-main" : "toc-list"}>
    {toc.map((item, i) => <TableOfContentsItem key={i} id={item.id || ""} level={item.level} visibleIDs={visibleIDs} label={item.label} items={item.children} />)}
</ul>;


const EditTableOfContents = () => {
    const tocTiered = usePageState(state => state.tocTiered)
    const visibleIDs = useHeadingVisibility(tocTiered)

    return <TableOfContentsList toc={tocTiered} visibleIDs={visibleIDs} />
}

export default EditTableOfContents
