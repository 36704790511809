import React from 'react'
import DndAttackPower from './DndAttackPower'
import DndSubheading from './DndSubheading'
import { AbilityScoreSet, CreaturePower } from '../../../dnd'
import DndBasicPower from './DndBasicPower'

interface DndLegendaryPowersOptions {
    name?: string
    proficiency?: number
    abilities?: AbilityScoreSet
    powers: CreaturePower[]
    showBreak: boolean
}

const DndLegendaryPowers = ({ name, proficiency, abilities, powers, showBreak }: DndLegendaryPowersOptions) => {
    const legendaryPowers = powers.filter(v => (v.type === "action" || v.type === "attack") && v.legendaryActionPoints !== 0)
    if (legendaryPowers.length === 0) return null

    return <div className="block legendary-powers">
        <DndSubheading>Legendary Actions</DndSubheading>
        <p>{name ? name : 'The creature'} can take 3 legendary actions, choosing from the options below. Only one legendary action option can be used at a time and only at the end of another creature's turn. {name} regains spent legendary actions at the start of its turn.</p>
        {legendaryPowers.map((power, i) => power.type === "attack"
            ? <DndAttackPower key={i} proficiency={proficiency} abilities={abilities} power={power} />
            : <DndBasicPower key={i} power={power} />
        )}
    </div>
}

export default DndLegendaryPowers