import { HeadingLevels } from "../../data/types"
import { TableOfContentsFlat, TableOfContentsReduction, TableOfContentsTiered } from "./types"

export const resolveUnchangedElement = (oldItem: TableOfContentsTiered, newItem: TableOfContentsTiered): TableOfContentsTiered => {
    if (oldItem.id !== newItem.id || oldItem.label !== newItem.label) return newItem
    if (oldItem.children.length !== newItem.children.length) return newItem

    const resolvedChildren = resolveUnchangedElements(oldItem.children, newItem.children)
    if (resolvedChildren !== oldItem.children) return { ...newItem, children: resolvedChildren }

    return oldItem
}

export const resolveUnchangedElements = (oldToC: TableOfContentsTiered[], newToC: TableOfContentsTiered[]): TableOfContentsTiered[] => {
    let changed = false

    const resolvedArray = newToC.map((newItem, index) => {
        const resolvedItem = resolveUnchangedElement(oldToC[index], newItem)
        if (resolvedItem === oldToC[index]) return oldToC[index]

        changed = true
        return resolvedItem
    })

    if (changed) return resolvedArray

    return oldToC
}

export const pageGenerateTieredToC = (markdown: string, oldToC: TableOfContentsTiered[]): TableOfContentsTiered[] => {
    const headingRegex = /^(#+) (.*)$/
    const lines = markdown.split("\n")

    let frontmatterStatus: "none" | "started" | "ended" = "none"
    let position = 0
    const flat = lines.map((line, index): TableOfContentsFlat => {
        position += line.length + 1

        if (line.slice(0,1) === "#") {
            const matches = line.match(headingRegex)
            if (!matches) return { id: "", label: "", href: "", level: 1 }

            return {
                id: `${position - line.length}`,
                label: matches[2] || "",
                href: `#${index}`,
                level: matches[1].length as HeadingLevels
            }
        }
        else if (line.slice(0, 3) === "| #") {
            const matches = line.slice(2).match(headingRegex)
            if (!matches) return { id: "", label: "", href: "", level: 1 }

            return {
                id: `${position - line.length}`,
                label: matches[2] || "",
                href: `#${index}`,
                level: matches[1].length as HeadingLevels
            }
        }
        else if (line.slice(0, 8) === "| name: ") {
            return {
                id: `${position - line.length}`,
                label: line.slice(8),
                href: `#${index}`,
                level: 6
            }
        }
        else if (index === 0 && line === "---") {
            frontmatterStatus = "started"
        }
        else if (frontmatterStatus === "started" && line === "---") {
            frontmatterStatus = "ended"
        }
        else if (frontmatterStatus === "started" && line.slice(0, 7) === "title: ") {
            return {
                id: `${position - line.length}`,
                label: line.split(": ").pop() || "",
                href: `#${index}`,
                level: 1
            }
        }

        return { id: "", label: "", href: "", level: 1 }
    }).filter(({ id }) => id !== "")

    const reduction = flat.reduce((a, b) => {
        // create tiered version
        const item: TableOfContentsTiered = { ...b, children:[] };

        // trim breadcrumb - remove items from end that couldn't be the new item's parent
        const breadcrumb: TableOfContentsTiered[] = [...a.breadcrumb.filter(i => i.level < b.level), item];

        // it's a new 1st level item
        if (breadcrumb.length === 1) return { toc:[...a.toc, item], breadcrumb };

        // it's a child of the 2nd to last item
        breadcrumb[breadcrumb.length - 2].children.push(item);
        return { toc:a.toc, breadcrumb };
    }, { toc:[], breadcrumb:[] } as TableOfContentsReduction)

    reduction.toc.push({
        id: `media`,
        label: "Media",
        href: `#media`,
        level: 1 as HeadingLevels,
        children: []
    })

    return oldToC.length === reduction.toc.length ? resolveUnchangedElements(oldToC, reduction.toc) : reduction.toc
}
