import { PageData } from "../usePageState";
import { PageElement, PageElementStatuses, PageStatusPriories } from "./types";

const statusPriority = {
    editable: 1,
    loaded: 2,
    saving: 3,
    uninitialized: 4,
    loading: 5,
    accessdenied: 6,
    error: 7,
} as PageStatusPriories

export const pageMergeStatus = (currentStatus: PageData["status"], statuses: PageElementStatuses): PageData["status"] => {
    if (currentStatus === "editable") return currentStatus

    return Object.keys(statuses)
        .map((element) => statuses[element as PageElement])
        .reduce((a, b) => statusPriority[a] > statusPriority[b] ? a : b)
}