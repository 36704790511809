import { MetadataStoreData } from "../useMetadataStore";
import { metadataStoreGetMetadataValue } from "./metadataStoreGetMetadataValue";
import { TemplateOption } from "./types";

export const metadataStoreGetTemplates = (store: MetadataStoreData): TemplateOption[] => {
    if (store.metadata.type === undefined) return []
    if (store.metadata.type.template === undefined) return []
    const paths = store.metadata.type.template || []

    return paths.map(path => ({
        path,
        title: metadataStoreGetMetadataValue(path, "title", path.replace(/^.*\/([^/]+)\/?/, "$1"), store),
        description: metadataStoreGetMetadataValue(path, "description", "", store)
    })).sort((a, b) => a.title.localeCompare(b.title))
}
