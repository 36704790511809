import React from 'react'
import { AbilityScoreSet, CreaturePower } from '../../../dnd'
import DndSubheading from './DndSubheading'
import DndBasicPower from './DndBasicPower'
import DndAttackPower from './DndAttackPower'

interface DndActionPowersOptions {
    powers: CreaturePower[]
    proficiency?: number
    abilities?: AbilityScoreSet
    showBreak: boolean
}

const DndActionPowers = ({ powers, proficiency, abilities , showBreak }: DndActionPowersOptions) => {
    const actionPowers = powers.filter(v => (v.type === "action" || v.type === "attack") && v.legendaryActionPoints === 0)
    if (actionPowers.length === 0) return null

    return <div className="block action-powers">
        <DndSubheading>Actions</DndSubheading>
        {actionPowers.map((power, i) => power.type === "attack"
            ? <DndAttackPower key={i} proficiency={proficiency} abilities={abilities} power={power} />
            : <DndBasicPower key={i} power={power} />
        )}
    </div>
}

export default DndActionPowers
