import { Element, ElementContent } from "react-markdown/lib/ast-to-react";
import { getBlockMatcher, MarkdownBlock } from "./markdownblocks/_utils";
import { HeadingLevel } from "@aws-amplify/ui-react";
import randomColor from 'randomcolor'
import { RandomTable, RandomTableOptionSet, RandomTableRollColumn } from "../state/randomtables/types";

const BasicRangeRE = /^\d+$/
const ComplexRangeRE = /^\d+[-–]\d+$/
const extractRollsAndData = (length: number, sets: RandomTableOptionSet[], diecolumns: number): [RandomTableRollColumn[], RandomTableOptionSet[]] => {
    // generate roll columns
    const rollcolumns = [] as RandomTableRollColumn[]
    const datacolumns = [] as RandomTableOptionSet[]
    if (diecolumns === 0) {
        rollcolumns.push({
            die: length,
            ranges: [],
            title: `d${length}`,
            color: randomColor({ luminosity: 'bright' })
        })

        for (let i=0; i<length; i++) {
            rollcolumns[0].ranges.push({
                start: i + 1,
                end: i + 2,
                raw: (i + 1).toString()
            })
        }

        for (let i=0; i<sets.length; i++) {
            datacolumns.push(sets[i])
        }
    } else {
        for (let i=0; i<diecolumns; i++) {
            const newrollcolumn: RandomTableRollColumn = {
                die: 0,
                ranges: [],
                title: "",
                color: randomColor({ luminosity: 'bright' })
            }

            for (let j=0; j<length; j++) {
                const val = sets[i].options[j] || ''

                if (val.trim().search(BasicRangeRE) === 0) {
                    const roll = parseInt(val.trim())
                    newrollcolumn.die = j > 0 && roll === 0 ? 100 : Math.max(newrollcolumn.die, roll)
                    newrollcolumn.ranges.push({
                        start: j > 0 && roll === 0 ? 100 : roll,
                        end: j > 0 && roll === 0 ? 101 : roll + 1,
                        raw: val.trim()
                    })
                } else if (val.trim().search(ComplexRangeRE) !== -1) {
                    const rolls = val.trim().split(/[-–]/).map(v => parseInt(v.trim()))
                    newrollcolumn.die = j > 0 && rolls[1] === 0 ? 100 : Math.max(newrollcolumn.die, rolls[1])
                    newrollcolumn.ranges.push({
                        start: rolls[0],
                        end: j > 0 && rolls[1] === 0 ? 101 : rolls[1] + 1,
                        raw: val.trim()
                    })
                } else {
                    newrollcolumn.ranges.push({
                        start: -1,
                        end: -1,
                        raw: val.trim()
                    })
                }
            }

            newrollcolumn.title = sets[i].title.trim() === "" ? `d${newrollcolumn.die}` : sets[i].title
            rollcolumns.push(newrollcolumn)
        }

        for (let i=diecolumns; i<sets.length; i++) {
            datacolumns.push(sets[i])
        }
    }

    return [rollcolumns, datacolumns]
}

const trimValue = (input: string) => input.replace(/(^\s*\|\s*\||^\s|\s*\|\s*$|\s*$)/g, '');

export const parseRandomTable = (block: MarkdownBlock): RandomTable | null => {
    if (block.content.length < 4) return null;

    const title = block.content.shift() || ""
    const titleText = title.replace(/^#+ /, '')
    const titleLevel = title.search(/^#+ /) === -1 ? 6 : title.replace(/^(#+) .*$/, "$1").length as HeadingLevel

    // remove invalid lines
    const dataLines = block.content
        .filter(line => line.search(/^([ -]*\s*\|)+\s*$/) === -1 && line.search(/^([^|]*\|)+\s*$/) === 0)
        .map(line => line.replace(/\s*\|\s*$/, ''));

    // split lines by
    const splitLines = dataLines.map(line => {
        return line.split("|")
    });

    // trim values
    const trimedValues = splitLines.map(row => row.map(trimValue));

    const headers = trimedValues.shift() || [];

    const sets = headers.map((title, column): RandomTableOptionSet => ({
        title,
        options: trimedValues.map(row => row[column])
    }));

    const diecolumns = block.blockParams["diecolumns"] !== undefined ? parseInt(block.blockParams["diecolumns"]) : 0
    const [rollcolumns, datacolumns] = extractRollsAndData(trimedValues.length, sets, diecolumns)

    return {
        index: block.index,
        length: trimedValues.length,
        title: titleText,
        level: titleLevel,
        sets: datacolumns,
        boundsets: block.blockParams["boundsets"] === "true",
        rollcolumns,
        line: block.line
    };
}

export const parseRandomTables = (markdown: string): RandomTable[] => {
    const matcher = getBlockMatcher("randomtable");
    const blocks = matcher(markdown);

    return blocks.map(parseRandomTable).filter(v => v !== null) as RandomTable[];
}

const getChildElement = (node: Element, tagNames: string[]): Element | null => {
    for (let child of node.children) {
        if (child.type === "element" && tagNames.indexOf(child.tagName) !== -1) {
            return child;
        }
    }
    
    return null;
}

const getChildElements = (node: Element, tagNames: string[]): Element[] => {
    let children = [] as Element[];

    for (let child of node.children) {
        if (child.type === "element" && tagNames.indexOf(child.tagName) !== -1) {
            children.push(child);
        }
    }
    
    return children;
}

type StringTest = (input: ElementContent | undefined) => boolean;

const testFirstCellValue = (row: Element, test:StringTest): boolean => {
    const cell = getChildElement(row, ["th","td"]);
    if (!cell) return false;

    const content = cell.children.pop();

    return test(content);
}

export const isRandomTable = (table: Element): boolean => {
    // test head
    const thead = getChildElement(table, ["thead"]);
    if (!thead) return false;

    const tr = getChildElement(thead, ["tr"]);
    if (!tr) return false;

    if (!testFirstCellValue(tr, (input) => input === undefined || (input.type === "text" && input.value.search(/^d\d+$/) === 0))) return false;

    // test rows
    const tbody = getChildElement(table, ["tbody"]);
    if (!tbody) return false;

    const isANumberCell: StringTest = (input) => input !== undefined && input.type === "text" && input.value.search(/^\d+$/) === 0;

    return getChildElements(tbody, ["tr"]).map(row => testFirstCellValue(row, isANumberCell)).reduce((a, b) => a && b, true);
}
