import React from 'react'
import { BasicPower, CreaturePower } from '../../../dnd'
import DndBasicPower from './DndBasicPower'

interface DndPassivePowersOptions {
    powers: CreaturePower[]
    showBreak: boolean
}

const DndPassivePowers = ({ powers, showBreak }: DndPassivePowersOptions) => {
    const passivePowers = powers.filter(v => v.type === "passive") as BasicPower[]
    if (passivePowers.length === 0) return null

    return <div className="block passive-powers">
        {passivePowers.map((power, i) => <DndBasicPower key={i} power={power} />)}
    </div>
}

export default DndPassivePowers
