import React from 'react'
import { DirPath } from "../types";
import { CustomMarkdownBlock } from "./_utils";

interface SnippetData {
    path: DirPath,
    href: string,
    html: string
}
export const SnippetBlock = new CustomMarkdownBlock<SnippetData>({
    name: "snippet",
    parser: (block) => {
        if (block.content.length !== 3) throw new Error("expected a page, link, and html snippet");

        return {
            path: block.content[0] as DirPath,
            href: block.content[1],
            html: block.content[2]
        }
    },
    renderToReact: (data) => {
        return <div>
            <p className="ignore-snippet mb-0" style={{textAlign:"right", fontSize:"0.8em"}}><a href={data.href}>source</a></p>
            <div dangerouslySetInnerHTML={{ __html:data.html }}></div>
        </div>
    },
    template: `[[snippet]]
| /| /| <p>Snippet text</p>
`
})