import { BlockNode } from "../ast"
import { MarkdownRange, NonEmptyMarkdownRangeArray } from "../markdownrange"
import { PeekAllBlockFunction, ParseAllBlockFunction, ParseAllInlineFunction, BlockParserFunctions } from "../parser"
import { TableDividerRE } from "./table"

const isNotTableHeadInjected = (peekAll: PeekAllBlockFunction) => (range: MarkdownRange, index: number): boolean => index !== 0

const processTableHeadRangesInjected = (parseBlock: ParseAllBlockFunction, parseInline: ParseAllInlineFunction) => (ranges: NonEmptyMarkdownRangeArray): BlockNode => ({
    type: "block",
    name: "tablehead",
    start: ranges.getStart(),
    end: ranges.getEnd(),
    meta: {},
    children: parseBlock(ranges)
})

export const TableHeadParserFunctions: BlockParserFunctions = {
    name: "tablehead",
    peek: (range, index, obj, ancestors) => {
        if (ancestors[ancestors.length - 1] !== "table") return false
        
        return range.markdown[0] === "|" && range.markdown.search(TableDividerRE) === -1
    },
    parse: (ranges, parseBlocks, parseInline, peekAll) => ranges.eat(
        isNotTableHeadInjected(peekAll),
        processTableHeadRangesInjected(parseBlocks, parseInline),
        "Expected start of a table head"
    )
}
