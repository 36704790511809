import { useMemo, useState } from "react";
import { BlockNode, InlineNode, MarkdownError, MarkdownNode, Node, TransformBlockFunction, TransformInlineFunction, Transformers, mergeTransformers } from "./ast";
import { MarkdownParserError, createMarkdownError } from "./error";
import { MarkdownRange, MarkdownRangeArray, NonEmptyMarkdownRangeArray, RangesResult } from "./markdownrange";
import { ParagraphParserFunctions } from "./parsers/paragraph";
import { PlaintextParserFunctions } from "./parsers/plaintext";
import { BreakParserFunctions } from "./parsers/break";
import { HeadingParserFunctions } from "./parsers/heading";
import { UnorderedListParserFunctions } from "./parsers/unorderedlist";
import { UnorderedListItemParserFunctions } from "./parsers/unorderedlistitem";
import { OrderedListParserFunctions } from "./parsers/orderedlist";
import { OrderedListItemParserFunctions } from "./parsers/orderedlistitem";
import { BlockquoteParserFunctions } from "./parsers/blockquote";
import { CodeParserFunctions } from "./parsers/code";
import { TableCellParserFunctions } from "./parsers/tablecell";
import { TableRowParserFunctions } from "./parsers/tablerow";
import { TableHeadParserFunctions } from "./parsers/tablehead";
import { TableBodyParserFunctions } from "./parsers/tablebody";
import { TableParserFunctions } from "./parsers/table";
import { StrikethroughParserFunctions } from "./parsers/strikethrough";
import { InlineCodeParserFunctions } from "./parsers/inlinecode";
import { EmphasisParserFunctions } from "./parsers/emphasis";
import { SimpleLinkParserFunctions } from "./parsers/linkSimple";
import { ComplexLinkParserFunctions } from "./parsers/linkComplex";
import { ComplexImageParserFunctions } from "./parsers/image";
import { BRParserFunctions } from "./parsers/br";
import { MacroParserFunctions } from "./parsers/macrop";
import { MacroTagParserFunctions } from "./parsers/macrotag";
import { MacroContentParserFunctions } from "./parsers/macrocontent";
import { FrontmatterParserFunctions } from "./parsers/frontmatter";
import { SectionParserFunctions } from "./parsers/section";

type PeekInlineFunction = (char: string, index: number, range: MarkdownRange, ancestors: string[]) => number | boolean
export type PeekAllInlineFunction = (ranges: NonEmptyMarkdownRangeArray, start?: number) => PeekMatch | null
export type InlineParserMatcherFunction = (char: string, index: number, obj: MarkdownRange) => number | boolean
export type ParseAllInlineFunction = (ranges: NonEmptyMarkdownRangeArray) => InlineNode[]
export type InlineParserFunction = (ranges: NonEmptyMarkdownRangeArray, inlineParser: ParseAllInlineFunction, peekOthers: PeekAllInlineFunction) => RangesResult<InlineNode>
export interface InlineParserFunctions {
    name: string
    peek: PeekInlineFunction
    parse: InlineParserFunction
}

type PeekBlockFunction = (range: MarkdownRange, index: number, obj: NonEmptyMarkdownRangeArray, ancestors: string[]) => boolean
export type PeekAllBlockFunction = (ranges: NonEmptyMarkdownRangeArray) => PeekMatch | null
export type BlockParserMatcherFunction = (range: MarkdownRange, index: number, obj: MarkdownRange[]) => number | boolean
export type ParseAllBlockFunction = (ranges: NonEmptyMarkdownRangeArray) => BlockNode[]
type BlockParserFunction = (ranges: NonEmptyMarkdownRangeArray, blockParser: ParseAllBlockFunction, inlineParser: ParseAllInlineFunction, peekOthers: PeekAllBlockFunction) => RangesResult<BlockNode>
export interface BlockParserFunctions {
    name: string
    peek: PeekBlockFunction
    parse: BlockParserFunction
}

interface Parsers {
    inline: InlineParserFunctions[],
    block: BlockParserFunctions[]
}

interface PeekMatch {
    name: string
    index: number
    match: number
    markdown: string
}
const sortMatches = (a: PeekMatch, b: PeekMatch) => {
    if (b.match === a.match) return a.index - b.index // if the match is at the same position, prioritize the earlier parser

    return a.match - b.match
}

const createInlineParser = (parsers: InlineParserFunctions[], transformInline: TransformInlineFunction) => {
    const peekAll = (ancestors: string[]): PeekAllInlineFunction => (ranges: NonEmptyMarkdownRangeArray, start: number=0): PeekMatch | null => {
        var matches = [] as PeekMatch[]
        const range = ranges[0]

        for (let j=0; j<parsers.length; j++) {
            const match = parsers[j].peek(range.markdown[start], start, range, ancestors)
            if (match === true) matches.push({ name: parsers[j].name, index: j, match: start, markdown: range.markdown.slice(start) })
            if (typeof(match) === "number") matches.push({ name: parsers[j].name, index: j, match, markdown: range.markdown.slice(match) })
        }

        const match = matches.sort(sortMatches).shift()
        if (match) {
            return match
        }

        return null
    }

    const parseAllInline = (ancestors: string[]): ParseAllInlineFunction => (ranges: MarkdownRangeArray): InlineNode[] => {
        if (!ranges.isNonEmpty()) return []

        const result = [] as InlineNode[]
        const currentPeekAll = peekAll(ancestors)

        var remainder = ranges
        for (let match=currentPeekAll(remainder); match; match=currentPeekAll(remainder)) {
            try {
                const parseResult = parsers[match.index].parse(remainder, parseAllInline([...ancestors, match.name]), currentPeekAll)
                if (!parseResult) throw remainder.error(`Could not parse the inline:${match.name}`)

                const transformedNode = transformInline(parseResult[0])
                if (transformedNode !== null) result.push(transformedNode)

                if (!parseResult[1].isNonEmpty()) break
                remainder = parseResult[1]
            } catch (err: any) {
                result.push({
                    type: "inline",
                    name: "error",
                    start: remainder.getStart(),
                    end: remainder.getEnd(),
                    meta: {
                        errorMessage: err.toString()
                    },
                    children: [],
                    value: ""
                })
                break;
            }
        }

        return result
    }

    return parseAllInline
}

const createBlockParser = (parsers: BlockParserFunctions[], inlineParser: (ancestors: string[]) => ParseAllInlineFunction, transformBlock: TransformBlockFunction) => {
    const peekAll = (ancestors: string[]): PeekAllBlockFunction => (ranges: NonEmptyMarkdownRangeArray): PeekMatch | null => {
        const range = ranges[0]
        const match = parsers
            .map((parser, index): PeekMatch => ({
                name: parser.name,
                index,
                match: parser.peek(range, 0, ranges, ancestors) ? 1 : 0,
                markdown: range.markdown
            }))
            .filter(({ match }) => match === 1)
            .shift()

        if (match) return match

        return null
    }

    const parseAllBlocks = (ancestors: string[]): ParseAllBlockFunction => (ranges: NonEmptyMarkdownRangeArray): BlockNode[] => {
        if (!ranges.isNonEmpty()) return []

        const result = [] as BlockNode[]
        const currentPeekAll = peekAll(ancestors)

        var remainder = ranges
        for (let i=0, match=currentPeekAll(remainder); match; match=currentPeekAll(remainder), i++) {
            try {
                const parseResult = parsers[match.index].parse(remainder, parseAllBlocks([...ancestors, match.name]), inlineParser([...ancestors, match.name]), currentPeekAll)
                if (!parseResult) throw remainder.error(`Could not parse the block:${match.name}`)

                const transformedNode = transformBlock(parseResult[0])
                if (transformedNode !== null) result.push(transformedNode)

                if (!parseResult[1].isNonEmpty()) break
                remainder = parseResult[1]
            } catch (err: any) {
                result.push({
                    type: "block",
                    name: "error",
                    start: remainder.getStart(),
                    end: remainder.getEnd(),
                    meta: {
                        errorMessage: err.toString()
                    },
                    children: []
                })
                break;
            }
        }

        return result
    }

    return parseAllBlocks
}

export const createParser = (parsers: Parsers, transformers: Transformers) => {
    const [transformInline, transformBlock] = mergeTransformers(transformers)
    const parseAllInline = createInlineParser(parsers.inline, transformInline)
    const parseAllBlocks = createBlockParser(parsers.block, parseAllInline, transformBlock)

    return (markdown: string): MarkdownNode | MarkdownError => {
        const ranges = MarkdownRangeArray.fromMarkdown(markdown + "\n")
        if (!ranges.isNonEmpty()) {
            return {
                type: "markdown",
                start: 0,
                end: markdown.length,
                children: [],
                meta: {}
            }
        }
    
        try {
            return {
                type: "markdown",
                start: ranges.getStart(),
                end: ranges.getEnd(),
                children: parseAllBlocks([])(ranges),
                meta: {}
            }
        } catch (err: any) {
            console.error(err)

            if (err.constructor === MarkdownParserError) return createMarkdownError(err.message, err.index, markdown)

            throw createMarkdownError(err.message, 0, markdown)
        }
    }
}

export const createErrorNode = <NodeType extends Node>(node: NodeType, message: string): NodeType => ({
    ...node,
    name: "error",
    meta: {
        ...node.meta,
        errorMessage: message
    }
})

export const DefaultParsers = {
    block: [
        FrontmatterParserFunctions,
        SectionParserFunctions,
        MacroTagParserFunctions,
        MacroContentParserFunctions,
        MacroParserFunctions,
        HeadingParserFunctions,
        OrderedListParserFunctions,
        OrderedListItemParserFunctions,
        UnorderedListParserFunctions,
        UnorderedListItemParserFunctions,
        BlockquoteParserFunctions,
        CodeParserFunctions,
        TableParserFunctions,
        TableHeadParserFunctions,
        TableBodyParserFunctions,
        TableRowParserFunctions,
        TableCellParserFunctions,
        BreakParserFunctions,
        ParagraphParserFunctions
    ],
    inline: [
        BRParserFunctions,
        StrikethroughParserFunctions,
        InlineCodeParserFunctions,
        EmphasisParserFunctions,
        SimpleLinkParserFunctions,
        ComplexLinkParserFunctions,
        ComplexImageParserFunctions,
        PlaintextParserFunctions
    ]
}
export const DefaultTransformers: Transformers = {
    inline: [],
    block: []
}
export const parseMarkdown = createParser(DefaultParsers, DefaultTransformers)

export const useMarkdownParser = (markdown: string, parserOptions: Parameters<typeof createParser>=[DefaultParsers, DefaultTransformers]): [MarkdownNode | MarkdownError, (markdown: string) => void] => {
    const parseMarkdown = useMemo(() => createParser(parserOptions[0], parserOptions[1]), [parserOptions])
    const [data, updateData] = useState(() => ({ markdown, ast: parseMarkdown(markdown) }))

    return [
        data.ast,
        (markdown: string) => {
            if (markdown !== data.markdown) updateData({ markdown, ast: parseMarkdown(markdown) })
        }
    ]
}