import { getHeadingID } from "../../data/util";
import { randomtablesRandomValues } from "./randomtableRandomValues";
import { ExpandedRandomTableReference, RandomTableDatabase, RandomTableIndexResult } from "./types";

export const randomtablesReferenceRandomValues = (reference: ExpandedRandomTableReference, index: number, data: RandomTableDatabase): RandomTableIndexResult | null => {
    const pageTables = data[reference.path];
    if (!pageTables) return null

    for (let j=0; j<pageTables.length; j++) {
        if (pageTables[j].title !== reference.randomTable) continue;

        return {
            index,
            path: reference.path,
            title: reference.title || reference.randomTable,
            id: getHeadingID(reference.randomTable),
            results: randomtablesRandomValues(pageTables[j])
        }
    }

    return null
}

export const randomtablesReferencesRandomValues = (references: ExpandedRandomTableReference[], data: RandomTableDatabase): RandomTableIndexResult[] => {
    const results = [] as RandomTableIndexResult[]

    for (let i=0; i<references.length; i++) {
        const result = randomtablesReferenceRandomValues(references[i], i, data)
        if (result !== null) results.push(result)
    }

    return results
}