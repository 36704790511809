import React from 'react'
import { AbilityScoreSet, Dnd5eAbilityScores, getDnd5eAbilityScoreModifier } from '../../../dnd';
import DndPropertyLine from './DndPropertyLine';

interface DndSavingThrowsOptions {
    proficiency?: number;
    abilities?: AbilityScoreSet
}

const DndSavingThrows = ({ proficiency=0, abilities }: DndSavingThrowsOptions) => {
    if (!abilities) return null
    if (abilities && Dnd5eAbilityScores.filter(v => abilities[v].proficient).length === 0) return null;

    let second = false
    return <DndPropertyLine label="Saving Throws">
        {Dnd5eAbilityScores.map((v,i) => {
            if (!abilities[v].proficient) return null
            const result = <React.Fragment key={i}>{second ? ', ' : ''} <span style={{textTransform: "capitalize"}}>{v.toLowerCase()}</span> +{proficiency+getDnd5eAbilityScoreModifier(abilities[v].score)}</React.Fragment>
            second = true
            return result
        })}
    </DndPropertyLine>
}

export default DndSavingThrows